import React from "react";
import "./numbermasking.css";

import Footer from "../../components/footer/Footer";

import Navbar from "../../components/navbar/Navbar";

import security_icon from "../../assets/images/securitypriority.svg";
import trust_icon from "../../assets/images/booster-icon.svg";
import local_presence_icon from "../../assets/images/grow-business-icon.svg";

import infroGraphics from "../../assets/images/Infographic.png"


import call_or_message from "../../assets/images/call-or-message.png";
import connect_img from "../../assets/images/connect-img.png";
import conversation_img from "../../assets/images/conversation-img.png";
import end_call from "../../assets/images/end-call-img.png";
import superdpowerd_by_img from "../../assets/images/superpowerd-by-img.png";
import deeper_eng_img from "../../assets/images/deeper-eng-img.png";
import number_masking_header_img from "../../assets/images/KASOOKOO NUMBER MASKING.png";
import step1Call from "../../assets/images/Step 1 Call or Message.png";
import step2connet from "../../assets/images/Step 2 Connect.png";
import step3conver from "../../assets/images/Step 3 Conversation.png";
import step4end from "../../assets/images/Step 4 End Call.png";
import forge from "../../assets/images/Forge meaningful.png";

export default function NumberMasking(props) {
  return (
    <div className="number-masking">
      <Navbar
        heading="Prioritize user privacy and security"
        text="Safeguard the privacy of your customers and users' phone numbers while enhancing the confidentiality of your calls and messages. Prevent off-platform transactions and seamlessly facilitate communication for improved privacy and security."
        img={number_masking_header_img}
        extra_heading="KASOOKOO NUMBER MASKING"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Securely communicate with ease</h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "345px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={security_icon} alt="Icon-security" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Enhance security</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Enhance the security measures for your team, users, and
                      business, while maintaining compliance in markets where it
                      is mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "345px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={trust_icon} alt="Group 1000005021" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Be reliable </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Leverage secure communication channels to reassure
                      customers that their data is safeguarded and their
                      information remains secure on your platform.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "345px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={local_presence_icon} alt="Group 1000005022" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Reach local audience </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Tailor your approach to fit local markets and provide
                      customers with local numbers, enabling higher response
                      rates without the need for physical infrastructure.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Functioning mechanism behind number masking
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img src={step1Call} alt="" class="mx-auto mb-4 my-md-2" />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 1: Call or Message</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Customers engage with your business by placing calls or
                      sending messages from their personal numbers, whether it's
                      to connect with a driver, make a reservation, or complete
                      a purchase.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img src={step2connet} alt="" class="mx-auto mb-4 my-md-2" />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 2: Connect</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Kasookoo facilitates the connection between the customer
                      and the driver, providing them with virtual numbers. Both
                      parties are unaware of each other's actual phone numbers
                      and instead see only the assigned local virtual number.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img src={step3conver} alt="" class="mx-auto mb-4 my-md-2" />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 3: Conversation</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Now, the customer and the driver can engage in anonymous
                      communication, ensuring that neither party has access to
                      the other's personal number.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img src={step4end} alt="" class="mx-auto mb-4 my-md-2" />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 4: End Call</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Once the transaction is completed, neither the agent nor
                      the customer can be contacted using the same virtual
                      number.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-center_button "
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row ">
            <div class="col-12 text-start text-md-center">
              <a
                class="btn btn-arrow"
                onClick={() => {
                  props.setIsFormOpen(true);
                }}
              >
                Transform user privacy into a distinctive competitive advantage
                today!
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up p-0">
        <svg
          className="p-0"
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
{/* 
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title pt-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Learn how ride-hailing giant Gojek uses KASOOKOO APIs to
                  superpower their App{" "}
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Gojek, the ride-hailing transport service, transformed the way
                  they handle lost and found co-operation between drivers and
                  customers by adding calling, messaging, and number masking
                  capabilities to their app.{" "}
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="num-section-8"
        class="w-100 position-relative block-center_button pb-4"
      >
        <style></style>
        {/* <div class="container ">
          <div class="row pb-4">
            <div class="col-12 text-start text-md-center">
              <a href="" class="btn btn-arrow" target="">
                Get the Full Story!{" "}
              </a>
            </div>
          </div>
        </div> */}
      </div>

      <div id="num-section-9" class="w-100 position-relative block-block_text text-center px-md-4 px-xl-5 pb-4 pb-md-5 pt-3 pt-md-4 d-none d-md-block">
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-3799 size-large"
                  src={infroGraphics}
                  alt=""
                  width="1024"
                 
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-11"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start pb-md-3 ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-first order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={forge}
                  alt="Deeper engagements begin with secure communications"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Forge meaningful connections by initiating secure
                    communications that foster deeper engagements
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    class="btn btn-yellow border-radius-50 mx-auto ms-0"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
