import React, { useState, useEffect } from "react";
export default function Feedback(props) {
  const [currentFeedback, setCurrentFeedback] = useState(0);

  const nextFeedback = () => {
    if (currentFeedback < props.feedback.length - 1)
      setCurrentFeedback(currentFeedback + 1);
  };

  const prevFeedback = () => {
    if (currentFeedback != 0) setCurrentFeedback(currentFeedback - 1);
  };

  const feedback = props.feedback[currentFeedback];

  return (
    <div>
      {feedback && (
        <div class="w-100 section-recommendations_slider">
          <div class="container py-5">
            <div class="col-12 mx-auto col-lg-9 slick-initialized slick-slider slick-dotted">
              <button
                class="slick-prev slick-arrow"
                aria-label="Previous"
                type="button"
                onClick={prevFeedback}
              >
                Previous
              </button>
              <div class="">
                <div class="slick-track">
                  <div class="slide px-md-5 slick-slide slick-current slick-active">
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                        <p>{feedback.quote}</p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-start order-md-last">
                          <div class="w-100 d-block">
                            <div class="name">{feedback.name}</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">{feedback.role}</div>
                          </div>
                        </div>
                        <div class="col-12 col-md-4 order-md-first pb-3 pb-md-0">
                          <img
                            src={feedback.image}
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                        {/* <div class="col-12 col-md-3 text-md-end">
                                         <div class="w-100 d-block">
                                             <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46374.png" alt="Celine Que" class="mx-auto ms-md-auto me-md-2" />
                                         </div>
                                     </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="slick-next slick-arrow"
                aria-label="Next"
                type="button"
                style={{backgroundImage:'url(../../assets/images/arrow right.svg)'}}
                onClick={nextFeedback}
              >
                Next
              </button>
              <ul class="slick-dots" role="tablist">
                <li class="slick-active" role="presentation">
                  <button
                    type="button"
                    role="tab"
                    id="slick-slide-control10"
                    aria-controls="slick-slide10"
                    aria-label="1 of 3"
                    tabindex="0"
                    aria-selected="true"
                  >
                    1
                  </button>
                </li>
                <li role="presentation" class="">
                  <button
                    type="button"
                    role="tab"
                    id="slick-slide-control11"
                    aria-controls="slick-slide11"
                    aria-label="2 of 3"
                    tabindex="-1"
                  >
                    2
                  </button>
                </li>
                <li role="presentation" class="">
                  <button
                    type="button"
                    role="tab"
                    id="slick-slide-control12"
                    aria-controls="slick-slide12"
                    aria-label="3 of 3"
                    tabindex="-1"
                  >
                    3
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
