import React from "react";
import "./programmablemessaging.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import comapaign_img from "../../assets/images/consulting-img.png";
import bottomLeftImg from "../../assets/images/7-1.png"

import bankicon from "../../assets/images/bank.svg"
import dollaricon from "../../assets/images/dollar.svg"
import grocerystoreicon from "../../assets/images/grocery-store.svg"
import insuranceicon from "../../assets/images/insurance.svg"
import planeicon from "../../assets/images/plane.svg"
import sendicon from "../../assets/images/send.svg"

import growBusinessicon from "../../assets/images/grow-business-icon.svg"
import affordableHighQicone from "../../assets/images/affordable-highQ-icon.svg"
import enhancedelieveryicon from "../../assets/images/enhance-delievery-icon.svg"

import build_a_quality_img from '../../assets/images/build_a_quality_img.png'
import marketing_communications_img from '../../assets/images/marketing_communications_img.png'
import programbable_messaging_img from '../../assets/images/programbable_messaging_img.png'
import reminders_notifications_img from '../../assets/images/reminders_notifications_img.png'
import text_to_speech_voice_messages_img from '../../assets/images/text_to_speech_voice_messages_img.png'
import verification_authentication_img from '../../assets/images/verification_authentication_img.png'

import edutech_icon from "../../assets/images/edutech.svg";
import debt_collector_icon from "../../assets/images/debt-collector.svg";
import call_center_icon from "../../assets/images/outbound call.svg";

export default function ProgrammableMessaging(props) {
  return (
    <div className="programmable-messaging">
      <Navbar
        heading="Develop a personalized messaging strategy"
        text="Bridge the gap with your audience and craft an exceptional messaging experience using our Programmable Messaging APIs.Use your app or platform to communicate with your customers and build stronger relationships. "
        img={comapaign_img}
        extra_heading="KASOOKOO PROGRAMMABLE MESSAGING"
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Use SMS to personalize and automate your customer
                  interactions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={growBusinessicon}
                      alt="Group 1000005101"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Grow your business international</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Provide SMS messaging services to all countries in Africa
                      and offer high-quality, localized support to customers in
                      these regions.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={affordableHighQicone}
                      alt="Vector 1233"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Affordable and high-quality</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Streamline your supplier network and optimize cost savings
                      with the most competitive rates in Africa.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={enhancedelieveryicon}
                      alt="Group 7329"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Enhance delivery efficiency</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Attain a remarkable success rate in delivering your
                      messages, guaranteeing their arrival to your intended
                      audience - your valuable customers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Improve customer engagement with SMS APIs
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Engage with your customers through the universally popular
                  channel for mobile users. Elevate their experience by
                  seamlessly integrating programmable SMS into your business
                  platform, expanding your reach and enhancing customer
                  interaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters pb-1"
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered pb-1">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers considers SMS as a desired communication channel,
                surpassing all others in terms of popularity and preference.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">57</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers express a preference for receiving reminders via
                SMS, highlighting its effectiveness as a communication medium.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">41</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers favor SMS as their preferred communication method.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <style></style>

        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/01/Frame-46326.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to know what Singapore consumers expect in an
                    interaction?
                  </h4>
                  <div class="description mb-3">
                    *Read Kasookoo's Singapore Consumer Engagement Report 2022.
                  </div>
                </div>
                <div class="d-block w-100">
                  <a
                    class="btn btn-yellow"
                    target=""
                    href="https://4284619.fs1.hubspotusercontent-na1.net/hubfs/4284619/Toku%20Singapore%20Consumer%20Engagement%20Report%202022.pdf"
                  >
                    Download Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title pt-5 pb-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 pb-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Reimagine your customer journey</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Improve your user experience by adding messaging features and
                  capabilities to your application or platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={verification_authentication_img}
                  alt="Frame 462575"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Verification & Authentication</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Want to use 2FA messages to authenticate payments,
                      transactions, and logins? Our APIs can do that for you,
                      with both messages and calls.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                  
                    class=" a-btn btn btn-arrow text-start"
                    onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Explore Programmable SMS today!
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={text_to_speech_voice_messages_img}
                  alt="Text to speech"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Text-to-speech voice messages</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Use Text-to-Speech to turn a text message into a voice
                      call that can be sent to any mobile phone or landline in
                      the world.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    class=" a-btn btn btn-arrow text-start"
                    onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Learn More About SMS APIs!
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={marketing_communications_img}
                  alt="Marketing and Communications"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Marketing &amp; Communications</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Keep your customers engaged and foster a strong connection
                      to your brand by regularly sending them product updates
                      and customer surveys. By doing so, you ensure their
                      involvement and active participation in your brand's
                      journey.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                   class=" a-btn btn btn-arrow text-start"
                   onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Start Connecting With Your Customers via SMS!
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={reminders_notifications_img}
                  alt="Reminders and Notications"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Reminders &amp; Notifications</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Inform your customers about upcoming appointments or
                      deadlines through timely reminders, ensuring they stay
                      well-informed and prepared.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    class=" a-btn btn btn-arrow text-start"
                    onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Start Sending Notifications!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-center_button pb-4 pt-3 align-items-center text-center"
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              {/* <a
                href="https://toku.co/blog/programmable-sms-why-businesses-care/"
                class="btn btn-blue"
                target=""
              >
                Discover More Ways to Use Programmable SMS!
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-titles_background_and_repeater_subjects pb-3"
      >
        <style></style>
        <div class="w-100 section-titles_background_and_repeater_subjects">
          <div class="container">
            <div class="row px-md-5 pb-5 pt-3">
              <div class="col-12 col-md-10 col-lg-8 mx-auto text-md-center">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Discover how businesses similar to yours are leveraging the
                    power of messaging to achieve their objectives and drive
                    success
                  </h2>
                </div>
              </div>
            </div>
            <div class="row px-md-3 rseh">
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/government-solution/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={bankicon}
                        alt="Government Services "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/government-solutions/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/government-solutions"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Government Services
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/fintech/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={dollaricon}
                        alt="Fintech "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/fintech" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/fintech"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Fintech
                    </a>
                  </div>
                </div>
              </div>
              
            
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/Edutech" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={edutech_icon}
                        alt="Sharing Economy"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                                      <a
                      class="style-h4 rs_equalHeight"
                      href="/#/EduTech"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Edutech
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/outbound-callcentre" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={call_center_icon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/outbound-callcentre"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                     Outbound Call Centre
                     
                    </a>
                  </div>
                </div>
              </div>
              {/* <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/sharing-economy/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={sendicon}
                        alt="Sharing Economy "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/sharing-economy" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/sharing-economy"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Sharing Economy
                    </a>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/insurance-services" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={insuranceicon}
                        alt="Insurance Services "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/insurance-services/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/insurance-services"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Insurance Services
                    </a>
                  </div>
                </div>
              </div> */}
            
            
            <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/debtcollection" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={debt_collector_icon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/debtcollection"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                   Debt Collection
                     
                    </a>
                  </div>
                </div>
              </div>  
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/hostpitality" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={planeicon}
                        alt="Travel &amp; Hospitality "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/hostpitality" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/hostpitality"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Travel &amp; Hospitality
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/retail-ecommerce/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={grocerystoreicon}
                        alt="Retail &amp; eCommerce "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/retail-ecommerce" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/retail-ecommerce"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Retail &amp; eCommerce
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-first order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={bottomLeftImg}
                  alt="Build a quality messaging experience for your customers today!"
                  class=" img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Embark on creating a top-notch messaging experience for your
                    customers today and elevate your communication to new
                    heights of excellence!
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                   
                    class="blact_btn a-btn"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
