import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/RETAIL _ ECOMMERCE.png";
import "./fullstories.css";
import partner_with_us_img from "../../assets/images/partner_with_us_img.png";

export default function KasookooStories(props) {
  return (
    <div className="fullstory">
      <Navbar
        // heading="Unleash exceptional buying experiences"
        // text="Enhance the online customer experience and streamline the customer journey by leveraging voice and messaging capabilities. Drive marketing efficiency and elevate retail customer experiences for a more seamless and satisfying interaction."
        // img={optimize_customer_img}
        // extra_heading="RETAIL & ECOMMERCE"
        first_section = {false}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>
      <main role="main">
        <div class="container pt-5">
          <div class="row pt-5 mt-md-5">
            <div >
              <div class="row single-title">
                <div class="col-12">
                  <h1 class="single-title-h2" style={{textAlign:"center"}}>{props.title}</h1>
                </div>
              </div>

              {/* <div class="row single-author">
                <div class="col author-details">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Sanjeed-Image.png"
                    alt="V K Sanjeed"
                  />
                  V K Sanjeed
                </div>
                <div class="col-auto text-end">
                  <div
                    class="a2a_kit a2a_default_style text-end w-auto ms-auto me-0 d-flex"
                    style={{ lineHeight: "16px" }}
                  >
                    <span class="a2a_title d-none d-md-block">Share</span>
                    <a
                      class="a2a_button_linkedin"
                      target="_blank"
                      rel="nofollow noopener"
                      href="/#linkedin"
                    >
                      <img
                        src="https://toku.co/wp-content/themes/toku/assets/images/icons/linkedin.svg"
                        border="0"
                        alt="Linkedin"
                        height="16"
                      />
                    </a>
                    <a
                      class="a2a_button_twitter"
                      target="_blank"
                      rel="nofollow noopener"
                      href="/#twitter"
                    >
                      <img
                        src="https://toku.co/wp-content/themes/toku/assets/images/icons/twitter.svg"
                        border="0"
                        alt="Twitter"
                        height="16"
                      />
                    </a>
                    <a
                      class="a2a_button_facebook"
                      target="_blank"
                      rel="nofollow noopener"
                      href="/#facebook"
                    >
                      <img
                        src="https://toku.co/wp-content/themes/toku/assets/images/icons/facebook.svg"
                        border="0"
                        alt="Facebook"
                        height="16"
                      />
                    </a>
                    <a
                      class="a2a_button_rss"
                      target="_blank"
                      rel="nofollow noopener"
                      href="/#rss"
                    >
                      <img
                        src="https://toku.co/wp-content/themes/toku/assets/images/icons/rss.svg"
                        border="0"
                        alt="rss"
                        height="16"
                      />
                    </a>
                    <div style={{ clear: "both" }}></div>
                  </div>

                  <script
                    async=""
                    src="https://static.addtoany.com/menu/page.js"
                  ></script>
                </div>
              </div> */}
              <div class="row single-featured">
                <div class="col-12">
                  <img
                    width="768"
                    height="421"
                    src={props.Img}
                    style={{ marginTop:"50px"}}
                    class="img-responsive responsive--full w-100 h-auto wp-post-image"
                    alt="gojek toku communication APIs customer experience"
                    // decoding="async"
                    // title="How Gojek Applied Toku’s Communications APIs To Boost Customer Experience"
                    // srcset="https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience-768x421.png 768w, https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience-300x164.png 300w, https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience.png 830w"
                    // sizes="(max-width: 768px) 100vw, 768px"
                  />
                </div>
              </div>
              <div class="row single-content">
                <div class="col-12" style={{textAlign:"justify"}}>
                  <p>
                   {props.storyp1}
                  </p>

                  <p>
                   {props.storyp2}
                  </p>

                  <p>
                   {props.storyp3}
                  </p>


                  <p>
                   {props.storyp4}
                  </p>

                  <p>
                   {props.storyp5}
                  </p>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div        id="num-section-15"        class="w-100 position-relative block-cta_strip_2 "    >
        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container ">
            <div class="row ">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={partner_with_us_img}
                  alt="Accelerate your Smart Nation masterplan with us"
                  class="img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                  Ready to meet your customers where they’re at?
                  </h2>
                </div>
                <div class="d-block w-100">
                  <div class="p-big">
                    <p>
                    Kasokoo Solutions are designed to help make communications smarter, and more seamless.
                    </p>
                  </div>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    class="blact_btn"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Connect With Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
