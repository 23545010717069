import "./uservarification.css";

import solution_icon from "../../assets/images/solution-icon.svg";

import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/easy-user-image.png";

import multiChannel from "../../assets/images/User-Verification-Multi-channel authentication.svg";
import globalicon from "../../assets/images/User-Verification-Higher deliverability – Global reach.svg";
import costEffectiveicon from "../../assets/images/User-Verification-Maximise cost-efficiency.svg";
import auntiFraudIcon from "../../assets/images/User-Verification-Mitigate fraud and reduce risk.svg";

import bottomLeftImg from "../../assets/images/5-1.png";
import bottomRightImg from "../../assets/images/5-2.png";

import voice from "../../assets/images/voice-verification-image.png";
import ping from "../../assets/images/ping-verification-image.png";
import message from "../../assets/images/message-verification.png";
import easyuser from "../../assets/images/easy-user-image.png";
export default function UserVarification(props) {
  return (
    <div className="user-verification">
      <Navbar
        heading="Secure and convenient user verification"
        text="Enhance your conversion rates, combat fraud, and verify genuine customers effortlessly using a streamlined suite of APIs tailored for multinational corporations."
        img={optimize_customer_img}
        extra_heading="KASOOKOO USER VERIFICATION"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Improve user experience and security with easy-to-use
                  verification methods
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "400px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={multiChannel}
                      style={{ height: "70px" }}
                      alt="Icon-security"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Multi-channel authentication</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Verify the authenticity of every user on your application
                      or platform through authentication methods such as voice
                      calls, messages, or even just a phone number.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "400px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={costEffectiveicon}
                      style={{ height: "70px" }}
                      alt="Vector 1233"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Improve efficiency & reduce costs</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Optimize your cost allocation based on conversions rather
                      than volume. Allocate your resources specifically towards
                      verified and legitimate users.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "400px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={auntiFraudIcon}
                      style={{ height: "70px" }}
                      alt="icon"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Avoid financial loss & minimize risk
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Effectively decrease the occurrence of fraud stemming from
                      counterfeit customers and fraudulent transactions through
                      the implementation of a straightforward and seamless
                      verification process.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "400px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={globalicon}
                      style={{ height: "70px" }}
                      alt="Group 1000005101"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Advanced deliverability with worldwide reach
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Broaden your voice connectivity and enhance messaging
                      deliverability for verification purposes by leveraging our
                      tier-1 network in Africa.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Explore new ways to validate data and processes with
                  application programming interfaces (APIs)
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-image_side_and_text_side_repeater pb-5"
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={message}
                  // style={{height:"50%"}}
                  alt="Frame 462540"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Message Verification</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Employ a reliable and time-tested verification <br />
                      process by sending One-time Passwords (OTPs) <br />
                      through SMS.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="/#/programmable-messaging"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More About Our Messaging APIs
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={voice}
                  alt="Group 1000004888"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Voice Verification</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Deliver One-time Passwords (OTPs) through <br />
                      text-to-speech calling, leveraging the exceptional <br />
                      voice connectivity available in Africa, along with the
                      <br /> convenience of local number availability.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="/#/programmable-voice"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn About Our Voice APIs
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={ping}
                  alt="Frame 462506"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Ping Verification</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Establish a seamless authentication experience by <br />
                      verifying Android users without the need for <br /> codes
                      or passwords.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    class="btn btn-arrow text-start"
                    onClick={(event) => {
                      event.preventDefault();
                      props.setIsFormOpen(true);
                    }}
                  >
                    Learn About Ping Verification
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one  order-md-last ">
                <img
                  src={bottomLeftImg}
                  alt="Want to verify your users with a simple API?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-first ">
                <img
                  src={bottomRightImg}
                  alt="Want to verify your users with a simple API?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Looking to verify your users effortlessly with a
                    straightforward API?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <button
                    class="blact_btn mb-5 mb-md-0 mx-auto"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                    style={{ border: "none" }}
                  >
                    Talk to an Expert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
