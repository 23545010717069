import React from "react";
import "./compaignmanager.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import comapaign_img from "../../assets/images/consulting-img.png";
import bottmLeftImg from "../../assets/images/7-2.png"
import img5 from "../../assets/images/campaign-manager-5.png"

import insighticon from '../../assets/images/insight-icon.svg';
import streamlineicon from '../../assets/images/stramline-icon.svg';
import boostericon from '../../assets/images/booster-icon.svg'

import bankicon from "../../assets/images/bank.svg"
import dollaricon from "../../assets/images/dollar.svg"
import grocerystoreicon from "../../assets/images/grocery-store.svg"
import insuranceicon from "../../assets/images/insurance.svg"
import planeicon from "../../assets/images/plane.svg"
import sendicon from "../../assets/images/send.svg"
import compaign_manager_top_img from '../../assets/images/compain_manager_top_img.png'
import centralized_campaign from '../../assets/images/centralized_campaign.png'
import simplifying_mass from '../../assets/images/simplifying_mass.png'
import send_customized from '../../assets/images/send_customized.png'
import error_free from '../../assets/images/error_free.png'
import Feedback from '../../components/Feedback/Feedback'

import edutech_icon from "../../assets/images/edutech.svg";
import debt_collector_icon from "../../assets/images/debt-collector.svg";
import call_center_icon from "../../assets/images/outbound call.svg";

import dufil from "../../assets/clientlogo/13.png";
  
export default function CompaignManager(props) {
  const feedback =[
   
    {
        quote: 'Kasookoo’s has everything it take to deliver on a messaging campaign and that is why we trusted them for our campaign.',
        name: 'Dufil Foods',
        role: '',
        image: dufil,
    }
];
  return (
    <div className="campaign-manager">
      <Navbar
        heading="Consolidate
        all
        your
        SMS
        campaigns
        into
        a
        single
        hub"
        text="Elevate your SMS campaigns to new heights with our all-in-one platform, enabling seamless sending, tracking, and management of sales, marketing, or operational campaigns at scale. Backed by a high delivery rate network in Africa, experience unparalleled efficiency and effectiveness."
        img={compaign_manager_top_img}
        extra_heading="KASOOKOO ORGANIZING CAMPAIGNS"
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Your only partner for managing SMS Campaigns
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "387.5px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={insighticon}
                      alt="Group 1000005133"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Gain insights of campaign performance
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Ensure the success of your campaigns with easy monitoring
                      and centralized tracking, all within a user-friendly
                      platform.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "387.5px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={streamlineicon}
                      alt="Integration"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Streamline customers’ communication
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Enhance global customer engagement by automating your
                      business SMS messaging to create seamless marketing or
                      sales campaigns.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "387.5px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={boostericon}
                      alt="Boost"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Boost your deliverability rates </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Unlock optimal delivery rates in Africa with our
                      enterprise-grade network, leveraging cost-efficient
                      routing while strictly adhering to legitimate channels,
                      eliminating the need for grey routes.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Optimize your SMS campaigns for precision targeting and reach
                  the right customers
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={centralized_campaign}
                  alt="Frame 46257 1"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Centralized Campaign Management </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      A wide view of your campaigns' status, including active,
                      pending, completed, and smoothly delivering, all in one
                      place.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={simplifying_mass}
                  alt="Frame 234"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Simplifying Mass Communication</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Accelerate your campaign operations with seamless bulk
                      contact uploading, template creation and reuse, audience
                      segmentation, and comprehensive tools to streamline your
                      SMS campaigns.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={send_customized}
                  alt="Image 2345 1"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Send customized messages with ease</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Harness the power of dynamic tags to personalize your
                      messages, effortlessly send unique details, codes, or
                      verification information at scale.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            {/* <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-end  pe-lg-5">
                <img
                  src={img5}
                  alt="Frame 46315"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Transform your Emails to SMS </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Convert emails sent to a designated email ID or mobile
                      number format into SMS messages, guaranteeing prompt
                      delivery to your intended recipients. Enjoy flexible
                      customizations upon request.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div> */}


            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-end  pe-lg-5">
                <img
                  src={error_free}
                  alt="Frame 46315"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Error-free environment</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Verify message accuracy with ease before sending by
                      previewing messages in a live environment and sending test
                      messages, ensuring every communication is error-free.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>



            
            {/* <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={error_free}
                  alt="Group 1000005403"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Error-free environment</h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Verify message accuracy with ease before sending by
                      previewing messages in a live environment and sending test
                      messages, ensuring every communication is error-free.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-center_button align-items-center text-center"
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              <button
                
                class="btn btn-blue"
                style={{border:"none"}}
                onClick={()=>{props.setIsFormOpen(true)}}
              >
                Schedule a Demo Today!
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title pt-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Dunfil made their WhatsApp campaigns 15% more efficient in
                  the first 3 months
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-7"
        class="w-100 position-relative block-center_button "
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              <a href="/" class="btn btn-arrow" target="">
                Find Out How
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-8"
        class="w-100 position-relative block-recommendations_slider py-5"
      >
        <style></style>
        <div class="w-100 section-recommendations_slider">
          
          <Feedback feedback={feedback}/>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title pt-5 pb-1"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 pb-1 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Elevate Your Contact Center Performance
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Centralize High-Volume Messaging with a Unified Browser-Based
                  Platform for Comprehensive Customer Communications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-center_button pb-5"
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              <a href="/#/contact-center" class="btn btn-arrow" target="">
                Learn More About Kasookoo Contact Centre
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-block_text pb-5"
      >
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-4501"
                  src={send_customized}
                  alt=""
                  width="900"
                  sizes="(max-width: 900px) 100vw, 900px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-titles_background_and_repeater_subjects pt-4 pb-5"
      >
        <style></style>
        <div class="w-100 section-titles_background_and_repeater_subjects">
          <div class="container">
            <div class="row px-md-5 pb-5 pt-3">
              <div class="col-12 col-md-10 col-lg-8 mx-auto text-md-center">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Unveil the Impact of Messaging in Your Industry for Business
                    Growth
                  </h2>
                </div>
              </div>
            </div>
            <div class="row px-md-3 rseh">
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/#/government-solution" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={bankicon}
                        alt="Government Solutions "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/government-solution/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/government-solution/"
                      title=""
                      target=""
                      style={{ width: "944px" }}
                    >
                      Government Solutions
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/#/fintech/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={dollaricon}
                        alt="Fintech "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/fintech/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/fintech/"
                      title=""
                      target=""
                      style={{ width: "944px" }}
                    >
                      Fintech
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/Edutech" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={edutech_icon}
                        alt="Sharing Economy"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                                      <a
                      class="style-h4 rs_equalHeight"
                      href="/#/EduTech"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Edutech
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/outbound-callcentre" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={call_center_icon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/outbound-callcentre"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                     Outbound Call Centre
                     
                    </a>
                  </div>
                </div>
              </div>

               <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/debtcollection" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={debt_collector_icon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/debtcollection"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                   Debt Collection
                     
                    </a>
                  </div>
                </div>
              </div>    
                          {/* <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/sharing-economy/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={sendicon}
                        alt="Sharing Economy "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/sharing-economy/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/sharing-economy/"
                      title=""
                      target=""
                      style={{ width: "944px" }}
                    >
                      Sharing Economy
                    </a>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/insurance-services/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={insuranceicon}
                        alt="Insurance Services "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/insurance-services/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/insurance-services/"
                      title=""
                      target=""
                      style={{ width: "944px" }}
                    >
                      Insurance Services
                    </a>
                  </div>
                </div>
              </div> */}
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/#/hospitality/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={planeicon}
                        alt="Travel &amp; Hospitality "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/hospitality/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/hostpitality/"
                      title=""
                      target=""
                      style={{ width: "944px" }}
                    >
                      Travel &amp; Hospitality
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/#/retail-ecommerce/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={grocerystoreicon}
                        alt="Retail &amp; eCommerce "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/retail-ecommerce/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/retail-ecommerce/"
                      title=""
                      target=""
                      style={{ width: "944px" }}
                    >
                      E-commerce &amp; FMCG
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-first order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={bottmLeftImg}
                  alt="Want to make SMS campaigns a breeze?"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Looking to Simplify Your SMS Campaigns?
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <button
                  
                    class="blact_btn"
                   style={{border:"none"}}
                   onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
