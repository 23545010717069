import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import kasookoocrm from "../../assets/images/Unlock Worldwide Calling Capabilities for CRM.png";
import whatiscrmImg from "../../assets/images/What is Kasookoo for CRM.png";
import ZohoStep4Img from "../../assets/images/step-4.PNG";
import ZohoStep5Img from "../../assets/images/step-5.PNG";
import ZohoStep6Img from "../../assets/images/step-6.PNG";
import ZohoStep7Img from "../../assets/images/step-7.PNG";
import ZohoStep8Img from "../../assets/images/step-8.PNG";
import ZohoStep9Img from "../../assets/images/step-9.PNG";
import ZohoStep10Img from "../../assets/images/step-10.PNG";
import ZohoStep11Img from "../../assets/images/step-11.PNG";
import ZohoStep12Img from "../../assets/images/step-12.PNG";
import ZohoStep13Img from "../../assets/images/step-13.PNG";
import ZohoStep14Img from "../../assets/images/step-14.PNG";
// import ZohoStep5Img from "../../assets/images/connect_zoho_4.png";
// import ZohoStep6Img from "../../assets/images/connect_zoho_5.png";
import bottomImageLeft from "../../assets/images/7-1.png"
import bottomImageRight from "../../assets/images/7-2.png"
import kasookoophoneApp from "../../assets/images/CRM-Kasookoo Phone App.png";
import kasooNativeDialer from "../../assets/images/CRM-Kasookoo Native Dialler.png"

import botom from "../../assets/images/flow-chart.png"
import bottomMobile from "../../assets/images/kasookoo-microsoft-graph-mobile.png";

import enhancecallicon from "../../assets/images/enhance-call-icon.svg";
import optimisedcost from "../../assets/images/optimized-cost-icon.svg";
import unparaconicon from "../../assets/images/unparalleledconicon.svg";
import cuttingEdgeicon from "../../assets/images/cutting-edge-icon.svg";
import fullencicon from "../../assets/images/full-encryption-sol-icon.svg";
import unparaRealiabilityicon from "../../assets/images/unparallel-realiability-icon.svg";

import bottomimg from "../../assets/images/Want to get African connectivity for Kasookoo CRM.png";

import "./kasookooforzoho.css";
export default function KasookooForMicrosoft(props) {
  return (
    <div className="crm">
      <Navbar
        heading="Make calls with a single click from Zoho applications"
        text=""
        img={kasookoocrm}
        extra_heading="Integrations "
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-2">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Installation Steps</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  1. You must have an active Kasookoo account. If not then please <a href="https://kasookoo.com/#/contact-us" target="_blank">sign up</a> first
                </p>
                <p class="p-small">
                  2. <a href="https://go.zoho.com/c6B" target="_blank">Sign up</a> for Zoho CRM (or any Zoho App that you want to use with Kasookoo Contact Center)
                  {/* 1. Install Kasookoo from <a href="https://marketplace.zoho.com/home" target="_blank">Zoho Marketplace</a> */}
                </p>
                <p class="p-small">
                  3. Login to your Zoho CRM Account as an Administrator 
                </p>
                <p class="p-small">  
                  4. Click the Gear icon on the top right and then Click Telephony 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary "
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep4Img}
                  alt="Zoho Integration Step 4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  5. Click “View Configuration”. It will take you to your Zoho One account 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep5Img}
                  alt="Zoho Integration Step 5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  6. In the Zoho One account, click Marketplace and then Click View Marketplace button 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep6Img}
                  alt="Zoho Integration Step 6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  7. Search “Kasookoo” and click it
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep7Img}
                  alt="Zoho Integration Step 7"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  8. Click “Install”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep8Img}
                  alt="Zoho Integration Step 8"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  9. Select the users for which you want to enable the Kasookoo application and click “Proceed”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep9Img}
                  alt="Zoho Integration Step 9"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  10. Login to the Kasookoo portal as Administrator and click “Integrations” 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep10Img}
                  alt="Zoho Integration Step 10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  11. Click “Connect” button in front of Zoho. It will redirect to a Zoho page
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep11Img}
                  alt="Zoho Integration Step 11"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  12. Click “Accept” 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep12Img}
                  alt="Zoho Integration Step 12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  13. Once the connection is established, you will be back to Integrations page in Kasookoo portal. Click “View Users” to see the list of Kasookoo users that can use the integration between Kasookoo and Zoho App
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep13Img}
                  alt="Zoho Integration Step 13"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left">
          <div class="container">
            <div class="row  pt-0 pb-0 px-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  14. You will see “Connect” button against some users. These users are available both in Kasookoo and Zoho. If a Kasookoo user who does not have a Zoho account then that user can not use this integration. Click “Connect” for all users who want to use this integration
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 col-md-10 text-center px-0">
                <img
                  src={ZohoStep14Img}
                  alt="Zoho Integration Step 14"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      
      <div
        id="num-section-13"
        class="w-100 position-relative block-cta_strip_2 text-center text-md-start"
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomImageLeft}
                  alt="Ready to drive transformational change for your business and customers?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomImageRight}
                  alt="Ready to drive transformational change for your business and customers?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Are you ready to make a positive impact on your business and
                    customers?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    
                    class="a-btn blact_btn mb-5 mb-md-0 mx-auto"
                    onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
