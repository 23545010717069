import React from 'react'

import FullStory from "../../components/FullStories/fullStories.js";
import wellaHealth from "../../assets/images/wellAHealthIMG.png";

export default function Wellahealth(props) {
  return (
    <div>
<FullStory
title={"Kasookoo Voice Solution for Wella Health"}
Img={wellaHealth}
setIsFormOpen={props.setIsFormOpen}
isFormOpen ={props.isFormOpen}
storyp1={"Kasookoo Voice Solution was implemented by Wella Health, a tech-based health startup, to enhance their telehealth operation and improve patient communication. The main objective was to automate calls to patients, provide voice reminders for their scheduled appointments, and offer rescheduling options. Additionally, the solution aims to drive sales of other health services."}
storyp2={"By leveraging Kasookoo Voice Solution, Wella Health was able to streamline their patient outreach process and ensure timely communication. The automated calling feature eliminated the need for manual calls, saving time and resources for the healthcare team. Patients received voice reminders for their upcoming appointments, reducing the likelihood of missed or forgotten visits."}
storyp3={"Moreover, the solution incorporated scheduling reminders and rescheduling options, empowering patients to conveniently modify their appointments if necessary. This flexibility enhanced patient satisfaction and helped to maintain a well-organized schedule for Wella Health."}
storyp4={"In addition to appointment reminders and rescheduling, Kasookoo Voice Solution assisted Wella Health in driving sales of other health services. The system could be programmed to provide information about various healthcare offerings, enabling targeted marketing and increasing awareness among patients."}
storyp5={"Overall, the implementation of Kasookoo Voice Solution by Wella Health enabled the automation of patient calls, provided voice reminders and rescheduling options, and supported the promotion of additional health services. These features enhanced operational efficiency, patient engagement, and revenue generation for the organization, ultimately ensuring the provision of quality, affordable healthcare accessible anywhere in the country."}
>

</FullStory>

    </div>
  )
}
