import React,{useState} from "react";
import "./programmablevoice.css";
import Navbar from "../../components/navbar/Navbar";

import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import comapaign_img from "../../assets/images/consulting-img.png";
import programmableVoiceImg6 from "../../assets/images/programmableVoiceImg6.png"
import programmableVoiceImg8 from "../../assets/images/programmable-voice-img8.png"

import userFreindlyAPiicon from "../../assets/images/user-frinedly-api-cione.svg"
import scalingsolutionsiocn from "../../assets/images/scalingsolution.svg"
import compititivepricesicon from "../../assets/images/compititive-price-icon.svg"
import premiumQcallicon from "../../assets/images/premium-q-calls-icon.svg"

import edutech_icon from "../../assets/images/edutech.svg";
import debt_collector_icon from "../../assets/images/debt-collector.svg";
import call_center_icon from "../../assets/images/outbound call.svg";

import bankicon from "../../assets/images/bank.svg"
import dollaricon from "../../assets/images/dollar.svg"
import grocerystoreicon from "../../assets/images/grocery-store.svg"
import insuranceicon from "../../assets/images/insurance.svg"
import planeicon from "../../assets/images/plane.svg"
import sendicon from "../../assets/images/send.svg"
import programable_voice from '../../assets/images/programable_voice.png'
import call_forwarding from '../../assets/images/call_forwarding.png'
import leverage_whatsapp_img from '../../assets/images/leverage_whatsapp_img.png'
import alerts from '../../assets/images/Intergrate_email_img.png'
import Feedback from "../../components/Feedback/Feedback";
import bottomLeftImg from "../../assets/images/7-1.png"

import infroGraphics from "../../assets/images/Infographic.png"

import userAuthImg from "../../assets/images/programvoice-user-authentication.png"
import integratedcaliing from "../../assets/images/programvoice-integratedvoicecalling.png"
import enhanceprivacy from "../../assets/images/programvoice-enhance-privacy.png"

import wellahealth from "../../assets/clientlogo/wellahealth.png"

export default function ProgrammableVoice(props) {

  const feedback =[
    
    {
        quote:
            'Kasookoo helped us to optimize our operations and team strength in responding and communication with our clients. The insights from the Kasookoo platform were especially relevant to help us make strategic marketing and customer service decisions.',
        name: 'Wella Health',
        role: '',
        image: wellahealth,
    }
];
  return (
    <div className="programvoice">
      <Navbar
        heading="Improve connectivity with advanced voice capabilities "
        text="Elevate your customer experience by incorporating a scalable and programmable carrier-grade voice experience, empowering your product or platform. Unlock innovative methods to engage with your customers and personalize their interactions with your brand."
        img={programable_voice}
        // imgnotVisible ={true}
        extra_heading="KASOOKOO PROGRAMMABLE VOICE "
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>

      {/* <div id="num-section-1" class="w-100 position-relative block-recommendations_slider ">

        <div class="w-100 section-recommendations_slider">
          <div class="container">
            <div class="col-12 mx-auto slider-recommendations col-lg-9 slick-initialized slick-slider" data-slider-autoplay="1" data-slider-autoplay_speed="4000" data-slider-arrows="" data-slider-dots="" data-slider-infinite_scroll="1" data-slider-slides_to_show="1" data-slider-slides_to_scroll="1">
              <div class="slick-list draggable"><div class="slick-track" style={{ opacity: "1", width: "696px", transform: "translate3d(0px, 0px, 0px)" }}>
                <div class="px-md-5 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: "696px", tabindex: "0" }}>
                  <div class="w-100 d-block px-md-5">
                    <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                      <p>Toku’s solution helped us to scale down on<br></br>
                        our manpower requirements, which was very helpful for us.</p>
                    </blockquote>
                  </div>
                  <div class="w-100 d-block px-md-3 px-lg-5">
                    <div class="row text-center">
                      <div class="col-12 col-md-4 text-md-start order-md-last">
                        <div class="w-100 d-block">
                          <div class="name">Celine Que</div>
                        </div>
                        <div class="w-100 d-block">
                          <div class="role">Head of Service Excellence,  Singapore</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-5 order-md-first pb-3 pb-md-0">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Gojek_logo_2019-2-1.png" alt="Gojek_logo_2019 2" class="m-auto mt-1 ms-md-0" />
                      </div>
                      <div class="col-12 col-md-3 text-md-end">
                        <div class="w-100 d-block">
                          <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46246.png" alt="Celine Que" class="mx-auto ms-md-auto me-md-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div></div></div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="num-section-1"
        class="w-100 position-relative block-recommendations_slider "
      >
        <div class="w-100 section-recommendations_slider">
          <Feedback feedback={feedback}/>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-centered_title pt-5 pb-4"
      >
        <div class="w-100 section-centered_title pt-5 pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Seamlessly integrate voice anywhere within your platform or
                  mobile applications
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary "
      >
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={userFreindlyAPiicon}
                      alt="Group 1000004359"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">User-Friendly APIs</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Craft a tailored solution for your business with
                      user-friendly voice APIs that are simple to implement,
                      utilize, and seamlessly integrate with other essential
                      business tools.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:"fit-content" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={scalingsolutionsiocn}
                      alt="Group"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Scaling Solutions, Connecting Globally
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Effortlessly incorporate PSTN calling into any
                      application, ensuring low latency, accelerated
                      time-to-market, and global reach. Benefit from virtual
                      numbers available in African countries.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={compititivepricesicon}
                      alt="Group 1000004766"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Competitive Pricing </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Maximize savings with the most competitive rates in
                      Africa. Establish direct connectivity with our
                      carrier-grade network and pay only for the services you
                      utilize.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={premiumQcallicon}
                      alt="Group 1000004769"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Premium Quality Calls </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Experience uninterrupted connectivity and exceptional
                      voice call quality with our dedicated carrier-grade
                      African telco network, ensuring 99.5% uptime.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-0">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Unleash the Potential of <br /> Programmable Calling
                  Capabilities
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-centered_title pb-5"
      >
        <div class="w-100 section-centered_title pb-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Seamlessly integrate voice calls into your platform and embed
                  the ability to engage with your customers in the most suitable
                  manner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-accordion_list "
      >
        
        <div
          class="w-100 section-accordion_list ng-scope"
          ng-controller="AccordionController"
          ng-init="load('list_accordion_6')"
        >
          <AccordionComponent setIsFormOpen={props.setIsFormOpen}/>
        </div>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Providing an exceptional customer <br /> experience with the
                  power of communication technology
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Develop an immersive and expandable voice experience on your
                  platform, equipped with a comprehensive set of features to
                  deliver the optimal calling experience in Africa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={enhanceprivacy}
                  alt="Number masking image"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Enhanced Caller Privacy </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Safeguard your users, agents, sellers, drivers, and
                      service providers with a number masking API, enabling
                      anonymization or customization of caller ids for enhanced
                      user privacy.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="/#/number-masking" class="btn btn-arrow text-start" target="">
                    Learn How to Enable Anonymised Communications
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={userAuthImg}
                  alt="User Verification illustration"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">User Authentication </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Authenticate genuine customers, boost conversion rates,
                      and mitigate fraud using a straightforward suite of APIS
                      tailored for global enterprises.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="/#/user-varification" class="btn btn-arrow text-start" target="">
                    Discover Our Robust User Verification
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={integratedcaliing}
                  alt="In app"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Integrated Voice Calling </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Establish a reliable backup for PSTN calls or integrate a
                      new communication channel into your mobile or web
                      application with a robust in-app voice SDK.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="/#/in-app-voice-calling" class="btn btn-arrow text-start" target="">
                    Start Building an In-App Voice Calling Solution
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={programmableVoiceImg6}
                  alt="A solution for every setup "
                  class="w-100 img-class"
                  style={{ objectFit: "contain" ,height:"400px", marginBottom:"20px"}}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Tailored Solutions for Every Configuration
                  </h2>
                </div>
                <div class="d-block w-100">
                  <div class="p-big">
                    <p>
                      No matter if you have an on-site PBX, a cloud-based
                      solution, or a combination of both, our Voice APIs and SDK
                      are purposefully crafted to seamlessly integrate with any
                      setup.
                    </p>
                    <p>
                      With Kasookoo's consultative approach, our dedicated team
                      will take the time to understand your integration needs
                      and collaborate with you to design a customized voice
                      solution that perfectly aligns with your requirements.
                    </p>
                  </div>
                </div>
                <div class="d-block w-100 mt-4">
                  <button
                   style = {{border:'none'}}
                    class="btn btn-arrow border-radius-50 mx-auto ms-0"
                    onClick = {()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-titles_background_and_repeater_subjects "
      >
        <div class="w-100 section-titles_background_and_repeater_subjects">
          <div class="container">
            <div class="row px-md-5 pb-5 pt-3">
              <div class="col-12 col-md-10 col-lg-8 mx-auto text-md-center">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Uncover how similar industries are empowering their
                    platforms with voice capabilities
                  </h2>
                </div>
              </div>
            </div>
            <div class="row px-md-3 rseh">
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/government-solution/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={bankicon}
                        alt="Government"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/government-solution" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/government-solution"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Government
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/fintech/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={dollaricon}
                        alt="Financial Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/fintech" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/fintech"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                     Fintech
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/Edutech" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={edutech_icon}
                        alt="Sharing Economy"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                                      <a
                      class="style-h4 rs_equalHeight"
                      href="/#/EduTech"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Edutech
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/outbound-callcentre" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={call_center_icon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/outbound-callcentre"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                     Outbound Call Centre
                     
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/debtcollection" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={debt_collector_icon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/debtcollection"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                   Debt Collection
                     
                    </a>
                  </div>
                </div>
              </div>    
               <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/hospitality/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={planeicon}
                        alt="Travel &amp; Hospitality"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/hospitality" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/hospitality"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Travel &amp; Hospitality
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/#/retail-ecommerce/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={grocerystoreicon}
                        alt="Retail &amp; E-commerce"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/#/retail-ecommerce/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/#/retail-ecommerce"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Retail &amp; E-commerce
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
             
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      {/* <div
        id="num-section-12"
        class="w-100 position-relative block-big_title pt-5 text-md-center pb-2"
      >
        <div class="w-100 section-big_title">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-10 col-lg-8 px-md-4 mx-auto">
                <h2 class="style-h2">
                  Gain Insights into How Ride-Hailing Giant Gojek Leverages
                  Kasookoo's APIs to Enhance the Power of Their App
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div        id="num-section-13"        class="w-100 position-relative block-free_blocks_centerd "      >
        <div class="w-100 section-free-blocks pt-3 pb-md-3 text-md-center w-md-75 mx-auto">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-auto mb-3"> */}
                {/* <p>
                  Revolutionize the lost and found process for Gojek, the
                  ride-hailing transport service, by integrating calling,
                  messaging, and number masking capabilities into their app.
                  enhance cooperation and communication between drivers and
                  customers.
                </p> */}
                {/* <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="aligncenter wp-image-3166 size-large"
                    src={infroGraphics}
                    alt=""
                    width="1024"
                       /> */}
                {/* </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
     
      <div        id="num-section-14"        class="w-100 position-relative block-center_button pb-5 text-start text-md-center"      >
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              {/* <a href="/" class="btn btn-blue" target="">
                Learn More
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-cta_strip_2 py-md-3"
      >
        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start pb-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={bottomLeftImg}
                  alt="Want to create new voice experiences?"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 py-md-3">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Looking to innovate with new voice experiences
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <button
                    
                    class="blact_btn"
                    style={{border:"none"}}
                    onClick = {()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export function AccordionComponent(props) {
  const list_accordion_6 =  [
    {
      title: "Call Forwarding",
      text: "<p>Enhance customer satisfaction by incorporating call-forwarding functionalities to ensure seamless and efficient routing of customer calls.</p>",
      image:
        call_forwarding,
      bottom_arrow_link: {
        title: "Learn more about Programmable Voice",
        url: "",
        target: "",
      },
    },
  
    {
      title: "IVR",
      text: "<p>Optimize customer satisfaction and simplify the workflow of your agents by implementing customizable menus and call flows to create a tailored customer experience.</p>",
      image: leverage_whatsapp_img,
      bottom_arrow_link: {
        title: "Discover more ways you can use IVR.",
        url: "",
        target: "",
      },
    },
  
    {
      title: "Alerts & Notifications",
      text: "<p>introduce a fresh dynamic to your customer notifications by transforming them into voice-based messages utilizing text-to-speech technology.</p>",
      image: alerts,
          bottom_arrow_link: {
        title: "Alerts & Notifications",
        url: "",
        target: "",
      },
    },
  
    
  ];
  const [activeItem, setActiveItem] = useState(0);

  const showItem = (index) => {
    setActiveItem(index);
  };
  return (
  
        <div className="container">
          <div className="row d-md-none">
            <div className="accordion accordion-flush" id="accordionFlush-6">
              {list_accordion_6.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2
                    className="accordion-header"
                    id={`flush-heading${index + 1}`}
                  >
                    <button
                      className={`accordion-button ${
                        index === activeItem ? "style-h4" : "collapsed style-h4"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index + 1}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`flush-collapse${index + 1}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index + 1}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`flush-heading${index + 1}`}
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div className="accordion-body">
                      <div className="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          className="aligncenter size-full size-full mb-3 mb-md-0"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <div
                        className="d-block w-100 p-small"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-6">
              <h3 className="style-h3 mb-5">Explore innovative applications of voice technology</h3>
              {list_accordion_6.map((item, index) => (
                <button
                  className={`list-accordion style-h4 w-100 ng-binding ng-scope ${
                    index === activeItem ? "active" : "text-dark"
                  }`}
                  key={index}
                  onClick={() => showItem(index)}
                  style={{ boxShadow: "none" }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="col-6">
              <div className="d-block w-100 mb-2">
                <img
                  decoding="async"
                  loading="lazy"
                  className="size-full"
                  src={list_accordion_6[activeItem].image}
                  alt={list_accordion_6[activeItem].title}
                />
              </div>
              <div className="d-block w-100">
                <div className="style-h4 mb-2">
                  {list_accordion_6[activeItem].title}
                </div>
              </div>
              <div
                className="d-block w-100 ng-binding"
                dangerouslySetInnerHTML={{
                  __html: list_accordion_6[activeItem].text,
                }}
              />
              <button
                className="btn btn-arrow text-start ng-binding ng-hide"
               onClick={()=>{props.setIsFormOpen(true)}}
              >{list_accordion_6[activeItem].bottom_arrow_link.title}</button>
            </div>
          </div>
        </div>
     
  );
}
