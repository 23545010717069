import React from "react";
import FullStory from "../../components/FullStories/fullStories.js";
import dufil from "../../assets/images/DufilStory.png";

export default function DufilFullStory(props) {
  return (
    <div>
      <FullStory
        title={"Dufil Foods Campaign Using Kasookoo Campaign Manager Solution"}
        Img={dufil}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen ={props.isFormOpen}
        storyp1={"Dufil Foods, Africa's largest instant noodles manufacturer, was faced with the challenge of managing and organizing the high volume of incoming messages on their WhatsApp marketing campaign. With approximately 1000 messages received daily on a single WhatsApp number, Dufil Foods sort a solution that could automate the process of organizing participant details, responding to messages, and generating campaign insights."}
        storyp2={"Kasookoo Campaign Management Solution offered that comprehensive and efficient way to handle this challenge. By leveraging our advanced automation capabilities, Kasookoo automatically sorts and categorizes incoming messages, ensuring that participant details are organized in a systematic manner. This did not only save valuable time but also eliminated the risk of overlooking important messages or participant information."}
        storyp3={"In addition, the Kasookoo enabled Dufil Foods to respond to messages promptly and effectively. With customizable response templates and intelligent chatbot functionality, our solution ensured that participants receive timely and personalized replies. This enhanced customer satisfaction and engagement while relieving the burden on human agents who can focus on more complex queries and tasks."}
        storyp4={"Moreover, the solution provided Dufil Foods with valuable campaign insights. By analyzing the data collected from the WhatsApp messages, the solution generated actionable insights regarding participant demographics, preferences, and engagement patterns. These insights enabled Dufil Foods to optimize their marketing campaign, refine their targeting strategies, and improve overall campaign performance."}
        storyp5={"By implementing the Kasookoo Campaign Manager Solution over WhatsApp, Dufil Foods streamlined their WhatsApp marketing campaign, effectively managed the high volume of messages, and achieved their goal of a successful campaign. With automated organization, personalized responses, and valuable insights, Dufil Foods enhanced customer satisfaction, drove engagement, and maximized the impact of their marketing efforts."}

></FullStory>
    </div>
  );
}
