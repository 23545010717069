import React from "react";
import "./retail&ecommerce.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import boostericon from "../../assets/images/booster-icon.svg";
import trust from "../../assets/images/customer_trust.svg";
import micicon from "../../assets/images/voice-recorder (1).svg";
import senticon from "../../assets/images/send.svg";
import maskingicon from "../../assets/images/shield.svg";
import expert from "../../assets/images/business_exper.svg";
import messageicon from "../../assets/images/email.svg";
import optimize_customer_img from "../../assets/images/RETAIL _ ECOMMERCE.png";
import empower_your from "../../assets/images/Give shoppers the power to choose.png";
import online_sales from "../../assets/images/Broadcast your online sales _ marketing.png";
import retain from "../../assets/images/Retain transactions within your app.png";

import join_forces_1 from "../../assets/images/7-1.png";
import join_forces_2 from "../../assets/images/7-2.png";

import headphone_white_icon from '../../assets/images/headphones_white_icon.svg'
import plan_white_icon from '../../assets/images/plan_white_icon.svg'
import speaker_white_icon from '../../assets/images/speaker_white_icon.svg'
import squres_white_icon from '../../assets/images/squres_white_icon.svg'


export default function RetaileCommerce(props) {
  return (
    <div className="retail">
      <Navbar
        heading="Unleash exceptional buying experiences"
        text="Enhance the online customer experience and streamline the customer journey by leveraging voice and messaging capabilities. Drive marketing efficiency and elevate retail customer experiences for a more seamless and satisfying interaction."
        img={optimize_customer_img}
        extra_heading="RETAIL & ECOMMERCE"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>
      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Enable a seamless shopping experience throughout
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pt-3 pb-5"
      >
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "470px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={expert} alt="Frame 46343" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Create an amazing buying experience
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Whether you're looking to establish a call center, execute
                      bulk messaging campaigns, or seek a customized solution
                      tailored to your specific requirements, we possess the
                      products and expertise to enhance the digital experience
                      for your buyers, delivering superior results.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "470px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={boostericon} alt="Frame 46344" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Build a strong customer relationship
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Boost conversions, enhance CSAT scores, and maximize
                      revenue retention by empowering your agents to assist
                      customers effectively and simplify their lives.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "470px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={trust} alt="Frame 46345" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Cultivate a loyal customer base</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Drive higher conversions, elevate CSAT scores, and
                      increase revenue retention by leveraging our suite of
                      customer engagement tools and APIs. Unlock personalized
                      touchpoints through voice and messaging, creating
                      experiences that foster loyalty and trust among your
                      customers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Exceed your customer expectations</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  The e-commerce landscape is evolving at a rapid pace, and
                  consumer expectations are rising. Today's customers seek a
                  wider range of channels, enhanced communication options, and
                  improved customer service to meet their needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">41</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers would like to get SMS for payment updates
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">67</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers favor app environments regarding all kinds of
                workplace communications as their preferred communication
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">81</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer to receive promotional material other than
                email
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={headphone_white_icon}
                      alt="KASOOKOO CONTACT CENTRE"
                    />
                    KASOOKOO CONTACT CENTRE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">Empower your shoppers</div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      In the realm of online commerce, customers prioritize
                      convenience and choice. Provide them with a hassle-free
                      means of contacting you and empower them to select their
                      preferred communication channel.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/#/contact-centre"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Unlock Omnichannel Customer Service
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={empower_your}
                  alt="Frame 4626223"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={plan_white_icon}
                      alt="KASOOKOO CAMPAIGN MANAGER"
                    />
                    KASOOKOO CAMPAIGN MANAGER
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Amplify your online sales & marketing
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Efficiently boost sales by sending tailored offers,
                      promotions, and deals to your customers at scale.
                      Customize your messaging based on audience segments and
                      automate the bulk messaging process for seamless
                      execution.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/#/programmable-messaging"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More About Sending Bulk Messages
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={online_sales}
                  alt="Frame 46323"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pb-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={speaker_white_icon}
                      alt="KASOOKOO IN-APP VOICE"
                    />
                    KASOOKOO IN-APP VOICE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Retain transactions within your app
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Stay connected with your customers while they travel by
                      incorporating a reliable built-in channel within your
                      application, ensuring they remain updated and engaged on
                      the go.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/#/in-app-voice-calling"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn How to Add Voice Calls to Your Application
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={retain}
                  alt="Group 10000052"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-top_title pt-5"
      >
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center pt-4">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={squres_white_icon}
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-4">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Discover our extensive range of products and solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-5"
      >
        <div class="container section-2_items_in_row_with_image_title_text_link style-slimmer">
          <div class="row rseh-md pb-5">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/programmable-voice"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img src={micicon} alt="Programmable  Voice  " />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Voice
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Embed voice calls in your product
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/programmable-messaging"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img src={messageicon} alt="Programmable Messaging" />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Messaging
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Build the messaging experience you want
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/number-masking"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img src={senticon} alt="Number  Masking" />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Number Masking
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Anonymise personal numbers and protect your users
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/user-varification"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img src={maskingicon} alt="User Verification" />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        User Verification
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Verify legitimate users on a range of channels
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={join_forces_2}
                  alt="Start creating a better e-commerce experience"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={join_forces_1}
                  alt="Start creating a better e-commerce experience"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Embark on the journey of crafting an enhanced e-commerce
                    experience
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    href="/#/contact-center"
                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#Solutions"
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
