import React from 'react'
import FullStory from "../../components/FullStories/fullStories.js"
import richfieldImg from "../../assets/images/RichfieldFullStory.png"

export default function RichFieldFullStory(props) {
  return (
    <div>
<FullStory
title={"Kasookoo CRM Integration with Leadsquared for Richfield Higher Education"}
Img={richfieldImg}
setIsFormOpen={props.setIsFormOpen}
isFormOpen ={props.isFormOpen}
storyp1={"Richfield, a leading educational institution in South Africa, successfully implemented the integration of Kasookoo CRM with Leadsquared to optimize their telesales activities, customer service management, and marketing campaigns. With over 130 users utilizing the integrated system, Richfield witnessed significant improvements in their operational efficiency and overall customer experience."}
storyp2={"One of the key benefits of the integration was the enhanced telesales activities. The integration allowed Richfield's sales team to effectively manage and track their calls, resulting in a total call time of about 48,000 minutes per month. This feature enabled the team to have comprehensive insights into their calling activities and helped them optimize their sales strategies."}
storyp3={"Furthermore, the integration facilitated efficient customer service management for Richfield. By leveraging the combined capabilities of Kasookoo CRM and Leadsquared, Richfield was able to handle a large volume of calls, with over 55,000 calls being answered every month. The integration provided a streamlined workflow for managing customer queries and resolving issues promptly, leading to improved customer satisfaction and retention."}
storyp4={"In addition to telesales and customer service, Richfield utilized the integration to enhance their marketing campaigns. The integrated system enabled the marketing team to leverage the customer data stored in Kasookoo CRM and Leadsquared, allowing for targeted and personalized marketing efforts. By analysing customer behaviour and preferences, Richfield created tailored campaigns that resonated with their target audience, resulting in higher engagement and conversion rates."}
storyp5={"Overall, the integration of Kasookoo CRM with Leadsquared empowered Richfield to optimize their telesales activities, enhance customer service management, and execute targeted marketing campaigns. With 130 users actively utilizing the integrated system, Richfield has experienced improved operational efficiency, increased customer satisfaction, and better marketing outcomes."}

>

</FullStory>
    </div>
  )
}
