import React, { useState } from "react";
import "./home.css";

import customer_support from "../../assets/images/customer-support.jpeg";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import Feedback from "../../components/Feedback/Feedback";

// images
import optimize_customer_img from "../../assets/images/Group 2172.png";
import customized_solutions_icon from "../../assets/images/customized-solutions-icon.svg";
import achieving_growth_icon from "../../assets/images/achieving-growth-icon.svg";
import enhance_customer_icon from "../../assets/images/enhance-customer-icon.svg";
import seamless_processes_icon from "../../assets/images/seamless-processes-icon.svg";
import contact_center_icon from "../../assets/images/contact-center-icon.svg";
import grup1 from "../../assets/images/Group 1171.svg";
import grup2 from "../../assets/images/Group 1172.svg";
import grup3 from "../../assets/images/Group 1173.svg";
import grup4 from "../../assets/images/Group 1174.svg";
import surface from "../../assets/images/surface1.svg";
import trust from "../../assets/images/trust.svg";
import layer from "../../assets/images/layer1-0.svg";
import transform_every_img from "../../assets/images/2.png";
import cnsimg from "../../assets/images/cnsimg.jpg";

import tradeDepotLogo from "../../assets/clientlogo/12.png";
import dufil from "../../assets/clientlogo/13.png";
import wellahealth from "../../assets/clientlogo/wellahealth.png"
import inLandSales from "../../assets/clientlogo/InlandSales.png"

import logo1 from "../../assets/clientlogo/19.png"
import logo2 from "../../assets/clientlogo/20.png"
import logo3 from "../../assets/clientlogo/21.png"
import logo4 from "../../assets/clientlogo/22.png"



import embeddables_icon from "../../assets/images/kasookoo_embeddables_icon.svg";
import expericence_user_img from "../../assets/images/3.png";
import business_telephony_icon from "../../assets/images/business-telephony.svg";
import microsoft_teams_img from "../../assets/images/4.png";
import zoom_phone_img from "../../assets/images/5.png";
import security_img from "../../assets/images/security-img.png";
import join_forces_1 from "../../assets/images/7-1.png";
import join_forces_2 from "../../assets/images/7-2.png";


import Slider from "../../components/Slider/Slider";


import ContactForm from "../../components/ContactForm/ContactForm";

import access_all_your_customer from '../../assets/images/access_all_your_customer.png'
import organizing_campaigns_img from '../../assets/images/organizing_campaigns_img.png'
import Intergrate_email_img from '../../assets/images/Intergrate_email_img.png'
import leverage_whatsapp_img from '../../assets/images/leverage_whatsapp_img.png'
import seamless_voice_calls_img from '../../assets/images/seamless_voice_calls_img.png'
import via_SMS from '../../assets/images/via_SMS.png'



export default function Home(props) {

  const images = [
    customer_support,
    customer_support,
    customer_support,
    customer_support,
  ];


  const feedback = [
    {
      quote:
        'Kasookoo’s depth of industry knowledge, world-class infrastructure, top-notch support and presence in multiple African countries was what we needed to support our expansion plans. They have helped us stay connected to our customers, resolve issues, have real-time conversations with them about matters that are pertinent to them. Most importantly, we built trust at Trade Depot and transition our Telesales team to a remote distributed team.',
      name: 'Feyishayo Akimoboye',
      role: 'Lead, Telecomms Team, Trade Depot',
      image: tradeDepotLogo,
    },
    {
      quote: 'Kasookoo’s has everything it take to deliver on a messaging campaign and that is why we trusted them for our campaign.',
      name: 'Dufil Foods',
      role: '',
      image: dufil,
    },
    {
      quote:
        'Kasookoo helped us to optimize our operations and team strength in responding and communication with our clients. The insights from the Kasookoo platform were especially relevant to help us make strategic marketing and customer service decisions.',
      name: 'Wella Health',
      role: '',
      image: wellahealth,
    },

    {
      quote:
        "Kasookoo's contact center product has revolutionized our operations, providing seamless integration and an intuitive interface. The outstanding support has exceeded our expectations. Kasookoo has enabled us to deliver exceptional customer experiences with insightful analytics and customizable options. Their team genuinely cares about our success and actively incorporates feedback. We highly recommend Kasookoo to any company in need of a reliable contact center solution and responsive support.",
         name: 'Pravesh Komal',
      role: 'Inland Sales Director',
      image: logo3,
    }


  ];




  return (
    <div className="home">
      <Navbar
        heading="Optimize customer engagement on a large scale"
        text="With our tailored cloud communication and customer engagement solutions, Kasookoo empowers businesses to create personalized and seamless customer experiences (CX) that are perfectly suited for the digital era."
        img={optimize_customer_img}
        contact_center_form="false"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>
      {/* <img className='w-100' style={{height:'100vh'}} src={background_img} alt="" /> */}

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h6 class="style-h6">
                  TRUSTED BY LEADING GLOBAL ORGANISATIONS
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-logos pb-3 hide-slick-dots-on-desktop"
      >
        <style></style>

        <div class="w-100  py-3 py-md-5 ">
          {/* <Slider/> */}
         
            <div className="d-flex text-center mt-10">
              <div class="w-100 section-logos-slider py-3 py-md-5  ">
                <img src={logo4} width="200px" style={{marginBottom:"25px"}} />
                <img src={logo3} width="200px" style={{marginBottom:"25px"}}/>
                <img src={tradeDepotLogo} width="200px" style={{marginBottom:"25px"}}/>
                <img src={logo2} width="200px" style={{marginBottom:"25px"}} />
                <img src={logo1} width="200px" style={{marginBottom:"25px"}} />

             
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-topic_center_repeater pb-4 text-white"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Expand and redefine the CX across
                    Africa and other regions
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      By leveraging Kasookoo as your reliable partner, you can
                      venture into new markets with increased assurance, while
                      simultaneously gaining command over your customer
                      experience (CX) and customer support through omnichannel
                      communications.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5 ">
                <div className="p-4">
                  <p>&nbsp;</p>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: "0",
                    }}

                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                      }}
                      src="https://www.youtube.com/embed/yJPbyDcqSeE?hide_owner=true&amp;hide_share=true&amp;hide_title=true&amp;hideEmbedTopBar=true"
                      frameborder="0"
                      allowfullscreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title pt-5 pb-3"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 pb-3 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Ignite transformative discussions that redefine the trajectory
                  of business growth
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary py-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%", minHeight: "fit-content" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={grup1} alt="Customized solutions icon" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Customized Solutions </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Tailored features and integrations designed specifically
                      for your intricate business requirements.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%", minHeight: "fit-content" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={grup2}
                      alt="Achieving growth and scalability icon"
                    />
                  </div>

                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Achieving Growth & Scalability </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">

                      Attain rapid market entry in Africa through scalable
                      solutions that expand alongside your business as you
                      venture into new markets.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%", minHeight: "fit-content" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={grup3} alt="Enhanced Customer Service icon" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Enhanced Customer Service</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Count on us as your trusted support partner for all your
                      platform and network requirements.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%", minHeight: "fit-content" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={grup4} alt="Seamless Processes Icon" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Seamless Workflows & Processes</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Experience seamless integration and optimal collaboration
                      between all your on-premise and digital business tools.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-topic_center_repeater pb-4"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={contact_center_icon}
                      alt="KASOOKOO CONTACT CENTRE"
                    />
                    KASOOKOO CONTACT CENTRE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Transform every interaction into a smooth
                    experience
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Establish connections with your customers in their
                      preferred manner by consolidating and streamlining your
                      communication channels through Kasookoo Contact Centre.
                    </p>
                    <p>
                      Our cloud-based platform combines the convenience of
                      omnichannel with the power of enterprise-grade African
                      network connectivity in one solution.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/#/contact-center"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Explore Kasookoo Contact Centre
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={transform_every_img}
                  alt="Kasookoo Contact Centre"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row small_issues pt-5 py-md-5 style-blue">
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Elevate your workforce
                </div>
                <div class="text-entry px-md-4">
                  Get a unified view of all communications
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Harness the power of data
                </div>
                <div class="text-entry px-md-4">
                  Gain valuable insights to customize customer experiences
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Driven by Artificial Intelligence
                </div>
                <div class="text-entry px-md-4">
                  Deepen customer knowledge through advanced sentiment
                  analysis
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-topic_center_repeater pb-4"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-5 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={contact_center_icon}
                      alt="Kasookoo Cloud Numbering Service"
                    />
                    KASOOKOO CLOUD NUMBERING SERVICE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Seamlessly connect the teams across the world
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Elevate your telecommunications experience with Kasookoo’s Cloud Number Service (CNS), the ideal solution for hyper scalers, Contact Center technology providers, multinationals seeking local telephony numbering integration into Microsoft Teams or Zoom across their operating countries, as well as other UCaaS or CCaaS platforms, and Communication Platform as a Service (CPaaS) providers.
                    </p>
                    {/* <p>
                      Our cloud-based platform combines the convenience of
                      omnichannel with the power of enterprise-grade African
                      network connectivity in one solution.
                    </p> */}
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/#/cloud-numbering-service"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn more about Cloud Number Service
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-7 side-two  ps-md-5">
                <img
                  src={cnsimg}
                  alt="Cloud Numbering Service"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row small_issues pt-5 py-md-5 style-blue">
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Connect everywhere
                </div>
                <div class="text-entry px-md-4">
                  Numbers of 15+ African countries
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Local Pricing
                </div>
                <div class="text-entry px-md-4">
                  Make and receive calls within budget
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  All Capabilities
                </div>
                <div class="text-entry px-md-4">
                  Numbers with Voice, SMS and WhatsApp
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-7"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={embeddables_icon}
                    alt="KASOOKOO EMBEDDABLES"
                    class="me-2"
                  />
                  KASOOKOO EMBEDDABLES
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Discover user-friendly experience tailored to your platform
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title pb-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Seamlessly integrate communication channels, foster customer
                  relationships, leverage contextual data, and unlock numerous
                  possibilities for your product, app, or web browser.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-10"
        class="w-100 position-relative block-block_text pb-5"
      >
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-2880 size-full"
                  src={expericence_user_img}
                  alt=""
                  sizes="(max-width: 984px) 100vw, 984px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-11"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Take full control of every communication on your platform
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-12"
        class="w-100 position-relative block-items_left_icon pb-5"
      >
        <style></style>
        <div class="w-100 section-items_left_icon">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={surface}
                    alt="Protect customer data and privacy"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">Protect customer data and privacy</p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={trust}
                    alt="Boost trust by improving user confidentiality"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Enhance user confidentiality to bolster trust
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={layer}
                    alt="Gain valuable data and insights to construct superior customer experiences"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Gain valuable data and insights to construct superior
                    customer experiences
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccordionComponent />

      {/* <div
        id="num-section-14"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Frame-4653424-1.svg"
                    alt="KASOOKOO BUSINESS TELEPHONY"
                    class="me-2"
                  />
                  KASOOKOO BUSINESS TELEPHONY
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div
        id="num-section-15"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Elevate your business communications to new heights
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div
        id="num-section-16"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Kasookoo for Microsoft Teams
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Enable seamless global calling from any location and
                      device using a unified telephony integration designed
                      specifically for Microsoft Teams. We have the right
                      solution for your telephony needs, whether you're a large
                      enterprise or a small business.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/microsoft-teams"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Discover More!
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={microsoft_teams_img}
                  alt=""
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Get the best of both worlds with Zoom Phone and Kasookoo
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Experience the full suite of Zoom Phone features and
                      capabilities you adore, enhanced by the robust
                      enterprise-grade connectivity provided by Kasookoo
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/zoom-phone/"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More!
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={zoom_phone_img}
                  alt="Toku for Zoom Phone"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div> */}

      {/* <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div> */}

      <div
        id="num-section-17"
        class="w-100 position-relative block-centered_title pb-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Feedback from our valued customers</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-18"
        class="w-100 position-relative block-centered_title pt-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">

                  Renowned brands worldwide rely on Kasookoo to empower them in
                  creating a highly scalable and effortlessly seamless customer
                  experience (CX).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-19"
        class="w-100 position-relative block-recommendations_slider py-5 pb-5"
      >
        <Feedback feedback={feedback} />
      </div>

      {/* <div
        id="num-section-20"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Security is our top priority
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      
                      Our Information Security Management System, which
                      encompasses the development, provisioning, and maintenance
                      of Telco Interfaces, Cloud Communications, and Customer
                      Engagement Platforms with connecting APIs and SDKs, is
                      certified for
                      <br />
                      ISO/IEC 27001:2013.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/02/ISO-certification.png"
                  alt="ISO certification"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="num-section-21"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={join_forces_2}
                  alt="Partner with Kasookoo to revolutionize CX in the present era! "
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={join_forces_1}
                  alt="Partner with Kasookoo to revolutionize CX in the present era! "
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Partner with Kasookoo to revolutionize CX in the present era!
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <button

                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto"
                    onClick={() => { props.setIsFormOpen(true) }}
                  >
                    Talk to an Expert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  );
}



export function AccordionComponent() {
  const list_accordion_6 = [
    {
      title: "In-App Voice Calling",
      text: "<p>Simplify the process of connecting with you or third parties through voice calls, ensuring a secure user experience within your app. Collect valuable data that can be transformed into impactful business insights.</p>",
      image: seamless_voice_calls_img,
      bottom_arrow_link: {
        title: "Add In-App Voice Calling Today",
        url: "/in-app-voice-calling/",
        target: "",
      },
    },

    {
      title: "Number Masking",
      text: "<p>Safeguard your users, agents, sellers, drivers, and service providers with a number masking API, enabling anonymization or customization of caller ids for enhanced user privacy.</p>",
      image: via_SMS,
      bottom_arrow_link: {
        title: "Learn More About Number Masking",
        url: "/number-masking",
        target: "",
      },
    },

    {
      title: "Programmable Voice",
      text: "<p>Enhance control and integrability by incorporating voice calling capabilities and expanding your range of voice functionalities through our programmable voice APIs.</p>",
      image: Intergrate_email_img,
      bottom_arrow_link: {
        title: "Integrate Voice Within Your Platform",
        url: "/programmable-voice/",
        target: "",
      },
    },

    {
      title: "Programmable Messaging",
      text: "<p>Bridge the gap with your audience and craft an exceptional messaging experience using our Programmable Messaging APIs.</p>",
      image: leverage_whatsapp_img,
      bottom_arrow_link: {
        title: "Explore More About Programmable Messaging",
        url: "/programmable-messaging/",
        target: "",
      },
    },
    {
      title: "User Verification",
      text: "<p>Enhance your customer validation process, boost conversion rates, and effectively combat fraud using our comprehensive set of APIs.</p>",
      image: seamless_voice_calls_img,
      bottom_arrow_link: {
        title: "Discover New Ways to Verify Your Customers",
        url: "/user-varification",
        target: "",
      },
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  const showItem = (index) => {
    setActiveItem(index);
  };
  return (
    <div
      id="num-section-13"
      className="w-100 position-relative block-accordion_list py-5"
    >
      <style>{`
        /* CSS styles here */
      `}</style>
      <div className="w-100 section-accordion_list ng-scope">
        <div className="container">
          <div className="row d-md-none">
            <div className="accordion accordion-flush" id="accordionFlush-6">
              {list_accordion_6.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2
                    className="accordion-header"
                    id={`flush-heading${index + 1}`}
                  >
                    <button
                      className={`accordion-button ${index === activeItem ? "style-h4" : "collapsed style-h4"
                        }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index + 1}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`flush-collapse${index + 1}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index + 1}`}
                    className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                      }`}
                    aria-labelledby={`flush-heading${index + 1}`}
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div className="accordion-body">
                      <div className="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          className="aligncenter size-full size-full mb-3 mb-md-0"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <div
                        className="d-block w-100 p-small"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-6">
              <h3 className="style-h3 mb-5">Explore new avenues to stay in touch</h3>
              {list_accordion_6.map((item, index) => (
                <button
                  className={`list-accordion style-h4 w-100 ng-binding ng-scope ${index === activeItem ? "active" : "text-dark"
                    }`}
                  key={index}
                  onClick={() => showItem(index)}
                  style={{ boxShadow: "none" }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="col-6">
              <div className="d-block w-100 mb-2">
                <img
                  decoding="async"
                  loading="lazy"
                  className="size-full"
                  src={list_accordion_6[activeItem].image}
                  alt={list_accordion_6[activeItem].title}
                />
              </div>
              <div className="d-block w-100">
                <div className="style-h4 mb-2">
                  {list_accordion_6[activeItem].title}
                </div>
              </div>
              <div
                className="d-block w-100 ng-binding"
                dangerouslySetInnerHTML={{
                  __html: list_accordion_6[activeItem].text,
                }}
              />
              <a
                className="btn btn-arrow text-start ng-binding ng-hide"
                href={list_accordion_6[activeItem].bottom_arrow_link.url}
              >{list_accordion_6[activeItem].bottom_arrow_link.title}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
