import React from "react";
import "./Slider.css";
import client1 from "../../assets/clientlogo/client1.png";
import client2 from "../../assets/clientlogo/client2.png";
import client3 from "../../assets/clientlogo/client3.png";
import client4 from "../../assets/clientlogo/client4.png";
import client5 from "../../assets/clientlogo/client5.png";
import client6 from "../../assets/clientlogo/client6.png";
import client7 from "../../assets/clientlogo/client7.png";
import client8 from "../../assets/clientlogo/client8.png";
import client9 from "../../assets/clientlogo/9.png";
import client10 from "../../assets/clientlogo/10.png";
import client11 from "../../assets/clientlogo/11.png";
import client12 from "../../assets/clientlogo/12.png";
import client13 from "../../assets/clientlogo/13.png";
import client14 from "../../assets/clientlogo/14.png";
import client15 from "../../assets/clientlogo/wellahealth.png"

export default function Slider() {
  return (
    <div>
      <div>
        <div class="row align-items-center">
          <div class="col-12 text-center slider-logos slick-initialized slick-slider slick-dotted d-flex">
            <div
              class="slider mt-2 w-100"
              style={{ overflow: "hidden", whiteSpace: "nowrap" }}
            >
              <div class="slide-item d-flex" style={{ height: "40px" }}>
                <img
                  loading="lazy"
                  src={client1}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client2}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client3}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client4}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client5}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client6}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client7}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client8}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client9}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client10}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client11}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client12}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client13}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client14}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                 <img
                  loading="lazy"
                  src={client15}
                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
