import React from "react";
import "./hostpitality.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import comapaign_img from "../../assets/images/consulting-img.png";
import expert from "../../assets/images/business_exper.svg";
import streamlineicon from "../../assets/images/stramline-icon.svg";
import send from "../../assets/images/send.svg";
import enhancedelieveryicon from "../../assets/images/enhance-delievery-icon.svg";
import messageicon from "../../assets/images/email.svg";
import micicon from "../../assets/images/voice-recorder (1).svg";
import user_verification_icon from '../../assets/images/user_verification_icon.svg'
import headphones from '../../assets/images/headphones.svg'
// import headphones from '../../assets/images/headphoneswhite.svg'
import alleviate_the_stress_img from '../../assets/images/alleviate-the-stress.png'
import improve_internal_img from '../../assets/images/improve-internal.png'
import retain_transactions_img from '../../assets/images/retain-transactions.png'
import streamline_user_verification_image from '../../assets/images/streamline-user-verification-image.png'
import streamline_your_marketing_image from '../../assets/images/streamline-your-marketing-image.png'
import travel_header_img from '../../assets/images/travel_header_img.png'
import bottomLeftImg from "../../assets/images/7-2.png"
import bottomRightImg from "../../assets/images/7-1.png"

import headphones_white_icon from '../../assets/images/headphones_white_icon.svg'
import plan_white_icon from '../../assets/images/plan_white_icon.svg'
import speaker_white_icon from '../../assets/images/speaker_white_icon.svg'
import telephone_white_icon from '../../assets/images/telephone_white_icon.svg'
import squres_white_icon from '../../assets/images/squres_white_icon.svg'
export default function Hostpitality(props) {
  return (
    <div className="hostpitality">
      <Navbar
        heading="Holistic business transformation solutions"
        text="Craft enhanced experiences for guests and travelers
      Empower your customers with personalized and secure experiences across various travel and hospitality interactions, be it confirming hotel bookings, resolving lost baggage inquiries, addressing billing concerns, or booking new flights. Achieve this through tailored cloud communications solutions explicitly designed for the travel and hospitality industry.
      "
        img={travel_header_img}
        extra_heading="KASOOKOO FOR TRAVEL & HOSPITALITY"
        first_section={true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Simplify customer experience</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={enhancedelieveryicon} alt="Group 1000005055" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Expand your communication </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      During peak booking seasons, it's crucial to have scalable
                      call and messaging capacity that aligns with your business
                      needs. With our solution, you can ensure that your
                      communication capabilities expand when required without
                      overpaying, thus optimizing cost efficiency.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={expert} alt="Group 1000005056" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Facilitate improved user relationships
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Enhance the booking and travel experience by integrating
                      additional communication channels into your app or
                      platform, fostering seamless communication among all
                      stakeholders involved.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={streamlineicon} alt="Group 7327" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Simplify your processes</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Accelerate your expansion into new markets effortlessly by
                      leveraging a unified communication provider to manage your
                      app or platform. Streamline your operations, minimize
                      expenses, and swiftly penetrate new locations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Ensure a comprehensive understanding of your guests' and
                  customers' preferred communication methods
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  The ever-evolving landscape of consumer preferences
                  necessitates offering a wide array of communication channels,
                  enhanced communication capabilities, and exceptional customer
                  service to meet the demands of guests and travelers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers considers SMS as a desired communication channel,
                surpassing all others in terms of popularity and preference.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">54</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers express a preference for voice calling in order to
                have instant resolution of issues.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">67</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers favor app environments regarding all kinds of
                workplace communications as their preferred communication.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <style></style>

        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/02/Frame.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to learn more about how consumers want to engage with
                    companies like yours?
                  </h4>
                  <div class="description mb-3">
                    *Read Kasookoo Singapore Consumer Engagement Report
                  </div>
                </div>
                <div class="d-block w-100">
                  <a class="btn btn-yellow" target="" href="">
                    Download Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={speaker_white_icon}
                      alt="KASOOKO IN-APP VOICE CALLING"
                    />
                    KASOOKOO IN-APP VOICE CALLING
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Retain transactions within your app
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Stay connected with your customers while they travel by
                      incorporating a reliable built-in channel within your
                      application, ensuring they remain updated and engaged on
                      the go.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/in-app-voice-calling" class="btn btn-arrow text-start" target="">
                    Bring Conversations Into Your App
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={retain_transactions_img}
                  alt="Group 1000005261"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={headphones_white_icon}
                      alt="KASOOKOO CONTACT CENTRE"
                    />
                    KASOOKOO CONTACT CENTRE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Alleviate the stress associated with travel complications
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      In times of travel challenges, provide your guests with a
                      hassle-free customer service experience, offering multiple
                      communication channels that lead to a centralized contact
                      center. This seamless approach ensures a straightforward
                      way for them to seek assistance while on the go.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/contact-center" class="btn btn-arrow text-start" target="">
                    Begin Your Omnichannel Journey
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={alleviate_the_stress_img}
                  alt="Image 34"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pb-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={plan_white_icon}
                      alt="KASOOKOO CAMPAIGN MANAGER"
                    />
                    KASOOKOO CAMPAIGN MANAGER
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Streamline your marketing efforts
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Achieve widespread user outreach effortlessly by
                      consolidating all your messaging campaigns into a unified
                      platform, enabling you to seamlessly send mass updates,
                      offers, and information.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/campaign-manager" class="btn btn-arrow text-start" target="">
                    Learn More About Sending Bulk Messages
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={streamline_your_marketing_image}
                  alt="Image 38"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={user_verification_icon}
                      alt="USER VERIFICATION"
                      
                    />
                    USER VERIFICATION
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Streamline user verification
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Enhance the security of customer bookings by leveraging
                      programmable voice and messaging APIs for verification
                      purposes. Combat fraud, automate the verification process,
                      and establish a secure environment to instill trust in
                      your customers.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/user-varification" class="btn btn-arrow text-start" target="">
                    Create Robust User Verification
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={streamline_user_verification_image}
                  alt="Frame 46260"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={telephone_white_icon}
                      alt="KASOOKOO BUSINESS TELEPHONY"
                    />
                    KASOOKOO BUSINESS TELEPHONY
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Improve internal communication
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Optimize your operational efficiency and reduce costs by
                      leveraging Microsoft Teams or Zoom Phone for seamless
                      internal and external communication across global team
                      members, hotels, and venues. Ensure smooth collaboration
                      and eliminate expensive calling charges while maintaining
                      productivity.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={improve_internal_img}
                  alt="Frame 46256"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center pt-4">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={squres_white_icon}
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Explore our diverse range of products and solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-5"
      >
        <style></style>
        <style></style>
        <div class="container section-2_items_in_row_with_image_title_text_link style-slimmer">
          <div class="row rseh-md pb-5">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/programmable-voice"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "120px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img src={micicon} alt="Programmable  Voice  " />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Voice
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Embed voice calls in your product
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/programmable-messaging"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "120px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img src={messageicon} alt="Programmable Messaging" />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Messaging
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Build the messaging experience you want
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomLeftImg}
                  alt="Create concierge-level customer experiences"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomRightImg}
                  alt="Create concierge-level customer experiences"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Elevate customer experiences to a concierge-level of service
                    excellence.
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    
                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto a-btn"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
