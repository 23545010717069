import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import kasookoocrm from "../../assets/images/Unlock Worldwide Calling Capabilities for CRM.png";
import whatiscrmImg from "../../assets/images/What is Kasookoo for CRM.png";
import kasookoophoneApp from "../../assets/images/CRM-Kasookoo Phone App.png";
import kasooNativeDialer from "../../assets/images/CRM-Kasookoo Native Dialler.png"

import botom from "../../assets/images/flow-chart.png"
import bottomMobile from "../../assets/images/kasookoo-microsoft-graph-mobile.png";

import enhancecallicon from "../../assets/images/enhance-call-icon.svg";
import optimisedcost from "../../assets/images/optimized-cost-icon.svg";
import unparaconicon from "../../assets/images/unparalleledconicon.svg";
import cuttingEdgeicon from "../../assets/images/cutting-edge-icon.svg";
import fullencicon from "../../assets/images/full-encryption-sol-icon.svg";
import unparaRealiabilityicon from "../../assets/images/unparallel-realiability-icon.svg";

import bottomimg from "../../assets/images/Want to get African connectivity for Kasookoo CRM.png";

import "./kasookooforcrm.css";
export default function KasookooForMicrosoft(props) {
  return (
    <div className="crm">
      <Navbar
        heading="Unlock Worldwide Calling Capabilities for CRM"
        text="Transform your corporate telephony with cloud migration and global calling capabilities, powered by a leading African communications provider. Simplify CRM connectivity, consolidate suppliers, and optimize costs with a seamless integration."
        img={kasookoocrm}
        extra_heading="Kasookoo for CRM "
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-2">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Connect and Communicate with CRM</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row  pt-0 pb-0">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Harness the power of a single bring-your-own-carrier (BYOC)
                  connection across African &amp; Middle Eastern countries for
                  enhanced call quality, cost efficiency, and increased
                  productivity. No concerns about CRM calling plans!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary "
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "416px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={enhancecallicon} alt="Group-7317" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Enhanced Call Clarity & Performance
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Offering PSTN replacement capabilities in 17 countries.
                      *licensed carrier in Africa.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "416px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={optimisedcost} alt="Vector" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Optimized Cost Effectiveness</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Unify your African telephony providers with a local
                      provider to unlock competitive calling rates and
                      streamlined operations.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "416px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={unparaconicon} alt="Group 1000004590" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Unparalleled Connectivity Assistance
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Experience unwavering confidence with African-based
                      network operations centre (NOC) for complete peace of
                      mind.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "416px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={cuttingEdgeicon} alt="Group 1000004580" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Cutting-Edge Solutions for Enterprises
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Gain entry to a robust, enterprise-grade administrative
                      interface for centralized management, eliminating the need
                      for installations or hardware.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "416px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={fullencicon} alt="Group 1000004578" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Fully Encrypted Solution</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      The Kasookoo for CRM environment and the client enforce
                      rigorous encryption, ensuring end-to-end protection of
                      both signaling and media.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "416px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={unparaRealiabilityicon} alt="Group 1000004576" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Unparalleled Reliability and Redundancy
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Kasookoo for CRM employs load balancing and leverages a
                      distributed server infrastructure spanning Africa,
                      ensuring exceptional availability and redundancy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-center_button "
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              <a
                // href="#MSTeams"

                class="a-btn btn btn-blue"
                onClick={()=>{props.setIsFormOpen(true)}}
                // target=""
                // data-bs-toggle="modal"
                // data-bs-target="#MSTeams"
              >
                Schedule a Demo!
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={whatiscrmImg}
                  alt="MS Teams illustration"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h2 class="style-h2">What is Kasookoo for CRM?</h2>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Kasookoo for CRM is a cloud-based service that seamlessly
                      integrates CRM users with the Kasookoo PBX or Kasookoo SIP
                      Trunk, facilitating efficient communication and
                      collaboration.
                    </p>
                    <p>
                      Gain Enhanced Cost Control by Paying for Calls Based on
                      Actual Usage, Putting You in Charge of Your Communication
                      Expenses.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    class="a-btn btn btn-arrow text-start"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                  >
                    Talk to An Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Choose Between a Streamlined No-Frills Application or a
                  Feature-Rich Integration to suit your needs
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Irrespective of your requirements, whether you have a team of
                  professionals seeking high-quality calls or an international
                  business aiming to maximize the potential of the Teams
                  platform, Kasookoo provides two options tailored to suit any
                  type of business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={kasookoophoneApp}
                  alt="Kasookoo Phone App"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h2 class="style-h2"> Kasookoo Phone App </h2>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Unleash global calling from any location and on any
                      device, utilizing any CRM without the need for calling
                      plan bundle licenses. Empower remote collaboration within
                      teams with the calling capability essential for seamless
                      connectivity.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="#MSTeams"
                    class="btn btn-arrow text-start"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#MSTeams"
                  >
                    Learn More About Kasooko Phone App
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={kasooNativeDialer}
                  alt="Kasookoo Native Dialler"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h2 class="style-h2"> Kasookoo Native Dialler </h2>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Enable Direct Global Calling* within the Native CRM
                      Dialer, Unlocking Advanced Calling Features such as Ring
                      Groups, Call Queuing, Call Forwarding, and Voicemail.
                      *E1/E3/E5 licenses
                    </p>
                    <p>*E1/E3/E5 licenses</p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="#MSTeams"
                    class="btn btn-arrow text-start"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#MSTeams"
                  >
                    Let’s Chat About Kasooko for CRM
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-0">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Get Ready to Connect! Dial-Up and Engage!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title pt-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-4">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Embark on your journey to unify internal communications,
                  starting today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-desktop_and_mobile_content "
      >
        <style></style>
        <div class="w-100 section-desktop_and_mobile_content  ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-auto d-none d-md-block ">
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="aligncenter wp-image-5042 size-full"
                    src={botom}
                    alt=""
                    width="1320"
                    height="412"
                  />
                </p>
              </div>
              <div class="col-12 col-md-auto d-block d-md-none ">
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="aligncenter wp-image-5043 size-full"
                    src={bottomMobile}
                    alt=""
                    width="263"
                    height="auto"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-center_button text-center pb-5"
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row text-center pb-5">
            <div class="col-12 mb-3">
              <a
                class="a-btn btn btn-arrow"
                onClick={() => {
                  props.setIsFormOpen(true);
                }}
              >
                Talk to An Expert
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-cta_strip_2 text-center text-md-start"
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class=" col-md-6 d-flex ps-md-5  order-last order-md-last"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={bottomimg}
                  alt="Want to get African connectivity  CRM? "
                  class="img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class=" col-md-6 py-4 py-md-5 my-auto px-lg-4 text-center text-md-start">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Want to get African connectivity for Kasookoo CRM?
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    class="a-btn blact_btn"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                  >
                    Talk to An Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
