import "./footer.css";
import logo from "../../assets/images/logoFooter.svg";
import fac from "../../assets/images/facebook-app-symbol.svg";
import twi from "../../assets/images/twitter.svg";
import insta from "../../assets/images/instagram.svg";
import you from "../../assets/images/youtube.svg";
import lin from "../../assets/images/_x31_0.Linkedin.svg";
import React from "react";
// import logo from "../../../assets/images/logoFooter.svg";
export default function Footer() {
  return (
    <div>
      
      {/* <footer classNameName=" text-center text-color">
        <div style={{ paddingTop: "80px" }} className="container">
          <section className="">
            <div className="row footer-h5">
              <div className="col-12 col-md-4 col-lg">
                <img width="170px;" src={logo} alt="" />

                <ul className="list-unstyled mb-0"></ul>
              </div>
              <div className="col-12 col-md-4 col-lg">
                <h5 className="text-uppercase">OUR PRODUCTS</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="/" className="text-color">
                      Contact Centre
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Campaign Manager
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Programmable Voice
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      In-App Voice Calling
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Programmable Messaging
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Number Masking
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      User Verification
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Kasookoo for Microsoft Teams
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-4 col-lg">
                <h5 className="text-uppercase">COMPANY</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="/" className="text-color">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Why Kasookoo
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Partners
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Press
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      User Verification
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Carrier Services
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-4 col-lg">
                <h5 className="text-uppercase">SOLUTIONS</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="/" className="text-color">
                      Government Solutions
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Fintech
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Insurance Services
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Travel & Hospitality
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Retail & eCommerce
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-4 col-lg">
                <h5 className="text-uppercase">RESOURCES</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="/" className="text-color">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Customer Stories
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      API Documentation
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-color">
                      Help Centre
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div className="row bottom-footer">
            <div className=" p-3 col-auto col-lg">
              <a className="text-color  " href="#">
                Terms & Conditions
              </a>
              <a
                style={{ marginLeft: "40px" }}
                className="text-color "
                href="#"
              >
                Privacy Policy
              </a>
            </div>
            <div className=" col-auto p-3 col-lg">
              <div className="text-color soical-icon " href="#">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-instagram"></i>
                <i className="fa fa-linkedin"></i>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      <footer class="footer w-100 py-3 bg-blue-light">
        <div class="container py-5 my-1">
          <div class="row">
            <div class="col-12 col-md-4 col-lg">
              <div class="w-100 d-block d-md-none mb-4">
                <img
                  src={logo}
                  alt=""
                />
              </div>
              <div class="w-100 d-none d-md-block mb-3">
                <a href="/" class="col-auto header-logo">
                  <img
                    width={"170px"}
                    loading="lazy"
                    src={logo}
                    alt="Group 6722"
                    class=""
                  />
                </a>
              </div>
              <div class="w-100 d-none d-md-block mb-1">
                <a
                  class="button"
                  href="tel:+65%203138%203138"
                  target="_self"
                ></a>
              </div>
            </div>
            <div
              class="col-12 col-md-4 col-lg accordion-item footer-menu"
              id="accordionFooterMenu1"
            >
              <h6 class="footer-title accordion-header" id="flush-heading1">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse1"
                  aria-expanded="false"
                  aria-controls="flush-collapse1"
                >
                  OUR PRODUCTS
                </button>
                <span>OUR PRODUCTS </span>
              </h6>
              <div class="footer-menu-list">
                <div
                  id="flush-collapse1"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-heading1"
                  data-bs-parent="#accordionFooterMenu1"
                >
                  <div class="menu-footer-our-products-container">
                    <ul id="menu-footer-our-products" class="menu">
                      <li
                        id="menu-item-2211"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2211"
                      >
                        <a href="/#/contact-center">Contact Centre</a>
                      </li>
                      <li
                        id="menu-item-2253"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2253"
                      >
                        <a href="/#/campaign-manager">Campaign Manager</a>
                      </li>
                      <li
                        id="menu-item-2212"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2212"
                      >
                        <a href="/#/programmable-voice">Programmable Voice</a>
                      </li>
                      <li
                        id="menu-item-2252"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2252"
                      >
                        <a href="/#/in-app-voice-calling">In-App Voice Calling</a>
                      </li>
                      <li
                        id="menu-item-2213"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2213"
                      >
                        <a href="/#/programmable-messaging">
                          Programmable Messaging
                        </a>
                      </li>
                      <li
                        id="menu-item-2254"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2254"
                      >
                        <a href="/#/number-masking">Number Masking</a>
                      </li>
                      <li
                        id="menu-item-2255"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2255"
                      >
                        <a href="/#/user-varification">User Verification</a>
                      </li>
                      <li
                        id="menu-item-2256"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2256"
                      >
                        <a href="/#/kasookoo-for-crm" >
                          Kasookoo for CRM
                        </a>
                      </li>
                      <li
                        id="menu-item-2256"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2256"
                      >
                        <a href="/#/zoho-phonebridge-integration" >
                          Integrations
                        </a>
                      </li>
                      {/* <li
                        id="menu-item-2257"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2257"
                      >
                        <a href="/#/kasookoo-for-zoom">Kasookoo for Zoom Phone</a>
                      </li> */}
                      <li
                        id="menu-item-2257"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2257"
                      >
                        <a href="/#/cloud-numbering-service">Cloud Numbering Service</a>
                      </li>
                      {/* <li
                        id="menu-item-4594"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4594"
                      >
                        <a href="/">Kasookoo for Salesforce</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-4 col-lg accordion-item footer-menu"
              id="accordionFooterMenu2"
            >
              <h6 class="footer-title accordion-header" id="flush-heading2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse2"
                  aria-expanded="false"
                  aria-controls="flush-collapse2"
                >
                  COMPANY
                </button>
                <span>COMPANY </span>
              </h6>
              <div class="footer-menu-list">
                <div
                  id="flush-collapse2"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-heading2"
                  data-bs-parent="#accordionFooterMenu2"
                >
                  <div class="menu-footer-company-container">
                    <ul id="menu-footer-company" class="menu">
                      <li
                        id="menu-item-2259"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2259"
                      >
                        <a href="/#/about-us">About Us</a>
                      </li>
                      <li
                        id="menu-item-2263"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2263"
                      >
                        <a href="/#/why-kasookoo">Why Kasookoo</a>
                      </li>
                      <li
                        id="menu-item-2260"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2260"
                      >
                        <a href="/#/careers">Careers</a>
                      </li>
                      <li
                        id="menu-item-5900"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5900"
                      >
                        <a href="/#/contact-us">Contact Us</a>
                      </li>
                      <li
                        id="menu-item-2211"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2211"
                      >
                        <a href="/#/africa-tech-festival-2023">Africa Tech Festival 2023</a>
                      </li>
                      {/* <li
                        id="menu-item-2261"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2261"
                      >
                        <a href="/partners">Partners</a>
                      </li> */}
                      {/* <li
                        id="menu-item-5171"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5171"
                      >
                        <a href="/press">Press</a>
                      </li> */}
                      {/* <li
                        id="menu-item-6016"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6016"
                      >
                        <a href="/carrier-services">Carrier Services</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-4 col-lg accordion-item footer-menu"
              id="accordionFooterMenu3"
            >
              <h6 class="footer-title accordion-header" id="flush-heading3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse3"
                  aria-expanded="false"
                  aria-controls="flush-collapse3"
                >
                  SOLUTIONS
                </button>
                <span>SOLUTIONS </span>
              </h6>
              <div class="footer-menu-list">
                <div
                  id="flush-collapse3"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-heading3"
                  data-bs-parent="#accordionFooterMenu3"
                >
                  <div class="menu-footer-solutions-container">
                    <ul id="menu-footer-solutions" class="menu">
                      <li
                        id="menu-item-2264"
                        class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-980 current_page_item menu-item-2264"
                      >
                        <a href="/#/government-solution" aria-current="page">
                          Government &amp; Political Campaign/Surveys
                        </a>
                      </li>
                      <li
                        id="menu-item-4383"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4383"
                      >
                        <a href="/#/FinTech">Fintech</a>
                      </li>
                      <li
                        id="menu-item-2267"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2267"
                      >
                        {/* <a href="/#/sharing-economy">Sharing Economy</a>
                         */}
                         <a href="/#/EduTech" >Edutech</a>
                      </li>
                      <li
                        id="menu-item-4382"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4382"
                      >
                        {/* <a href="/#/insurance-services">Insurance Services</a> */}
                        <a href="/#/outbound-callcentre">Outbound Call Center</a>
                      </li>
                      <li
                        id="menu-item-4382"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4382"
                      >
                        {/* <a href="/#/insurance-services">Insurance Services</a> */}
                        <a href="/#/debtcollection">Debt Collection</a>
                      </li>
                      <li
                        id="menu-item-4385"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4385"
                      >
                        <a href="/#/hostpitality">Travel &amp; Hospitality</a>
                      </li>
                      <li
                        id="menu-item-4384"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4384"
                      >
                        <a href="/#/retail-ecommerce">E-commerce &amp; FMCG</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-4 col-lg accordion-item footer-menu"
              id="accordionFooterMenu4"
            >
              <h6 class="footer-title accordion-header" id="flush-heading4">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse4"
                  aria-expanded="false"
                  aria-controls="flush-collapse4"
                >
                  RESOURCES
                </button>
                <span>RESOURCES </span>
              </h6>
              <div class="footer-menu-list">
                <div
                  id="flush-collapse4"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-heading4"
                  data-bs-parent="#accordionFooterMenu4"
                >
                  <div class="menu-footer-resources-container">
                    <ul id="menu-footer-resources" class="menu">
                      {/* <li
                        id="menu-item-2268"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2268"
                      >
                        <a href="/">Blog</a>
                      </li> */}
                      {/* <li
                        id="menu-item-5494"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5494"
                      >
                        <a href="/customer">Customer Stories</a>
                      </li> */}
                      {/* <li
                        id="menu-item-6014"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6014"
                      >
                        <a href="/">API Documentation</a>
                      </li> */}
                      {/* <li
                        id="menu-item-6015"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6015"
                      >
                        <a href="/help-center">Help Centre</a>
                      </li> */}
                      <li
                        id="menu-item-6015"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6015"
                      >
                        <a href="https://kasookoo.com/downloads/kasookoo-app.zip">
                          Download Desktop App
                        </a>
                      </li>
                      <li
                        id="menu-item-6015"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6015"
                      >
                        <a href="/#/faqs">FAQs</a>
                      </li>
                      {/* <li
                        id="menu-item-6015"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6015"
                      >
                        <a >
                          For Developers
                        </a>
                      </li> */}

                      <li
                        id="menu-item-6015"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6015"
                      >
                        <a href="/#/gdpr">EU DATA PROTECTION</a>
                      </li>

                      <li
                        id="menu-item-6015"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6015"
                      >
                        <a href="/#/contact-us">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-2 pt-md-4 pb-2 pb-md-5">
              <div class="w-100 footer-line-space"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 footnote-links">
              <a
                class="
                                            w-100 d-block mb-3 mt-2
                                            w-md-auto d-md-inline my-md-0 me-5
                                        "
                href="/#/terms-and-conditions"
                target="_self"
              >
                Terms &amp; Conditions
              </a>
              <a
                class=" w-100 d-block mb-3 mt-2
                                            w-md-auto d-md-inline my-md-0 me-5
                                        "
                href="/#/privacy-policy/"
                target="_self"
              >
                Privacy Policy
              </a>

             
            </div>
            <div class="col-12 col-md-4 footnote-links text-center">
            <span style={{textAlign:"center", fontWeight:"bold"}}>
             
                  © 2023 Kasookoo. 
                  {/* Powered by <a href="https://speckpro.com/" target="_blank">SpeckPro Digital.</a>           */}
             
              </span>
              </div>
            
            <div class="col-12 col-md-4 links-to-pages">
              <div class="w-auto d-inline-block d-md-table mt-2 mt-md-0 ms-md-auto me-md-0">
                <a
                  class="footer-social-item me-3 me-md-0 ms-md-4"
                  href="https://www.linkedin.com/company/kasookoo/"
                  target="_blank"
                  title="Kasookoo Linkedin"
                >
                  <img src={lin} alt="social icon" />
                </a>
                <a
                  class="footer-social-item me-3 me-md-0 ms-md-4"
                  href="https://www.youtube.com/@kasookoo3852"
                  target="_blank"
                  title="Kasookoo YouTube"
                >
                  <img src={you} alt="social icon" />
                </a>

                <a
                  class="footer-social-item me-3 me-md-0 ms-md-4"
                  href="https://www.instagram.com/kasookoong/"
                  target="_blank"
                  title="Kasookoo Instagram"
                >
                  <img src={insta} alt="social icon" />
                </a>
                <a
                  class="footer-social-item me-3 me-md-0 ms-md-4"
                  href="https://web.facebook.com/kasookoonigeria"
                  target="_blank"
                  title="Kasookoo Facebook"
                >
                  <img src={fac} alt="social icon" />
                </a>
                <a
                  class="footer-social-item me-3 me-md-0 ms-md-4"
                  href="https://twitter.com/Kasookoo1"
                  target="_blank"
                  title="Kasookoo Twitter"
                >
                  <img src={twi} alt="social icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
