import React from 'react'
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import './faqs.css'
export default function (props) {
    return (
        <div className='faqs'>
             <Navbar
        extra_heading = "KASOOKOO CONTACT CENTRE"
        heading="A highly efficient Contact Center for Increased Productivity"
        text="Enable smooth and effortless customer interactions by leveraging a comprehensive cloud-based contact center solution that seamlessly integrates calls, messages, and OTT conversations. Empower your agents and managers with robust integrations that enhance customer engagement to an unprecedented level."
        img={optimize_customer_img}
        first_section = {false}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>
            <section class="w-100 d-block hero-single-careers pt-5 d-flex align-items-center mb-5" style={{ backgroundColor: '#7DBAAB' }}>
                <div class="container py-5 text-white">
                    <div class="row text-center py-2">
                        <div class="col-12 col-md-8 col-xl-6 mx-auto">
                            <h1 class="style-h3">FREQUENTLY ASKED QUESTIONS</h1>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">

                    </div>
                </div>
            </section>
           

		
	
		<section id="">
				<div class="container">
					<h3 class="section-title wow fadeIn animated" data-wow-delay=".2s" style={{visibility: 'visible'}}>
						Setting up and using Kasookoo
					</h3>
					
						<h4> How does Kasookoo work? </h4>
						<p style={{marginLeft: '20px'}}> Kasookoo takes all the functionality of a full business phone system (Multiple phone lines, deskphones, receptionist, call routing, call transfers, conference calling, call recording) and concentrates it into one simple app that is installed on your mobile devices or computers; but gives you more functionality and low international calling rates.</p>

						<h4> Do I need to have any technical knowledge to use Kasookoo?</h4>
						<p style={{marginLeft: '20px'}}> No. We’ve made it really easy with our simple app. As long as you can operate the basic functions on a mobile phone or a computer system, you’ll find Kasookoo a breeze to setup and use. Simply download our app, and you’re ready to go. </p>

						<h4> How long will I have to wait until my Kasookoo system is ready to use? </h4>
							<p style={{marginLeft: '20px'}}>Once you've signed up and installed one of our apps, your account is ready to use instantly!</p>

						<h4> Does it cost anything to receive calls on Kasookoo? </h4>
							<p style={{marginLeft: '20px'}}>No. Receiving calls is free of charge</p>

						<h4>How many international countries does Kasookoo support?</h4>
							<p style={{marginLeft: '20px'}}>You can make calls to over 170 countries. Please refer to our pricing page for more details.</p>

						<h4> Am I tied into a contract with Kasookoo? </h4>
							<p style={{marginLeft: '20px'}}>No, there’s no commitment with Kasookoo. You just need to accept our terms and conditions. </p>

					<h3 class="section-title wow fadeIn" data-wow-delay=".2s" style={{visibility: 'hidden'}}>
						Users and Numbers
					</h3>

						<h4> How do I add extra users to Kasookoo? </h4>
							<p style={{marginLeft: '20px'}}>On the home page click on “Users” and you can easily add them. You can select pay as you go for free, or a Pro if you want unlimited calls to the UK, US, and Canada.</p>

						<h4> How do I add extra phone numbers to Kasookoo? </h4>
							<p style={{marginLeft: '20px'}}>From the home page click on “Phone number” and it will guide you to the right page to add more numbers or port your existing number.</p>

						<h4> I need an international phone number. Can you still help me? </h4>
							<p style={{marginLeft: '20px'}}>Yes we can offer you a whole range of international numbers. Contact us directly for this.</p>

						<h4> I already have a phone number. Can I transfer this to Kasookoo? </h4>
							<p style={{marginLeft: '20px'}}>Yes. Simple go to “Phone number” in the home page, and you can add it from there. We can transfer mobile or landline numbers.</p>

						<h4> How long will it take to transfer a number? </h4>
							<p style={{marginLeft: '20px'}}>Mobile numbers take about two days, landlines take about a week.</p>

						<h4> If I decide to leave Kasookoo, can I take my number with me? </h4>
							<p style={{marginLeft:'20px'}}> Yes, if this occurs you can take your number to another provider. </p>

						<h4> Can I use Kasookoo in different countries? </h4>
							<p style={{marginLeft:'20px'}}> Kasookoo will work in most countries around the world, providing you have a data or WiFi connection available. However, there are a small number of countries which block internet-based calling for either security or revenue-protection reasons. Internet-based calling is currently blocked in Azerbaijan, Belize, China, Iran, Jordan, Kuwait, Libya, North Korea, Oman, Pakistan, Qatar, Saudi Arabia, South Korea, Syria and UAE. You can still call these countries using Kasookoo, it’s just outbound calls that are blocked. </p>

						<h4> My colleagues currently use their own mobile phones, and we pay for their calls through expenses. Does Kasookoo replace that? </h4>
							<p style={{marginLeft:'20px'}}> Yes. We can save you some money. Your colleagues can simply add their business phone numbers to their own smartphones and computer systems to make and receive calls. There’s no longer any need for extra business phones or extra sim cards. </p>

						<h4>So I can cancel my existing phone contracts? </h4>
							<p style={{marginLeft:'20px'}}> That's up to you. You don't need an existing landline or mobile contract to use kasookoo. Of course, if your internet connection is provided through your landline or mobile, you’ll still need that.</p>

						<h4>How much data does Kasookoo use? </h4>
							<p style={{marginLeft:'20px'}}> As a general guide, Kasookoo will use approx 1mb of data per minute. This can be variable, depending on which additional features you are using. Kasookoo’s data consumption is at most equivalent to most other IP or cloud-based systems.</p>


				</div>
		</section>


            <Footer/>

        </div>
    )
}
