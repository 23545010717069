import "./whykasookoo.css";
import Navbar from "../../components/navbar/Navbar";
import consulting_img from "../../assets/images/main_right.png";
import africa_map1_img from "../../assets/images/africa-map1.png";
import africa_map2_img from "../../assets/images/africa-map2.png";
import headphone from "../../assets/images/headphones.svg";
import voice from "../../assets/images/voice-recorder (1).svg";
import speaker from "../../assets/images/speaker.svg";
import send from "../../assets/images/send.svg";
import STAT from "../../assets/images/statistics.svg";

import email from "../../assets/images/email.svg";
import tick from "../../assets/images/true.svg";
import con from "../../assets/images/connectivity.svg";
import call from "../../assets/images/call.svg";
import sur from "../../assets/images/surface1.svg";
import l1 from "../../assets/images/l1.png";
import l2 from "../../assets/images/l2.png";
import l3 from "../../assets/images/l3.png";
import l4 from "../../assets/images/l4.png";
import l5 from "../../assets/images/l5.png";
import NET from "../../assets/images/NETWORK.png";
import curve from "../../assets/images/tailord-why.png";
import PROG from "../../assets/images/GOV-SOL.png";
import HELP from "../../assets/images/HELP AGENT.png";
import Footer from "../../components/footer/Footer";
import ban from "../../assets/images/Group 1188.png";
import Slider from "../../components/Slider/Slider";
import Feedback from "../../components/Feedback/Feedback";

import squres_white_icon from '../../assets/images/squres_white_icon.svg'
import our_awards from '../../assets/images/our_awards.svg'
import our_customers from '../../assets/images/our_customers.svg'
import our_products from '../../assets/images/our_products.svg'
import our_statistics from '../../assets/images/our_statistics.svg'


import tradeDepotLogo from "../../assets/clientlogo/12.png";
import dufil from "../../assets/clientlogo/13.png";
import wellahealth from "../../assets/clientlogo/wellahealth.png"
import inLandSales from "../../assets/clientlogo/InlandSales.png"

import logo1 from "../../assets/clientlogo/19.png"
import logo2 from "../../assets/clientlogo/20.png"
import logo3 from "../../assets/clientlogo/21.png"
import logo4 from "../../assets/clientlogo/22.png"


import { useState } from "react";
export default function WhyKasookoo(props) {

  const feedback =[
    {
        quote:
            'Kasookoo’s depth of industry knowledge, world-class infrastructure, top-notch support and presence in multiple African countries was what we needed to support our expansion plans. They have helped us stay connected to our customers, resolve issues, have real-time conversations with them about matters that are pertinent to them. Most importantly, we built trust at Trade Depot and transition our Telesales team to a remote distributed team.',
        name: 'Feyishayo Akimoboye',
        role: 'Lead, Telecomms Team, Trade Depot',
        image: tradeDepotLogo,
    },
    {
        quote: 'Kasookoo’s has everything it take to deliver on a messaging campaign and that is why we trusted them for our campaign.',
        name: 'Dufil Foods',
        role: '',
        image: dufil,
    },
    {
        quote:
            'Kasookoo helped us to optimize our operations and team strength in responding and communication with our clients. The insights from the Kasookoo platform were especially relevant to help us make strategic marketing and customer service decisions.',
        name: 'Wella Health',
        role: '',
        image: wellahealth,
    },
    {
      quote:
        "Kasookoo's contact center product has revolutionized our operations, providing seamless integration and an intuitive interface. The outstanding support has exceeded our expectations. Kasookoo has enabled us to deliver exceptional customer experiences with insightful analytics and customizable options. Their team genuinely cares about our success and actively incorporates feedback. We highly recommend Kasookoo to any company in need of a reliable contact center solution and responsive support.",
         name: 'Pravesh Komal',
      role: 'Inland Sales Director',
      image: inLandSales,
    }
];

  var list_accordion_6 = [
    {
      title: "Building a connected Africa",
      text: "<p>Simplifying your expansion into Africa, Kasookoo serves as your trusted communications technology provider, granting you access to our extensive enterprise telco network across the region. Seamlessly expand your operations with ease and efficiency.</a></p>\n",
      image: NET,
      bottom_arrow_link: "",
    },
    {
      title: "Tailored to your requirement",
      text: "<p>Collaborate with our team of technology experts to tailor our contact center platform or voice and messaging APIs, creating a fully customized customer engagement solution that precisely aligns with your unique requirements.</a></p>\n",
      image: curve,
      bottom_arrow_link: "",
    },
    {
      title: "Programmable Communications",
      text: "<p>Unleash innovative communication channels for your customers through our comprehensive suite of programmable APIs, seamlessly integrated with your platform and our robust telco network</a></p>\n<p>&nbsp;</p>\n",
      image: PROG,
      bottom_arrow_link: "",
    },
    {
      title: "On-demand support, no matter where you are",
      text: "<p>No matter the nature of your requirements, our team of dedicated experts based in Africa is available to assist you with your communication needs. Count on us to ensure you can consistently deliver the best possible customer experience.</a></p>\n",
      image: HELP,
      bottom_arrow_link: "",
    },
  ];

  return (
    <div className="why-kasookoo">
      <Navbar
        extra_heading="WHY KASOOKOO"
        heading="Your trusted partner for cloud communications solutions that deliver superior customer experiences in Africa"
        img={africa_map2_img}
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >


        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-12">
                <h6 class="style-h6">
                  TRUSTED BY LEADING ORGANISATIONS IN AFRICA & MIDDLE EAST
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-logos pb-3 hide-slick-dots-on-desktop"
      >


        {/* <div class="w-100 section-logos-slider py-3 py-md-5 bg- ">
          <Slider/>
        </div> */}
         <div class="w-100  py-3 py-md-5 ">
         
         
            <div className="d-flex text-center mt-10">
              <div class="w-100 section-logos-slider py-3 py-md-5  ">
                <img src={logo4} width="200px" style={{marginBottom:"25px"}} />
                <img src={logo3} width="200px" style={{marginBottom:"25px"}}/>
                <img src={tradeDepotLogo} width="200px" style={{marginBottom:"25px"}}/>
                <img src={logo2} width="200px" style={{marginBottom:"25px"}} />
                <img src={logo1} width="200px" style={{marginBottom:"25px"}} />

             
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-top_title pt-5"
      >

        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={squres_white_icon}
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >


        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="style-h2">
                  Scalable cloud communications solutions for enterprises
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >


        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  By selecting Kasookoo as your communications provider in
                  Africa, you gain access to our tailored enterprise technology
                  solutions, coupled with carrier-grade telco connectivity
                  across 17 countries. This ensures seamless, high-quality
                  communications for your business operations.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccordionComponent />

      <div
        id="num-section-7"
        class="w-100 position-relative block-top_title pt-5"
      >

        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img src={STAT} alt="OUR STATISTICS" class="me-2" />
                  OUR STATISTICS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title text-start text-md-center text-white"
      >


        <div class="w-100 section-centered_title text-start text-md-center text-white py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="style-h2">
                  What sets Kasookoo apart from the rest?
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title text-start text-md-center pb-5 text-white"
      >


        <div class="w-100 section-centered_title text-start text-md-center pb-5 text-white py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  Kasookoo aims to revolutionize customer engagement for
                  enterprises in Africa by introducing a comprehensive suite of
                  communication solutions that prioritize the highest quality
                  connectivity. Our goal is to redefine how businesses interact
                  with their customers in the region.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-4_columns_with_counters pb-5"
      >


        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-default pb-5">
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">17</span>
                  <span class="count-text-after"></span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
              African countries with PSTN replacement
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">1000</span>
                  <span class="count-text-after">+</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
              Concurrent agents
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">10</span>
                  <span class="count-text-after">M +</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
              SMS
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">50</span>
                  <span class="count-text-after">M +</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
              voice minutes per year
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-top_title pt-5"
      >

        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={our_products}
                    alt="OUR PRODUCTS"
                    class="me-2"
                  />
                  OUR PRODUCTS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >


        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="style-h1">
                  Powering cloud-based communications from a unified source
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >


        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row  ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  Access enterprise-grade software and Africa connectivity
                  through a single provider, unlocking a suite of solutions
                  meticulously crafted to deliver seamless customer engagement
                  experiences.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-3_items_in_row_with_icon_title_text_link "
      >

        <div class="container section-3_items_in_row_with_icon_title_text_link">
          <div class="row rseh-md ">
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/contact-center"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={headphone} alt="Contact Centre" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">Contact Centre </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    A custom, cloud-based contact center solution that is
                    tailored to the needs of companies operating in Africa.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/programmable-voice"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={voice} alt="Programmable Voice" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Programmable Voice
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Deliver a personalized, seamless, and engaging voice
                    experience across all channels with programmable calling.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/programmable-messaging"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={email} alt="Programmable Messaging" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Programmable Messaging
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Send your messages from Africa to anywhere in the world with
                    confidence.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-3_items_in_row_with_icon_title_text_link "
      >

        <div class="container section-3_items_in_row_with_icon_title_text_link">
          <div class="row rseh-md ">
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "341.5px" }}
              >
                <a
                  href="/#/in-app-voice-calling"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={speaker} alt="In-App Voice Calling" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    In-App Voice Calling
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Use a single voice SDK to add inbound and outbound calls to
                    your app, so you can keep customer experiences in one place.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "341.5px" }}
              >
                <a
                  href="/#/user-verification"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={tick} alt="User Verification" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    User Verification
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Enhance your customer validation process, boost conversion
                    rates, and effectively combat fraud using our comprehensive
                    set of APIs.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "341.5px" }}
              >
                <a
                  href="/#/campaign-manager"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={send} alt="Campaign Manager" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Campaign Manager
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Send, track, and manage all of your sales, marketing, or
                    operational SMS campaigns at scale with a single platform.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-16"
        class="w-100 position-relative block-3_items_in_row_with_icon_title_text_link "
      >

        <div class="container section-3_items_in_row_with_icon_title_text_link">
          <div class="row rseh-md ">
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/number-masking"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={sur} alt="Number Masking" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">Number Masking </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Safeguard your users by implementing a number masking API
                    that anonymizes or adapts caller IDs, providing an
                    additional layer of protection.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/kasookoo-for-crm"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={call} alt="KAsookoo for CRM" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Kasookoo for CRM
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Seamlessly integrate your telephony system with the MS Teams
                    console to enable efficient call management.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/zoho-phonebridge-integration"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={call} alt="KAsookoo for Integration" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Kasookoo for Integration
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Seamlessly integrate your telephony system with the Zoho
                    to enable efficient call management.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/#/kasookoo-for-zoom"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={con} alt="kasookoo for Zoom Phone" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Kasookoo for Zoom Phone
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Get high-quality voice calls and video conferencing in
                    Africa with Zoom Phone.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-17"
        class="w-100 position-relative block-top_title pt-5"
      >

        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={our_customers}
                    alt="OUR CUSTOMERS"
                    class="me-2"
                  />
                  OUR CUSTOMERS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-18"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >


        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Hear from our customers</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-19"
        class="w-100 position-relative block-centered_title text-start text-md-center pt-0"
      >


        <div class="w-100 section-centered_title text-start text-md-center pt-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  We collaborate with businesses worldwide, delivering optimal
                  solutions for customer engagement in Africa.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-20"
        class="w-100 position-relative block-recommendations_slider py-5"
      >

       <Feedback feedback={feedback}/>
      </div>
      {/* <div
        id="num-section-21"
        class="w-100 position-relative block-top_title pt-5"
      >

        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={our_awards}
                    alt="OUR AWARDS"
                    class="me-2"
                  />
                  OUR AWARDS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        id="num-section-22"
        class="w-100 position-relative block-centered_title pb-4"
      >


        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  An award-winning cloud communications provider in Africa,
                  recognized for our innovative solutions and outstanding
                  customer service.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-23"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-4"
      >


        <div class="container section-2_items_in_row_with_image_title_text_link style-default">
          <div class="row rseh-md pb-4">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px",height:"fit-content" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2022/12/sbr-tea-03-1.png"
                        alt="Business Technology Excellence Awards"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Business Technology Excellence Awards
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        API - Telecommunications SBR Technology Excellence
                        Awards 2022
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px",height:"fit-content" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2022/12/2022-Award-Logo-Toku-1.png"
                        alt="2022 Southeast Asia Cloud Contact Center Services"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        2022 Southeast Asia Cloud Contact Center Services
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Entrepreneurial Company of the Year Award
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-24"
        class="w-100 position-relative block-cta_strip_no_image text-white "
      >


        <div class="w-100 section-cta_strip_no_image">
          <div class="container">
            <div class="row text-white py-4">
              <div class="col-12 text-center p-md-5">
                <div class="d-block w-100">
                  <h2 class="style-h2 mb-4">
                    Looking to reimagine your customer experience?
                  </h2>
                </div>
                <div class="d-block w-100">
                  <a
                    
                    class="mx-auto btn btn-yellow a-btn"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export function AccordionComponent() {
  const list_accordion_6 = [
    {
      title: "Building a connected Africa",
      text: "<p>Simplifying your expansion into Africa, Kasookoo serves as your trusted communications technology provider, granting you access to our extensive enterprise telco network across the region. Seamlessly expand your operations with ease and efficiency.</a></p>\n",
      image: NET ,
      bottom_arrow_link: "",
    },
    {
      title: "Tailored to your requirement",
      text: "<p>Collaborate with our team of technology experts to tailor our contact center platform or voice and messaging APIs, creating a fully customized customer engagement solution that precisely aligns with your unique requirements</a></p>\n",
      image: curve ,
      bottom_arrow_link: "",
    },
    {
      title: "Programmable Communications",
      text: "<p>Unleash innovative communication channels for your customers through our comprehensive suite of programmable APIs, seamlessly integrated with your platform and our robust telco network.</a></p>\n<p>&nbsp;</p>\n",
      image: PROG ,
      bottom_arrow_link: "",
    },
    {
      title: "On-demand support, no matter where you are",
      text: "<p>No matter the nature of your requirements, our team of dedicated experts based in Africa is available to assist you with your communication needs. Count on us to ensure you can consistently deliver the best possible customer experience.</a></p>\n",
      image: HELP ,
      bottom_arrow_link: "",
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  const showItem = (index) => {
    setActiveItem(index);
  };
  return (
    <div
      id="num-section-6"
      className="w-100 position-relative block-accordion_list py-5"
    >
      <style>{`
        /* CSS styles here */
      `}</style>
      <div className="w-100 section-accordion_list ng-scope">
        <div className="container">
          <div className="row d-md-none">
            <div className="accordion accordion-flush" id="accordionFlush-6">
              {list_accordion_6.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2
                    className="accordion-header"
                    id={`flush-heading${index + 1}`}
                  >
                    <button
                      className={`accordion-button ${
                        index === activeItem ? "style-h4" : "collapsed style-h4"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index + 1}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`flush-collapse${index + 1}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index + 1}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`flush-heading${index + 1}`}
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div className="accordion-body">
                      <div className="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          className="aligncenter size-full size-full mb-3 mb-md-0"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <div
                        className="d-block w-100 p-small"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-6">
              <h3 className="style-h3 mb-5">Our approach to delivery</h3>
              {list_accordion_6.map((item, index) => (
                <button
                  className={`list-accordion style-h4 w-100 ng-binding ng-scope ${
                    index === activeItem ? "active" : "text-dark"
                  }`}
                  key={index}
                  onClick={() => showItem(index)}
                  style={{ boxShadow: "none" }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="col-6">
              <div className="d-block w-100 mb-2">
                <img
                  decoding="async"
                  loading="lazy"
                  className="size-full"
                  src={list_accordion_6[activeItem].image}
                  alt={list_accordion_6[activeItem].title}
                />
              </div>
              <div className="d-block w-100">
                <div className="style-h4 mb-2">
                  {list_accordion_6[activeItem].title}
                </div>
              </div>
              <div
                className="d-block w-100 ng-binding"
                dangerouslySetInnerHTML={{
                  __html: list_accordion_6[activeItem].text,
                }}
              />
              {/* <a
                className="btn btn-arrow text-start ng-binding ng-hide"
                href={list_accordion_6[activeItem].bottom_arrow_link}
                target=""
              ></a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
