import React from 'react'
import FullStory from "../../components/FullStories/fullStories.js";
import tradespot from "../../assets/images/tradespotStory.png"
export default function TradespotFullStory(props) {
  return (
    <div>
<FullStory

title={"Trade Depot Customer Engagement with Kasookoo"}
Img={tradespot}
setIsFormOpen={props.setIsFormOpen}
isFormOpen ={props.isFormOpen}
storyp1={"Trade Depot, a tech-led direct-to-retail firm in Nigeria, required a contact centre solution to enhance customer engagement, streamline order fulfilment, and facilitate delivery information for its vast network of over 40,000 retail businesses. Additionally, Trade Depot aimed to generate repeat sales on a large scale by effectively collaborating with its remote sales teams."}
storyp2={"By implementing Kasookoo’s robust contact centre solution, Trade Depot established a centralized communication platform across seven African nations to efficiently handle customer inquiries, resolve issues, and provide prompt updates on order status and delivery details. The solution enabled Trade Depot's 200 staff members, including remote sales teams, to access relevant customer data, streamline their sales processes, and enhance overall productivity"}
storyp3={["Key features of the Kasookoo’s contact centre solution for Trade Depot may include:",<br/>,"1. Omnichannel Support: Our solution enabled seamless customer interactions across various channels, including phone calls, emails, chat, and social media, allowing Trade Depot to cater to customer preferences and provide consistent support.",<br/> ,"2. Order Tracking and Management: The integration with Trade Depot's existing systems enabled real-time order tracking, allowing customers and sales teams to monitor order progress and provide accurate delivery information.",<br/>, "3. Customer Relationship Management (CRM) Integration: Kasookoo integration with Trade Depot's CRM system provided a comprehensive view of customer data, order history, and preferences, enabling personalized interactions and tailored sales strategies.",<br/>," 4. Reporting and Analytics: Kasookoo’s robust reporting capabilities and analytics tools provided Trade Depot with insights into customer trends, agent performance, and overall contact centre efficiency, facilitating data-driven decision-making and continuous improvement."]}
storyp4={"With Kasookoo’s contact centre solution in place, Trade Depot could effectively engage its customers, ensure efficient order fulfilment, provide accurate delivery updates, and collaborate seamlessly with its remote sales teams. This contributed largely to enhanced customer satisfaction, increased sales, and improved operational efficiency for the fast-growing direct-to-retail firm. "}
>
    
</FullStory>
    </div>
  )
}
