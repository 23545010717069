import React from 'react'
import './viewjob.css'
export default function ViewJob() {
  return (
    <div>
       




<section class="w-100 d-block hero-single-careers pt-5 d-flex align-items-center" style={{backgroundColor:'#7DBAAB'}}>
    <div class="container py-5 text-white">
        <div class="row text-center py-2">
            <div class="col-12 col-md-8 col-xl-6 mx-auto">
                <h1 class="style-h3">Senior Marketing Executive</h1>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-auto">
                <p>
                    <img class="me-1" src="https://toku.co/wp-content/themes/toku/assets/images/icons/department-white.svg" alt="Marketing"/>
	                Marketing                </p>
            </div>
            <div class="col-auto">
                <p>
                    <img class="me-1" src="https://toku.co/wp-content/themes/toku/assets/images/icons/map-white.svg" alt="CBD, Singapore"/>
	                CBD, Singapore                </p>
            </div>
        </div>
    </div>
</section>
    <section class="w-100 d-block">
        <div class="container pt-3 pb-5">
            <div class="row">
                <div class="col-12">
                    <p id="breadcrumbs"><span><span><a href="https://toku.co/">Home</a></span> » <span class="breadcrumb_last" aria-current="page">Senior Marketing Executive</span></span></p>                </div>
            </div>
        </div>
    </section>
	<div class="w-100 d-block">
    <div class="container mb-5">
        <div class="row mb-5">
            <div class="col-12 col-lg-8 pt-md-4">
                <div class="w-100 title">
                    <div class="style-h4 mb-3">Senior Marketing Executive</div>
                </div>
                <div class="w-100 text-job-description">
                    <p class="p-big text-purple">Job description</p>
                </div>
                <div class="w-100 content">
                    <p>At Toku, we create bespoke cloud communications and customer engagement solutions to reimagine customer experiences for enterprises. We provide an end-to-end approach to help businesses overcome the complexity of digital transformation in APAC markets and enhance their CX with mission-critical cloud communication solutions. Toku combines local strategic consulting expertise, bespoke technology, regional in-country infrastructure, connectivity and global reach to serve the diverse needs of enterprises operating regionally. To augment our enterprise digital transformation offerings, we recently incorporated a new business consulting unit with a dedicated team of expert consultants into our operations.</p>
<p></p>
<p></p>
<div>
<p>Toku is currently getting into a scale-up mode. We want to continue creating momentum for our products in the APAC region and helping customers with their communications needs. As we build the marketing foundation, we are looking for a Senior Marketing Executive who can help bring our campaigns and events to life.&nbsp;Want to be part of our journey?</p>
</div>
<p></p>
<p></p>
<p><strong>What would you be doing?</strong></p>
<p>You will report to our VP of Marketing. You will be working in conjunction with the rest of the team to execute and maximise the impact of our campaigns and events. Your responsibilities will include:</p>
<div>
<p></p>
</div>
<div>
<p><strong>Campaign &amp; Event Execution</strong> </p>
</div>
<div>
<div>
<ul>
<li>Planning and launch of virtual and physical events that include webinars, roundtables, conferences, tradeshows, etc. </li>
<li>Execution of online and offline marketing campaigns in line with the objectives and positioning set out in the marketing plan </li>
<li>Researching &amp; monitoring target accounts and industries to facilitate the personalization of content as part of our strategic marketing approach </li>
<li>Collaborate with the rest of the marketing team to drive awareness and/or demand generation for events, campaigns, and other initiatives </li>
<li>Liaising with internal stakeholders and external vendors to ensure all initiatives are launched in an organized and timely manner</li>
<li>Analysing performance for each event or campaign to track achievement of success metrics and determine areas for improvement</li>
</ul>
</div>
</div>
<div>
<div>
<p></p>
</div>
<div>
<p><strong>Digital Advertising &amp; Analytics</strong> </p>
</div>
<div>
<ul>
<li>Execution, monitoring, and optimisation of digital ads across all paid platforms to maximise impressions, traffic and online conversions </li>
<li>Regular keyword &amp; competitor research to ensure the competitiveness and relevance of our online ads </li>
</ul>
</div>
</div>
<div>
<div>
<ul>
<li>Monitoring and analysing marketing performance across all tools, such as Google Analytics, HubSpot, LinkedIn, etc. and provision of regular insights to the team to implement measures to optimise our processes and activities. </li>
<li>Keeping up-to-date on the latest developments in digital marketing &amp; analytics and sharing insights with the rest of the team  </li>
</ul>
</div>
<div>
<p></p>
</div>
<div>
<p><strong>Lead &amp; Database Management</strong> </p>
</div>
<div>
<ul>
<li>Liaising with sales &amp; marketing teams regularly to learn about products and sales processes to understand the full buyer journey.</li>
<li>Qualification of inbound leads based on pre-defined criteria to maximise Sales efficiency</li>
<li>Tracking of all leads generated, and collaborating with Sales to ensure that they are followed up on appropriately and that updates are recorded clearly</li>
<li>Ensuring data cleanliness and quality in our CRM tool.</li>
<li>Other ad-hoc duties as assigned</li>
</ul>
</div>
</div>
                </div>
                <div class="w-100 requirements">
                    <p><strong>We would love to hear from you if you have:</strong></p>
<ul>
<li>Minimum 3 years of B2B marketing or event management experience.</li>
<li>Hands-on experience in managing online and offline campaigns</li>
<li>Good project management and organizational skills with keen attention to detail</li>
<li>Good communication and analytical skills</li>
<li>Be able to work well independently and meet timeframes</li>
<li>Self-starter who enjoys learning and working in a collaborative environment</li>
<li>Experience in B2B tech or telco industry would be a plus</li>
</ul>
<div>
<div>
<p><strong>What would you get?</strong></p>
</div>
<div>
<ul>
<li>Flexible working locations</li>
<li>Training and Development</li>
<li>Discretionary Yearly Bonus &amp; Salary Review</li>
<li>Healthcare Coverage based on location</li>
<li>20 days Paid Annual Leave (excluding Bank holidays)</li>
</ul>
</div>
</div>
<div>
<p>If you would love to experience working in a start-up growing at an accelerated speed, and you think you tick most of the requirements, join us!</p>
</div>
                </div>
                <div class="w-100 apply text-center">
                    <a href="https://careers.toku.co/o/senior-marketing-executive/c/new" target="_blank" class="btn btn-big btn-yellow border-radius-50 mx-auto">Apply for this job</a>
                </div>
            </div>
        </div>
    </div>
</div>
			
    </div>
  )
}
