import "./consulting.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import consulting_img from "../../assets/images/consulting-img.png";

import businessExpertise from "../../assets/images/business_experties_icon.svg"
import tecxhExpertise from "../../assets/images/technalogy_experties_icon.svg"
import customSatisfaction from "../../assets/images/Group 1178.svg"

import image2 from "../../assets/images/consulting-2.png"
import image3 from "../../assets/images/consulting-3.png"

import bottomImageLeft from "../../assets/images/7-1.png"
import bottomImageRight from "../../assets/images/7-2.png"

import graphImg from "../../assets/images/consulting-graph-img.png"

export default function Consulting(props) {
  return (
    <div className="consulting">
      <Navbar
        heading="Transforming Businesses with Integrated Solutions "
        text="At Kasookoo Consulting, we know that digital transformation is not a one-size-fits-all solution. That's why we take an end-to-end approach, working with you to understand your needs, develop a plan, and implement the right solution."
        img={consulting_img}
        extra_heading="KASOOKOO CONSULTING"
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>

      <div

        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Accelerate your business transformation with a team of
                  experienced consultants
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={businessExpertise}
                      alt="Group 1000005055"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Business Expertise in Africa </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Harness our in-depth understanding of regional business
                      processes and regulatory landscape to expedite your
                      digital transformation journey and create exceptional
                      customer experiences.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={tecxhExpertise}
                      alt="Group 1000005250"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Technical <br /> Knowledge
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Discover the transformative impact of our team of tech
                      specialists, equipped with comprehensive hybrid cloud
                      expertise and a deep understanding of the dynamic customer
                      engagement landscape.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={customSatisfaction}
                      alt="Group 1000005251"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Customer <br />
                      Satisfaction  
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Our consultants are part of your team, working alongside
                      you every step of the way to ensure a smooth and
                      successful implementation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5 pb-2"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 pb-2 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Maximize your transformation's potential by uncovering hidden
                  value
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="num-section-4" class="w-100 position-relative block-block_text ">
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-6442 size-large"
                  src={graphImg}
                   alt=""
                  
                  
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      {/* <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div> */}
      <div
        id="num-section-5"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={image2}
                  alt="Frame 46359"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">
                    Are you using data and insights to improve your customer
                    experience?
                  </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Through our meticulous methodologies, we evaluate,
                      enhance, and monitor your service blueprint, providing
                      comprehensive recommendations for growth, scalability, and
                      profitability. We tailor training workshops to actively
                      engage and inspire your team, empowering them to deliver
                      exceptional customer experiences (CX).
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    
                    class="a-btn btn btn-arrow text-start"
                   onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Partner with us today to take your CX to the next level!
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={image3}
                  alt="Frame 46361"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">
                    Would you like a digital workplace that's simple and
                    efficient?
                  </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Embark on a complete workplace transformation journey with
                      us. We will streamline workflows, simplify complexities,
                      and revolutionize communication and collaboration within
                      your team. Build a thriving digital workplace that fosters
                      productivity and supports the diverse needs and working
                      styles of every team member.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                     class="a-btn btn btn-arrow text-start"
                     onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Contact us today to learn more!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomImageLeft}
                  alt="Ready to drive transformational change for your business and customers?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomImageRight}
                  alt="Ready to drive transformational change for your business and customers?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Are you ready to make a positive impact on your business and
                    customers?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    
                    class="a-btn blact_btn mb-5 mb-md-0 mx-auto"
                    onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
