import React from "react";
import "./governmentsolution.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import shieldIcon from "../../assets/images/shield.svg";
import headphonesicon from "../../assets/images/headphones1.svg";
import bespokeicone from "../../assets/images/booster-icon.svg";
import PDPAicon from "../../assets/images/Group 1174.svg";

import bottomRightImg from "../../assets/images/7-1.png";
import governemnt_solution_header_img from "../../assets/images/gov_heading.png";

import reach_larage_img from "../../assets/images/build_stronger.png";
import improved_accessibility_img from "../../assets/images/improved_accessibility_img.png";
import improve_the_citizen_img from "../../assets/images/improved_acc.png";
import discover_why_government_img from "../../assets/images/discover_why_government_img.png";
import accelerate_your_img from "../../assets/images/accelerate_your_img.png";
import empower_residents_img from "../../assets/images/Empower residents.png";
import enhance_the_user_img from "../../assets/images/Enhance the user.png";
import partner_with_us_img from "../../assets/images/partner_with_us_img.png";
import communicate_img from "../../assets/images/communicate_img.png";

export default function GovernmentSolution(props) {
  return (
    <div className="government-solution">
      <Navbar
        heading="Create a seamless digital experience for citizens"
        extra_heading="TRANSFORMING GOVERNMENTS"
        text="Kasookoo empowers ministries and agencies to achieve their Smart Nation objectives by offering tailored cloud communication solutions."
        img={governemnt_solution_header_img}
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      {/* <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title w-100 text-center"
      >
        <style></style>

        <div class="w-100 section-centered_title w-100 text-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h6 class="style-h6">PARTNERS</h6>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        id="num-section-2"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Kasookoo specializes in developing seamless and accessible
                  citizen experiences within African government services.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        id="num-section-3"
        class="w-100 position-relative block-free_blocks_centerd text-center"
      >
        <style></style>
        <div class="w-100 section-free-blocks pt-3 pb-md-3 ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-auto mb-3">
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="wp-image-1978 size-full aligncenter"
                    src="https://toku.co/wp-content/uploads/2022/12/logo-pub.png"
                    alt=""
                    width="304"
                    height="120"
                    srcset="https://toku.co/wp-content/uploads/2022/12/logo-pub.png 304w, https://toku.co/wp-content/uploads/2022/12/logo-pub-300x118.png 300w"
                    sizes="(max-width: 304px) 100vw, 304px"
                  />
                </p>
              </div>
              <div class="col-12 col-md-auto mb-3">
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="wp-image-1979 size-full aligncenter"
                    src="https://toku.co/wp-content/uploads/2022/12/logo-hdb.png"
                    alt=""
                    width="304"
                    height="120"
                    srcset="https://toku.co/wp-content/uploads/2022/12/logo-hdb.png 304w, https://toku.co/wp-content/uploads/2022/12/logo-hdb-300x118.png 300w"
                    sizes="(max-width: 304px) 100vw, 304px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div> */}
      {/* <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div> */}
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title Go from legacy to cloud communications faster"
      >
        <style></style>

        <div class="w-100 section-centered_title Go from legacy to cloud communications faster py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Accelerate your transition from legacy to cloud communications
                  with unprecedented speed and efficiency
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Kasookoo possesses unparalleled hybrid expertise in
                  facilitating seamless migration from legacy telecommunications
                  infrastructure to cloud telephony.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="num-section-6" class="w-100 position-relative block-block_text ">
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="wp-image-1981 size-full aligncenter"
                  src={accelerate_your_img}
                  alt=""
                  width="579"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title pb-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-0">
              <div class="col-12 mx-auto col-md-8">
                <h3 class="style-h3">Enabling people to work remotely </h3>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title pb-3"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-3 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Integrate a cloud telephony system with your preferred
                  communications platform, <br /> enabling government employees
                  to collaborate seamlessly from any location
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        id="num-section-9"
        class="w-100 position-relative block-cards pt-3 pb-md-5"
      >
        <style></style>
        <div class="w-100 section-cards pt-3 pb-md-3">
          <div class="container">
            <div class="row row-cols-1 row-cols-md-3 g-4 cards">
              <style></style>
              <div class="col mb-3 " id="card-id-37-1">
                <div class="card h-100">
                  <div class="card-body d-block w-100 text-start p-0">
                    <div class="d-block d-md-none w-100 mb-3">
                      <img
                        src="https://toku.co/wp-content/uploads/2022/11/logo-microsoft-team.png"
                        alt="Boost Productivity with Microsoft Teams"
                        class="card-img"
                      />
                    </div>
                    <div class="card-title d-block w-100 mb-3">
                      <div class="title-in-row">
                        Increase operational effectiveness with MS Teams
                      </div>
                    </div>
                    <div class="card-text d-block w-100 mb-3">
                      <p>
                        Unlock the power of global calling from any location and
                        on any device, seamlessly integrated with MS Teams.
                      </p>
                    </div>
                    <div class="d-block w-100">
                      <a
                        href="/kasookoo-for-microsoft"
                        class="btn btn-arrow text-start"
                        target=""
                      >
                        Explore Kasookoo for Microsoft Teams
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <style></style>
              <div class="col mb-3 " id="card-id-37-2">
                <div class="card h-100">
                  <div class="card-body d-block w-100 text-start p-0">
                    <div class="d-block d-md-none w-100 mb-3">
                      <img
                        src="https://toku.co/wp-content/uploads/2022/11/logo-zoom.png"
                        alt="Next Level Communications with Zoom Calls  "
                        class="card-img"
                      />
                    </div>
                    <div class="card-title d-block w-100 mb-3">
                      <div class="title-in-row">
                        Experience hi-quality communications with Zoom calls
                      </div>
                    </div>
                    <div class="card-text d-block w-100 mb-3">
                      <p>
                        Enhance the call quality of your Zoom experience with
                        enterprise-grade connectivity, ensuring
                        hi-endcommunication for your business needs.
                      </p>
                    </div>
                    <div class="d-block w-100">
                      <a
                        href="/kasookoo-for-zoom"
                        class="btn btn-arrow text-start"
                        target=""
                      >
                        Explore Kasookoo for Zoom Phone
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-10"
        class="w-100 position-relative block-big_card_with_items "
      >
        <style></style>
        <div class="w-100 section-big_card_with_items py-3">
          <div class="box">
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-5 mx-auto text-center">
                  <h2 class="style-h2 mb-5">
                    Design a smart nation experience that puts people first
                  </h2>
                </div>
              </div>
              <div class="row row-cols-1 row-cols-md-3 g-4 cards">
                <div class="col">
                  <div class="card h-100">
                    <div class="card-body d-block w-100 text-start p-0 h-100">
                      <div class="d-block w-100 mb-3">
                        <img
                          src={improved_accessibility_img}
                          alt="Improved accessibility to government agencies "
                          class="w-100 "
                          
                        />
                      </div>
                      <div class="card-title d-block w-100 mt-2 mb-3 px-3" >
                        <h4 class="style-h4" style={{marginTop:"40px"}}>
                        <br/>

                          Improved accessibility <br /> to government agencies
                        </h4>
                      </div>
                      <div class="card-text d-block w-100 mb-3 px-3">
                        <div class="p-small">
                          Streamline communications through cloud technology,
                          simplifying the process for residents to locate and
                          connect with the appropriate organization or
                          department for all their needs.
                        </div>
                      </div>
                    </div>
                    <div class="d-block w-100 mt-2 px-3 pb-2">
                      <a
                        href="/#/contact-center"
                        class="btn btn-arrow text-start"
                        target=""
                      >
                        Discover Kasookoo Contact Centre
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100">
                    <div class="card-body d-block w-100 text-start p-0 h-100">
                      <div class="d-block w-100 mb-3">
                        <img
                          src={empower_residents_img}
                          alt="Empower residents to take control of their own
                          lives "
                          class="w-100"
                          // style={{height:"310px"}}
                        />
                      </div>
                      <div class="card-title d-block w-100 mt-2 mb-3 px-3" >
                        <h4 class="style-h4">
                        <br/>
                          Empower residents to take <br /> control of their own
                          lives
                        </h4>
                      </div>
                      <div class="card-text d-block w-100 mb-3 px-3">
                        <div class="p-small">
                          
                          Optimize your call center operations and provide
                          residents with instant answers to frequently asked
                          questions by leveraging Interactive Voice Response
                          (IVR) technology.
                        </div>
                      </div>
                    </div>
                    <div class="d-block w-100 mt-2 px-3 pb-2">
                      <a href="/" class="btn btn-arrow text-start" target="">
                        Learn More About IVR
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100">
                    <div class="card-body d-block w-100 text-start p-0 h-100">
                      <div class="d-block w-100 mb-3">
                        <img
                          src={enhance_the_user_img}
                          // style={{height:"310px"}}
                          alt="Improve citizen<br />experiences  "
                          class="w-100"
                        />
                      </div>
                      <div class="card-title d-block w-100 mt-2 mb-3 px-3">
                        <h4 class="style-h4">
                        <br/>
                          Enhance the user <br /> experience for citizens
                        </h4>
                      </div>
                      <div class="card-text d-block w-100 mb-3 px-3">
                        <div class="p-small">
                          Eliminate agency silos and establish smarter
                          inter-agency workflows that seamlessly connect
                          different government entities. Adopting a streamlined
                          Whole of Government (WOG) approach simplifies the
                          process of meeting people's needs from start to
                          finish, ensuring comprehensive and efficient service
                          delivery.
                        </div>
                      </div>
                    </div>
                    <div class="d-block w-100 mt-2 px-3 pb-2">
                      <a href="/" class="btn btn-arrow text-start" target="">
                        Digitally Transform Government Services
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Build stronger relationships with citizens
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={reach_larage_img}
                  alt="Connect with people at scale with SMS"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h4 class="style-h4">
                    Reach a large audience with SMS messaging
                  </h4>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Ensure timely and reliable dissemination of news, <br />
                      updates, and reminders to a wide audience through the most
                      <br />
                      accessible communication channel. When responsive <br />
                      government services are of utmost importance, SMS emerges
                      <br />
                      as a trusted and effective medium to deliver critical
                      <br />
                      information efficiently.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="/#/programmable-messaging"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Discover Programmable Messaging
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={communicate_img}
                  alt="Engage with residents on their preferred channel"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h4 class="style-h4">
                    Communicate with residents through the channels they use
                    most often
                  </h4>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Engage with your citizens through popular OTT
                      (Over-The-Top) channels such as WhatsApp, Telegram, and
                      more, facilitating seamless and convenient interactions
                      for enhanced citizen engagement.
                    </p>
                    <p>
                      Elevate government services to new heights by
                      incorporating a personalized touch through omnichannel
                      communications.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="/#/contact-center"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Discover Omnichannel Solutions
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={improve_the_citizen_img}
                  alt="group 1265"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h4 class="style-h4">
                    Improve the citizen experience in your apps
                  </h4>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Do you have an app? Fantastic! Take it a step further and
                      enhance citizen accessibility by integrating voice and
                      chat functionalities directly into your apps and digital
                      platforms.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="/#/in-app-voice-calling"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Discover In-App Voice Calling
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-centered_title pt-3 pb-2"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-3 pb-2 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Discover why government agencies choose Kasookoo for their
                  digital transformation needs
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-image_on_the_left_and_4_subjects_on_the_right pb-3 pb-md-5"
      >
        <style></style>
        <div class="w-100 section-image_on_the_left_and_4_subjects_on_the_right py-3">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-4">
                <img
                  src={discover_why_government_img}
                  alt="Group-1000004450-e1666968298518"
                  class="w-100 h-auto mb-4"
                />
              </div>
              <div class="col-12 col-md-8 ps-md-5">
                <div class="row">
                  {/* <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={shieldIcon}
                          alt="Safe. Secure. Reliable"
                          title="Safe. Secure. Reliable"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Security and Reliability </h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          As a Tier 1 Aggregator recognized by IMDA (Infocomm
                          Media Development Authority), we adhere to all IMDA
                          requirements, ensuring compliance and implementing
                          robust anti-spoofing protocols for protected SMS.
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={bespokeicone}
                          alt="Customised Service"
                          title="Customised Service"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Bespoke Offerings</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          Explore our diverse selection of cutting-edge cloud
                          communication solutions, meticulously designed to
                          cater to the unique requirements of your project.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={headphonesicon}
                          alt="Local 24/7  Expert Support"
                          title="Local 24/7  Expert Support"
                          style={{ width: "60px" }}
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Quality Customer Support</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          Benefit from the expertise and responsiveness of our
                          skilled technical teams, based in Middle-east and
                          Africa, who are readily available to support and
                          swiftly resolve any issues you may encounter.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={PDPAicon}
                          alt="Compliant with PDPA and DSS"
                          title="Compliant with PDPA and DSS"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">PDPA and DSS Compliant</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          We understand the critical importance of compliance
                          with regulatory standards such as ISO 27001, PDPA
                          (Personal Data Protection Act), and DSS (Digital
                          Service Standards). As a result, we place utmost
                          importance on meeting and exceeding these
                          requirements.
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div        id="num-section-15"        class="w-100 position-relative block-cta_strip_2 "    >
        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={partner_with_us_img}
                  alt="Accelerate your Smart Nation masterplan with us"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Partner with us to make your Smart Nation vision a reality
                  </h2>
                </div>
                <div class="d-block w-100">
                  <div class="p-big">
                    <p>
                      Whether you're in the process of migrating legacy
                      communication systems to the cloud or aiming to enhance
                      citizen experiences by fostering greater connectivity
                      across multiple agencies, Kasookoo offers tailored cloud
                      communication solutions to help you achieve your Smart
                      Nation goals.
                    </p>
                  </div>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    class="blact_btn"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                  >
                    Contact Us Today!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
