import React from "react";
import "./kasookooforzoom.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import zoom_img from "../../assets/images/kasookoo-for-zoom-1.png";
import zoomimg2 from "../../assets/images/kasookoo-for-zoom-2.png"
import bottomImhleft from "../../assets/images/7-2.png"
import bottomImgRight from "../../assets/images/7-1.png"

import excellentcallqicon from "../../assets/images/excellent-call-q-icon.svg"
import enhanceproductivityicon from "../../assets/images/enhance-product-icon.svg"
import support247 from "../../assets/images/icon-24-7.svg"
import locationicon from "../../assets/images/zoom-location-icon.svg"


import solution_icon from "../../assets/images/solution-icon.svg";

import customer_support from "../../assets/images/customer-support.jpeg";

export default function KasookooForZoom(props) {
  return (
    <div className="zoom">
      <Navbar
        heading="Effortless Africa Calling with Kasookoo & Zoom Phone"
        text="Leverage your favorite features and functionality with zoom phone to strengthen your presence in the African market. Integrate our carrier-grade network with your existing setup for unmatched performance and reliability."
        img={zoom_img}
        extra_heading="KASOOKOO FOR ZOOM PHONE"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Elevate Your Zoom Phone Connectivity to the Next Level
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Unlock Unparalleled Call Quality, Cost Efficiency, and
                  Enhanced Productivity in 17 AFRICAN Countries through a Single
                  Bring-Your-Own-Carrier (BYOC) Connection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary "
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "355px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={excellentcallqicon}
                      alt="Group 7330"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Excellent <br /> Call Quality
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Gain Access to Carrier-Grade Connections through
                      Kasookoo's Enterprise-Grade African Network, Offering PSTN
                      Replacement Capabilities in 17 Countries.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "355px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={enhanceproductivityicon}
                      alt="Group 7329"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Enhanced Productivity </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Gain access to highly competitive calling rates and
                      consolidate all your African telephony providers with a
                      local provider.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "355px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={support247}
                      alt="Group 7331"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Full Support</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Experience unwavering confidence with an African-based
                      network operations centre (noc) for complete peace of
                      mind.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-list_locations "
      >
        <style></style>

        <div class="w-100 section-list_locations ">
          <div class="container pt-0 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-6 ">
                <div class="w-100 w-md-80 d-block mb-3">
                  <h2 class="style-h2">
                    Discover the key elements that foster connectivity for
                    enterprises in Africa
                  </h2>
                </div>
                <div class="w-100 w-md-80 d-block mb-5">
                  <span class="p-small mb-3">
                    <p>
                      By Choosing Kasookoo as Your Communications Technology
                      Provider, Unlock Access to Our AFRICAN Enterprise Telco
                      Network, Ensuring Superior Call Quality throughout the
                      Region.
                    </p>
                  </span>
                </div>
                {/* <div class="w-100 w-md-80 d-block mb-4">
                  <span class="style-h4">Our 15 connectivity locations</span>
                </div> */}
                {/* <div class="w-100 d-block mb-5 mb-md-0">
                  <div class="location-item">
                    <img src={locationicon} />
                    Australia
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Cambodia
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    China
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Hong Kong
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Indonesia
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Japan
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Laos
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Malaysia
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Myanmar
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    New Zealand
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Philippines
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Singapore
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Taiwan
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Thailand
                  </div>
                  <div class="location-item">
                    <img src={locationicon} />
                    Vietnam
                  </div>
                </div> */}
              </div>

              <div class="col-12 col-md-6">
                <img
                  src={zoomimg2}
                  alt="See what keeps enterprises in APAC connected"
                  class="img-side d-block my-5 mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomImhleft}
                  alt="Want to upgrade your Zoom Phone connectivity?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomImgRight}
                  alt="Want to upgrade your Zoom Phone connectivity?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Looking to enhance your zoom phone connectivity?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                   
                    class="a-btn blact_btn"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Get connected in Africa
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
