import React from 'react'
import './privacypolicy.css'
export default function PrivacyPolicy() {
    return (
        <div className='privacy'>

            <section class="w-100 d-block hero-single-careers pt-5 d-flex align-items-center mb-5" style={{ backgroundColor: '#7DBAAB' }}>
                <div class="container py-5 text-white">
                    <div class="row text-center py-2">
                        <div class="col-12 col-md-8 col-xl-6 mx-auto">
                            <h1 class="style-h3">Privacy Policy</h1>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">

                    </div>
                </div>
            </section>
            <section id="about">
                <div class="container">
                    {/* <h1 class="section-title wow fadeIn animated" data-wow-delay=".2s" style={{visibility: 'visible'}}><span>Privacy</span> Policy</h1> */}
                    <div class="row">
                        <div class="body-container">
                            <p>Yuave Limited ("<strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>"), trading as kasookoo, is committed to protecting and respecting your privacy. We own and operate&nbsp;<a href="http://www.kasookoo.com/" style={{color:'black'}}>www.kasookoo.com</a>&nbsp;(the “<strong>Website</strong>”) and the “kasookoo” downloadable mobile application (the “<strong>App</strong>”). The Website and the App, together with any other websites, administrative tools and applications that we make available from time to time, are the “<strong>Access Points</strong>”. The computer or device onto which you access or download the Access Points is a “<strong>Device</strong>”.</p>
                            <p>In this Privacy and Cookies Policy, references to “<strong>you</strong>” are to any business or person who subscribes to and/or uses the Services, and submits data to us or an Access Point about him/herself or about any living individual in relation to use of the Access Points or the telecommunications services that are available through the Access Points or that we otherwise make available (the “<strong>Services</strong>”).</p>
                            <p>When you access the Services, we may ask you to provide us with information about you and, when using the Services, we collect and process personal information about the user of the Services. This Privacy and Cookies Policy sets out how we might use that information.</p>
                            <p>This Privacy and Cookies Policy (together with our Terms and Conditions, and any other documents referred to in them) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read this Privacy and Cookies Policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
                            <ol>
                                <li><strong> Important information and who we are</strong></li>
                            </ol>
                            <p><strong>Purpose of this Privacy and Cookies Policy</strong></p>
                            <p>This Privacy and Cookies Policy aims to give you information on how we collect and process personal data through your use of the Services, including any data you may provide through the Access Points when you purchase our products and services, or otherwise through your use of the Services.</p>
                            <p>The Services are not intended for use by children, although you acknowledge that we will collect and process data relating to children (and anyone else) if they use the Services.</p>
                            <p>It is important that you read this Privacy and Cookies Policy together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This Privacy and Cookies Policy supplements the other notices and is not intended to override them.</p>
                            <p><strong>Controller</strong></p>
                            <p>Yuave Limited is the controller and responsible for certain personal data shared through use of the Services.</p>
                            <p>We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this Privacy and Cookies Policy. If you have any questions about this Privacy and Cookies Policy, including any requests to exercise your legal rights under paragraph 12 below, please contact the DPO using the details set out below:</p>
                            <p>Full name of legal entity: Yuave Limited</p>
                            <p>Email address: support@yuave.com </p>
                            <p>Postal address: 4th Floor, Silverstream House, 45 Fitzroy Street, Fitzrovia, London, W1T 6EB.</p>
                            <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (<a href="http://www.ico.org.uk/" style={{color:'black'}}>www.ico.org.uk</a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.</p>
                            <p><strong>Processor</strong></p>
                            <p>We are also a processor in respect of certain personal data that you share with us through use of the Services. The business that has subscribed to the Services will be the controller of such personal data. Such personal data relates to use of the Services, and includes information about how you use the Access Points and the Services, such as call logs, call duration, traffic data, weblogs and other communication data (“<strong>Usage Data</strong>”).</p>
                            <p>Our and your respective obligations in relation to that personal data are set out in our <a href="/terms-and-conditions" style={{color:'black'}}>Terms and Conditions</a>.</p>
                            <p><strong>Changes to our Privacy and Cookies Policy and your duty to inform us of changes</strong></p>
                            <p>This version was last updated on 24/05/2018.</p>
                            <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
                            <p>Any changes we may make to this Privacy and Cookies Policy in the future will be posted on the Access Points and, where appropriate, notified to you by email. Each time you enter an Access Point, you agree that the Privacy and Cookies Policy current at that time shall apply to all information held by you.</p>
                            <p><strong>Third-party links</strong></p>
                            <p>This Access Points may include links to third-party websites, plug-ins and applications (such as Stripe, who we use to process your payments for the Services). Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave the Access Points, we encourage you to read the privacy notice of every website you visit.</p>
                            <ol start="2">
                                <li><strong> The data we collect about you</strong></li>
                            </ol>
                            <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
                            <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>
                            <ul>
                                <li><strong>Identity Data</strong>&nbsp;includes title, first name, last name, username or similar identifier, business/company name, job title, and profession, for the business subscribing to the Services, and the administrator and authorised users of the Services.</li>
                                <li><strong>Contact Data</strong>&nbsp;includes billing address and delivery address in respect of the business subscribing to the Services, and email address and telephone numbers for the business subscribing to the Services, and the administrator and authorised users of the Services.</li>
                                <li><strong>Financial Data</strong>&nbsp;includes bank account and payment card details in respect of the business subscribing to the Services.</li>
                                <li><strong>Transaction Data</strong>&nbsp;includes details about payments to and from you and other details of products and services you have purchased from us in respect of the business subscribing to the Services.</li>
                                <li><strong>Technical Data</strong>&nbsp;includes, in respect of any user of the Services, internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access the Website and the type of device you use, a unique device identifier (for example, your Device's IMEI number, the MAC address of the Device's wireless network interface, or the mobile phone number used by the Device), mobile network information, your mobile operating system and the type of mobile browser you use when you access the App.</li>
                                <li><strong>Profile Data&nbsp;</strong>includes your username and password, purchases or orders made by you, and your interests, preferences and feedback, for the business subscribing to the Services, and the administrator and authorised users of the Services.</li>
                                <li><strong>Marketing and Communications Data&nbsp;</strong>includes your preferences in receiving marketing from us and our third parties and your communication preferences, for the business subscribing to the Services, and the administrator and authorised users of the Services.</li>
                            </ul>
                            <p>We also collect, use and share&nbsp;<strong>Aggregated Data</strong>&nbsp;such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does&nbsp;<strong>not</strong>&nbsp;directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy and Cookies Policy.</p>
                            <p>We do not collect any&nbsp;<strong>Special Categories of Personal Data</strong>&nbsp;about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
                            <p><strong>If you fail to provide personal data</strong></p>
                            <p>Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
                            <ol start="3">
                                <li><strong> How is your personal data collected?</strong></li>
                            </ol>
                            <p>We use different methods to collect data from and about you including through:</p>
                            <p><strong>Direct interactions.</strong>&nbsp;You may give us your Identity, Contact, Marketing and Communications, Profile and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>
                            <ul>
                                <li>create an account through the Access Points;</li>
                                <li>subscribe to the Services;</li>
                                <li>request marketing to be sent to you; or</li>
                                <li>give us some feedback.</li>
                            </ul>
                            <p><strong>Automated technologies or interactions.</strong>&nbsp;As you interact with the Access Points, we may automatically collect Technical Data and Usage Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. Please see paragraph 8 below for more information about our use of cookies.</p>
                            <p><strong>Third parties or publicly available sources.</strong>&nbsp;We may receive data about you from various third parties and public sources as set out below:</p>
                            <ul>
                                <li>analytics providers such as Google based outside the EU.</li>
                                <li>affiliate provider platforms such as Capterra based outside the EU.</li>
                                <li>advertising networks such as Google, Bing, Facebook, Twitter based outside the EU.</li>
                                <li>Usage Data from communications providers such as BT based inside the EU, and Hutchison 3G based outside the EU.</li>
                                <li>search information providers such as Google or MSN based outside the EU.</li>
                                <li>Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Stripe based outside the EU, Chargebee based outside the EU, ChartMogul based outside the EU.</li>
                                <li>Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the EU.</li>
                            </ul>
                            <ol start="4">
                                <li><strong> How we use your personal data</strong></li>
                            </ol>
                            <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
                            <ul>
                                <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                                <li>Where we need to comply with a legal or regulatory obligation.</li>
                            </ul>
                            <p>See paragraph 13 below to find out more about the types of lawful basis that we will rely on to process your personal data.</p>
                            <p>Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</p>
                            <p><strong>Purposes for which we will use your personal data</strong></p>
                            <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
                            <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
                            <table class="cl-policy-table" border="1px" style={{position: 'fixed'}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><strong>Purpose/Activity</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>Type of data</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>Lawful basis for processing including basis of legitimate interest</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To register you as a new customer</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity</p>
                                            <p>(b) Contact</p>
                                        </td>
                                        <td>
                                            <p>Performance of a contract with you</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To allow your use of the Services</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity Data</p>
                                            <p>(b) Contact Data</p>
                                            <p>(c) Technical Data</p>
                                            <p>(d) Profile Data</p>
                                        </td>
                                        <td>
                                            <p>(a) Performance of a contract with you</p>
                                            <p>(b) Necessary to comply with a legal obligation</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To process and deliver your order including:</p>
                                            <p>(a) Manage payments, fees and charges</p>
                                            <p>(b) Collect and recover money owed to us</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity</p>
                                            <p>(b) Contact</p>
                                            <p>(c) Financial</p>
                                            <p>(d) Transaction</p>
                                            <p>(e) Marketing and Communications</p>
                                        </td>
                                        <td>
                                            <p>(a) Performance of a contract with you</p>
                                            <p>(b) Necessary for our legitimate interests (to recover debts due to us)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To manage our relationship with you which will include:</p>
                                            <p>(a) Notifying you about changes to our Terms and Conditions or Privacy and Cookies Policy</p>
                                            <p>(b) Communicating with you if you contact us</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity</p>
                                            <p>(b) Contact</p>
                                            <p>(c) Profile</p>
                                            <p>(d) Marketing and Communications</p>
                                        </td>
                                        <td>
                                            <p>(a) Performance of a contract with you</p>
                                            <p>(b) Necessary to comply with a legal obligation</p>
                                            <p>(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To administer and protect our business and the Services (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity</p>
                                            <p>(b) Contact</p>
                                            <p>(c) Technical</p>
                                        </td>
                                        <td>
                                            <p>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</p>
                                            <p>(b) Necessary to comply with a legal obligation</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To deliver relevant content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity</p>
                                            <p>(b) Contact</p>
                                            <p>(c) Profile</p>
                                            <p>(d) Marketing and Communications</p>
                                            <p>(e) Technical</p>
                                        </td>
                                        <td>
                                            <p>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To use data analytics to improve the Services, marketing, customer relationships and experiences</p>
                                        </td>
                                        <td>
                                            <p>Technical</p>
                                        </td>
                                        <td>
                                            <p>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our Services updated and relevant, to develop our business and to inform our marketing strategy)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>To make suggestions and recommendations to you about goods or services that may be of interest to you</p>
                                        </td>
                                        <td>
                                            <p>(a) Identity</p>
                                            <p>(b) Contact</p>
                                            <p>(c) Technical</p>
                                            <p>(d) Profile</p>
                                        </td>
                                        <td>
                                            <p>Necessary for our legitimate interests (to develop our products/services and grow our business)</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p><strong>Marketing</strong></p>
                            <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising:</p>
                            <p><strong>Promotional offers from us</strong></p>
                            <p>We may use your Identity, Contact, Technical, Marketing and Communications, and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</p>
                            <p>You will receive marketing communications from us if you have requested information from us or purchased Services from us and, in each case, you have not opted out of receiving that marketing.</p>
                            <p><strong>Third-party marketing</strong></p>
                            <p>We will get your express opt-in consent before we share your personal data with any other business for marketing purposes.</p>
                            <p><strong>Opting out</strong></p>
                            <p>You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.</p>
                            <p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase or other transactions.</p>
                            <p><strong>Change of purpose</strong></p>
                            <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
                            <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
                            <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
                            <ol start="5">
                                <li><strong> Disclosures of your personal data</strong></li>
                            </ol>
                            <p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>
                            <ul>
                                <li>Third Parties as set out in paragraph 13 below.</li>
                                <li>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy and Cookies Policy.</li>
                                <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation; or to protect the rights, property, or safety of us, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
                            </ul>
                            <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
                            <ol start="6">
                                <li><strong> International transfers</strong></li>
                            </ol>
                            <p>We do not transfer your personal data outside the European Economic Area (<strong>EEA</strong>).</p>
                            <ol start="7">
                                <li><strong> IP addresses</strong></li>
                            </ol>
                            <p>We may collect information about the Device from which you access the Services, including where available your IP address, operating system and browser type, for system administration. This is statistical data about our users' browsing actions and patterns, and does not identify any individual.</p>
                            <ol start="8">
                                <li><strong> Cookies</strong></li>
                            </ol>
                            <p>The Access Points use cookies. Cookies are tiny text files which identify your computer or device to our server as a unique user when you visit certain pages on the Access Points and they are stored by your Internet browser on your computer's hard drive. Cookies can be used to&nbsp;recognise&nbsp;your Internet Protocol address, saving you time while you are on the Access Points.&nbsp;</p>
                            <p>We only use cookies for your convenience in using the Access Points and not for obtaining or using any other information about you (for example targeted advertising). Your browser can be set to not accept cookies, but this would restrict your use of the Access Points.&nbsp;</p>
                            <p>If you want to find out more information about cookies, go to&nbsp;<a href="http://www.allaboutcookies.org/" style={{color:'black'}}>http://www.allaboutcookies.org</a>&nbsp;or to find out about removing them from your browser, go to&nbsp;<a href="http://www.allaboutcookies.org/manage-cookies/index.html" style={{color:'black'}}>http://www.allaboutcookies.org/manage-cookies/index.html</a>.</p>
                            <p>Please note that we collect data from the Access Points about use of the Access Points using a web analysis tool which uses cookies that are (able to be) used to generate pseudonyms for a user’s profile. This means that use cookies (which may be stored on your computer after you leave the Access Points) can store information about your visit.</p>
                            <p>The Website uses the following cookies:</p>
                            {/* <div class="hidden-lg">
                                <strong style={{marginLeft: '40px'}}>Website usage tracking</strong>
                                <p>Google (google.com) &nbsp; 2 years</p>
                                <p>Intercom (intercom.io) &nbsp; 2 years</p>
                                <p>Hubspot (hubspot.com) &nbsp; 2 years</p>
                                <p>Hotjar (hotjar.com)  &nbsp; 1 year</p>
                                <p>Facebook (facebook.com)&nbsp; 2 years</p>
                                <p>Twitter (twitter.com)&nbsp; 2 years</p>
                                <p>LinkedIn (linkedin.com) &nbsp; 2 years</p>
                                <p>Bing (bingads.com) &nbsp; 2 years</p>
                                <strong style={{marginLeft: '40px'}}>Information capture</strong>
                                <p>Hubspot (hubspot.com) &nbsp; 2 years</p>
                                <strong style={{marginLeft: '40px'}}>	Affiliate marketing conversion tracking </strong>
                                <p>Rakuten (rakuten.com) &nbsp; 1 year</p>

                            </div> */}




                            <div class=" hidden-xs hidden-sm">
                                <table class="cl-policy-table" border="1px">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p><strong>Name</strong></p>
                                            </td>
                                            <td>
                                                <p><strong>Expiration date</strong></p>
                                            </td>
                                            <td>
                                                <p><strong>Purpose</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Google (google.com)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Intercom (intercom.io)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Hubspot (hubspot.com)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Information capture &amp; website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Hotjar (hotjar.com)</p>
                                            </td>
                                            <td>
                                                <p>1 year</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Facebook (facebook.com)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Twitter (twitter.com)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>LinkedIn (linkedin.com)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>kasookoo (kasookoo.com)</p>
                                            </td>
                                            <td>
                                                <p>1 year</p>
                                            </td>
                                            <td>
                                                <p>Website session storage</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Bing (bingads.com)</p>
                                            </td>
                                            <td>
                                                <p>2 years</p>
                                            </td>
                                            <td>
                                                <p>Website usage tracking</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Rakuten (rakuten.com)</p>
                                            </td>
                                            <td>
                                                <p>1 year</p>
                                            </td>
                                            <td>
                                                <p>Affiliate marketing conversion tracking</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>The App uses the following cookies:</p>
                            <table class="cl-policy-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><strong>Name</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>Expiration date</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>Purpose</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Intercom</p>
                                        </td>
                                        <td>
                                            <p>2 years</p>
                                        </td>
                                        <td>
                                            <p>App usage tracking</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ol start="9">
                            <li><strong> Where we store your personal data</strong></li>
                        </ol>
                        <p>All information you provide to us is stored on our secure servers. We use industry standard security and firewalls on our servers.&nbsp;Where we have given you (or where you have chosen) a password which enables you to access certain Services, you are responsible for keeping that password confidential. We ask you not to share a password with anyone.</p>
                        <p>We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of your information.&nbsp;Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.</p>
                        <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                        <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
                        <p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to the Access Points; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                        <ol start="10">
                            <li><strong> Security</strong></li>
                        </ol>
                        <p>When you make any payment to us through the Access Points, you must make that payment to us through Stripe, using your credit or debit card, or such other payment methods as we make available from time to time. We use Stripe to process payments that you make through the Access Points, and Stripe has its own privacy policy; we do not accept any responsibility or liability for that privacy policy or the privacy policy of any other payment processing provider we use from time to time. We will tell you which processor we use before you make payment, and please check those privacy policies before you submit any personal data to us.</p>
                        <ol start="11">
                            <li><strong> Data retention</strong></li>
                        </ol>
                        <p><strong>How long will you use my personal data for?</strong></p>
                        <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                        <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
                        <p>By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers. For all other data, details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us by contacting us.</p>
                        <p>In some circumstances you can ask us to delete your data: see paragraph 12 below for further information.</p>
                        <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
                        <ol start="12">
                            <li><strong> Your legal rights</strong></li>
                        </ol>
                        <p>You have the right to:</p>
                        <ul>
                            <li><strong>Request access&nbsp;</strong>to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                            <li><strong>Request correction&nbsp;</strong>of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                            <li><strong>Request erasure&nbsp;</strong>of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                            <li><strong>Object to processing&nbsp;</strong>of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                            <li><strong>Request restriction of processing&nbsp;</strong>of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                            <li><strong>Request the transfer&nbsp;</strong>of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                            <li><strong>Withdraw consent at any time&nbsp;</strong>where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                        </ul>
                        <p><strong>No fee usually required</strong></p>
                        <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
                        <p><strong>What we may need from you</strong></p>
                        <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                        <p><strong>Time limit to respond</strong></p>
                        <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                        <ol start="13">
                            <li><strong> Glossary</strong></li>
                        </ol>
                        <p><strong>LAWFUL BASIS</strong></p>
                        <ul>
                            <li><strong>Legitimate Interest</strong>&nbsp;means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</li>
                            <li><strong>Performance of Contract</strong>&nbsp;means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
                            <li><strong>Comply with a legal or regulatory obligation</strong>&nbsp;means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</li>
                        </ul>
                        <p><strong>THIRD PARTIES</strong></p>
                        <ul>
                            <li>Service providers acting as processors based inside the EU and UK who provide IT and system administration services.</li>
                            <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based UK who provide consultancy, banking, legal, insurance and accounting services.</li>
                            <li>HM Revenue &amp; Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.</li>
                            <li>Law Enforcement and/or National Security Services who may require (on demand) reporting of phone system activities in certain circumstances.</li>
                            <li>Stripe who provide all payment services.</li>
                            <li>Chargebee who provide subscription and billing services.</li>
                            <li>Postmark who provide account operational &amp; notification emails.</li>
                            <li>Hubspot who provide pre-customer marketing, customer onboarding and product updates.</li>
                            <li>Intercom who provide the chat/help system.</li>
                            <li>We use third party providers for phone number porting services. In the event that you wish to initiate a number port to us, we will advise you at the time as to which provider we will pass your information and seek and record your consent to do so.</li>
                            <li>Grafana who provide call quality monitoring &amp; reporting.</li>
                            <li>VoipMonitor who provide call quality monitoring &amp; reporting.</li>
                        </ul></div>

                </div>

            </section>


        </div>
    )
}
