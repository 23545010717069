import React from 'react'
import './termandconditions.css'
export default function 
() {
  return (
    <div className='term-condittion'>



		

<section class="w-100 d-block hero-single-careers pt-5 d-flex align-items-center mb-5" style={{ backgroundColor: '#7DBAAB' }}>
                <div class="container py-5 text-white">
                    <div class="row text-center py-2">
                        <div class="col-12 col-md-8 col-xl-6 mx-auto">
                            <h1 class="style-h3">Terms and Conditions</h1>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">

                    </div>
                </div>
            </section>

   
	
		<section id="about">
			<div class="container">
				{/* <h1 class="section-title wow fadeIn animated" data-wow-delay=".2s" style={{visibility: 'visible'}}><span>Terms and </span> Conditions</h1> */}
				<div class="row">
					<p><a href="http://www.kasookoo.com/" style={{color:'black'}}>kasookoo.com</a>&nbsp;(the “Service”) is a self-service platform for the provision of Telephony and
							Communication Services to be managed by the Customer and its Users,
							accessed by Callers. The Service is operated and owned by Yuave
							Limited (Company number 08097391) whose registered office is 4th Floor, Silverstream House, 45 Fitzroy Street, Fitzrovia, London, W1T 6EB. These
							Terms &amp; Conditions and our Privacy Policy constitute the full legal
							agreement between you (the “Customer”, “you” and “your”) and Yuave Limited (hereafter referred to as “We”, “Our” and “Us”).</p>
							<p><strong>IMPORTANT INFORMATION:</strong></p>
							<p><strong>PLEASE NOTE THAT THE SERVICE DOES NOT SUPPORT USE FOR ANY
								EMERGENCY SERVICE CALLS. ANY CALLS YOU NEED TO MAKE OF THIS NATURE
								SHOULD BE MADE FROM YOUR NEAREST LANDLINE OR MOBILE PHONE THROUGH YOUR
								PHONE NETWORK PROVIDER. USING YOUR NEAREST FIXED LINE OR MOBILE PHONE TO
								MAKE EMERGENCY CALLS WILL ENSURE THAT THE EMERGENCY SERVICES
								AUTOMATICALLY HAVE THE MOST ACCURATE DATA ON YOUR CALLING LOCATION.</strong></p>
							<p><strong>THE USE OF THIS SERVICE WILL RELY ON THE AVAILABILITY OF YOUR
								OWN DEVICES, HARDWARE, PHONE DATA NETWORK AND INTERNET PROVIDER AT ALL
								TIMES – ANY RESTRICTIONS IN ANY OF THE ABOVE SERVICES OR DEVICES WILL
								ALSO LIMIT THE QUALITY OF THE SERVICE.</strong></p>
							<p>The terms, conditions, and notices contained in these Terms &amp;
									Conditions must be agreed to and accepted without qualification or
									amendment in order to obtain the Service as defined below. By using the
									Service, you hereby agree in full to all terms and conditions of this
									Agreement. If you do not agree to these terms and conditions, then
									please do not access or use the Service.&nbsp;</p>
							<p>If you begin the sign-up process but fail to complete the process, we
								may contact you in an effort to help you complete the process. You
								hereby authorise us to contact you in this circumstance even if you
								ultimately decide not to sign up to the Service.</p>
							<ol>
								<li><strong>1. Definitions</strong></li>
							</ol>
							<p>1.1 In this Agreement the following terms shall have the following meanings unless the context requires otherwise:</p>
							<p><strong>Act:</strong>&nbsp;means the Communications Act 2003.</p>
							<p><strong>Agreement:</strong>&nbsp;shall mean this document between Us and the You.</p>
							<p><strong>Application:</strong>&nbsp;means&nbsp;<a href="http://www.kasookoo.com/" style={{color:'black'}}>kasookoo.com</a>&nbsp;and
								any other websites, administration tools and/or software applications
								to be provided by us, or our authorised partners, to you.</p>
								<p><strong>Artificial Inflation of Traffic:</strong>&nbsp;means any
									situation where the Service experiences unusual call patterns that are
									disproportionate to the overall amount of usage as would reasonably be
									expected.</p>
								<p><strong>Business Day</strong>: means any day which is not a Saturday or Sunday or a public bank holiday in England.</p>
								<p><strong>Call:</strong>&nbsp;means a signal, message, voicemail or communication which can be silent, visual (including text and data) or spoken.</p>
								<p><strong>Caller</strong>: a person who ultimately uses the Service through the Customer.</p>
								<p><strong>Confidential Information</strong>: has the meaning set out in clause 15.1.</p>
								<p><strong>Customer Information</strong>: means any information,
									including (without limitation) any routing destinations, images, audio,
									audio-visual, text or other information or content that relates to the
									Customer’s use of the Service.</p>
									<p><strong>Controller:</strong>&nbsp;has the meaning set out in GDPR.</p>
									<p><strong>Data Protection Legislation:</strong>&nbsp;in relation to any
										Personal Data which is Processed in the performance of this Agreement,
										the Data Protection Act 1998 and EU Data Protection Directive 95 / 46 /
										EC (prior to 25 May 2018), GDPR (on and after 25 May 2018), the
										Investigatory Powers Act 2016, Telecommunications (Lawful Business
										Practice), the Privacy and Electronic Communications Directive 2002 / 58
										/ EC, the Privacy and Electronic Communications (EC Directive)
										Regulations 2003 (SI 2003 / 2426), in each case together with all laws
										implementing or supplementing the same and any other applicable or
										equivalent data protection or privacy laws, and all other applicable
										law, regulations and codes of conduct relating to the processing of
										personal data and privacy, including the guidance and codes of practice
										issued by a relevant Supervisory Authority.</p>
										<p><strong>Data Subject:</strong>&nbsp;has the meaning set out in GDPR.</p>
<p><strong>Emergency Call:</strong>&nbsp;means a Call from a Caller to the emergency services made by dialling 999 or 112.</p>
<p><strong>GDPR:</strong> the General Data Protection Regulation (EU 2016/679).</p>
<p><strong>Inappropriate Content</strong>: any content that infringes
any applicable laws, regulations or third party rights (such as, for
example, material which is obscene, indecent, pornographic, seditious,
offensive, defamatory, threatening, liable to incite racial hatred,
menacing, blasphemous or in breach of any third party Intellectual
Property Rights or the Act).</p>
<p><strong>Intellectual Property Rights</strong>: means all intellectual
property rights (including without limitation, patents, trademarks,
copyrights, designs, rights in databases, user data, rights in and to
know-how and Confidential Information (in each case whether registered
or unregistered)) and any and all applications for the same.</p>
<p><strong>Insolvency Proceedings:</strong>&nbsp;means going into
administration, administrative receivership, liquidation or receivership
or becoming unable to pay debts as they fall due within the meaning of
section 123 of the Insolvency Act 1986 or suffering any similar or
analogous action in consequence of debt.</p>
<p><strong>Network:</strong>&nbsp;means the network provided by us or any other third-party network supporting the Service.</p>
<p><strong>Number</strong>: means any number specifically requested by or allocated to the Customer for the provision of the Service to Callers.&nbsp;</p>
<p><strong>Personal Data:</strong>&nbsp;has the meaning set out in GDPR
and relates only to personal data, or any part of such personal data, in
respect of which you are the Controller and in relation to which we are
providing services under this Agreement.</p>
<p><strong>Personal Data Breach:</strong> has the meaning set out in GDPR.</p>
<p><strong>Premium Features</strong>: means any additional features and bolt-on extras you may add to your Service from time to time.</p>
<p><strong>Processing and Process:</strong>&nbsp;have the meaning set out in GDPR.</p>
<p><strong>Service</strong>: the service provided to you by us as described above.</p>
<p><strong>Service Fee</strong>: means any charge in respect of the Service levied by us from time to time to you.</p>
<p><strong>Software</strong>: any software used by us, or any party acting on our behalf in fulfilling its obligations under this Agreement or otherwise.</p>
<p><strong>Special Categories of Personal Data: </strong>those categories of data listed in Article 9(1) GDPR.</p>
<p><strong>Supervisory Authority: </strong>means (a) an independent
public authority which is established by a Member State pursuant to
Article&nbsp;51 GDPR; and (b)&nbsp;any similar regulatory authority
responsible for the enforcement of Data Protection Legislation.</p>
<p><strong>Third Party Information:</strong>&nbsp;means information,
audio, video, graphics, sound, music, photographs, software, data,
databases, records, and any other materials (in whatever form) not owned
or generated by or on behalf of the Customer, published or otherwise
utilised or made available using the Service.</p>
<p><strong>Users</strong>: means any user within your business who you authorise to use this Service.</p>
<p><strong>Usage Rates</strong>: means the charges incurred by you calculated against the usage based pricing which can be found at&nbsp;<a href="https://www.kasookoo.com/pricing" style={{color:'black'}}>https://www.kasookoo.com/pricing</a></p>
<p>1.2 Clause and schedule headings do not affect the interpretation of this Agreement.</p>
<p>1.3 References to clauses and schedules are (unless otherwise
provided) references to the clauses and schedules of this Agreement.</p>
<p>1.4 Words in the singular include the plural and in the plural include the singular.</p>
<p>1.5 A reference to a particular law is a reference to it as it is in
force at the date of this Agreement taking account of any amendment,
extension, application or re-enactment and including any subordinate
legislation in force at the date of this Agreement made under it.</p>
<ol>
<li><strong>2. Our Obligations</strong></li>
</ol>
<p>2.1 We shall provide the Service to you in consideration for the due
and timely fulfilment of the Customer's obligations under this
Agreement.</p>
<p>2.2 Use of the Service is at all times subject to the terms and
conditions of this Agreement and by using the Service, the Customer
confirms their acceptance of, and agrees to be bound by, this Agreement.</p>
<p>2.3 We reserve the right to amend or alter in any way these terms and
conditions or charges for the Service at any time. We shall make
reasonable endeavours to notify the Customer of any such changes,
including making notice of the same to the Customer, but any change
shall take effect even if the Customer fails to receive such notice.</p>
<p>2.4 We shall provide reasonable endeavours to manage and maintain a professional Service, Network and Application.</p>
<p>2.5 We shall grant you a non-exclusive, non-assignable or transferable right to use the Service in accordance with these terms.</p>
<p>2.6 We shall provide support to you for the Service via our help-desk and online knowledgebase.</p>
<ol>
<li><strong>3. Customer Obligations</strong></li>
</ol>
<p>3.1 The Customer may only use the Service for lawful purposes and at all times in accordance with the Act.</p>
<p>3.2 The Customer will not, nor will the Customer authorise or permit any other person to, use the Service or the Application:</p>
<ol>
<li>a) to send or receive any Inappropriate Content or any other
information or material, any part of which, or the accessing of which or
use of which would be a criminal offence or otherwise be unlawful;</li>
<li>b) to create any Artificial Inflation of Traffic in anyway;</li>
<li>b) to sell, or offer to sell goods or services which are in
violation of any laws, regulations or codes of practice. The Customer
shall take such corrective action as we may require in such
circumstances promptly following notification from us.</li>
<li>c) to pretend to be a party which it is not or use the Service to falsely represent another party.</li>
</ol>
<p>3.3 The Customer warrants that Customer Information is and will
remain accurate and will not include any Inappropriate Content or any
other information or material, any part of which, or the accessing of
which or use of which would be a criminal offence or otherwise unlawful
including the breach of any Intellectual Property Rights of any other
person. We reserve the right to remove such content from the Service,
Application and Network where, in our sole discretion, we suspect such
content to be in breach of this clause 3.3.</p>
<p>3.4 The Customer will be solely responsible for the accuracy,
completeness, design, creation, maintenance, and updating thereof of all
Customer Information. We shall not be liable for any errors or
inaccuracies in any Customer Information</p>
<p>3.5 The Customer shall be responsible for obtaining all necessary
licenses and consents required to use Service and the Customer warrants
that such licences and consents have been obtained and will be subject
in this regard to the indemnity at clause 12.</p>
<p>3.6 The Customer shall permit us to document you as a client case
study or reference client and to use your name in any publicity material
we may produce without first referring any such material to you for
approval.</p>
<p>3.7 The Customer shall provide us with any assistance we require in
relation to any query or enquiry in relation to the Service howsoever
arising.</p>
<p>3.8 The Customer shall ensure it does not use the Service excessively
and beyond what we deem is reasonable but will use its reasonable
endeavours to actively promote the Service to its Callers.</p>
<p>3.9 The Customer will not sell or re-sell the Service to any third party.</p>
<p>3.10 The Customer will not use or associate the Service with any
unsolicited telemarketing activities (“Spam”) and agrees to pay us a fee
of £25 for each Spam complaint received by us. This will be charged for
and applied to your Usage Rates and charged to the payment card you
used to register for the Service.</p>
<p>3.11 The Customer warrants that the name, email address, phone
numbers, postal address and payment information (where required upfront)
that the Customer provides when the Customer registers for the Service
is correct and the Customer agrees to update its account immediately in
the event of any changes to such details.</p>
<p>3.12 The Customer warrants that it possesses the legal right and
ability to enter into this Agreement and to use the Service in
accordance with this Agreement.</p>
<p>3.13 The Customer confirms that the person contracting on behalf of
the Customer has authority to bind the Customer in relation to the
Customer’s purchase of the Service.</p>
<p>3.14 Any breach of this clause 3 by the Customer shall be deemed to
be a material breach of this Agreement and shall entitle us to
immediately terminate the Agreement and, for this purpose, it shall be
irrelevant whether the Customer was aware of the content of any material
so transmitted or not. Notwithstanding and in addition to clause 8, we
may suspend the Service without notice with immediate effect if, in our
reasonable opinion, the Customer is in breach of this clause 3 and we
may refuse to restore the Service until the Customer gives us an
acceptable assurance that there will be no further contravention.</p>
<p>3.15 The Customer is responsible for notifying any applicable terms
of the Service to its Users and for ensuring that its Users comply with
such terms.</p>
<ol>
<li><strong>4. Warranties</strong></li>
</ol>
<p>4.1 Other than as cannot be excluded in law, we expressly exclude all
warranties, express or implied, including, but not limited to, implied
warranties of merchantability and fitness for a particular purpose in
relation to the provision of the Service.</p>
<p>4.2 We do not warrant that the Service will be error and or virus
free or operate without disruption or is compatible with all equipment
and software configurations.</p>
<p>4.3 The full extent of our liability in respect of the provision of the Service is as set out in clause 10.</p>
<ol>
<li><strong>5. Service Specific Conditions</strong></li>
</ol>
<p>5.1 Number allocation - once you have registered for the Service you
shall be allocated or can select from us your Numbers at random to use
in conjunction with the Service and for your Callers to contact you on
in accordance with the terms of this Agreement.&nbsp;</p>
<p>5.2 Number usage – where the “Free” service is concerned (see clause
7.1), if you do not use the Service or your Number for a period of over
6-months we reserve the right to cancel the Service and re-allocate your
Number back to stock for potential re-use by another party in the
future.</p>
<p>5.3 Number testing - It is your responsibility to ensure that the
Number works correctly when being diverted to the Customer Information
and all equipment you have is in full working order.</p>
<p>5.4 Call and voicemail recording – subject to termination of this
Agreement we will store any recorded Calls for as long as you state you
need them accessible within the Application of the Service for that
period only. Any recordings stored will be held in accordance with Data
Protection terms specified in clause 13 and then deleted unless you
download and store them yourself.</p>
<p>5.5 Emergency calling – you will NOT be able to make Emergency Calls
from the Service. We would always advocate using the nearest fixed line
or mobile telephone to make Emergency Calls to ensure the emergency
services automatically have the most accurate data on your calling
location.</p>
<p>5.6 Number porting into the Service – should you wish to port any
existing Number into the Service then you must contact us via
help@kasookoo.com to complete the required consent paperwork to
complete this process, we will then handle this for you. There will be
an admin charge payable of at least £20 per number but this will be
agreed with you in writing prior to any number being ported.</p>
<p>5.7 Number porting away – should you wish to port any existing
Numbers away from the Service then you must contact your new service
provider to request this. We will typically receive instruction from
them within 10-days to action this request. We cannot however guarantee
that your new service provider will have the ability to port in your
Number.</p>
<p>5.8 Support – we will provide you with support facilities for the
Service through the Application, however, should you need to raise a
support query with us directly email help@kasookoo.com.</p>
<p>5.9 Service related communications - we will send you updates about
the Service, these will include new feature updates, service updates,
support updates and other general communications at our discretion. If
you do not want to receive these, you can opt-out at any stage.&nbsp;</p>
<p>5.10 Marketing communications – we will occasional send you carefully
selected marketing communications, if you do not want to receive these
you can opt-out at any stage. Opting out of these marketing
communications will not impact any Service related communications and
you will still receive these.</p>
<ol>
<li><strong>6. Charges</strong></li>
</ol>
<p>6.1 You agree to pay the Service Fee for your use of the Service at the prices disclosed in the Application.&nbsp;</p>
<p>6.2 You acknowledge that prices disclosed could be subject to change
and we will do our best to notify you of such changes and providing you
as much notice as possible. We, as additional features to the Service,
will add to the things which you can pay for within the Service Fees.
These will always be features you are in control of and you can use them
or not as you prefer.</p>
<p>6.3 All pricing disclosed on the Application will be invoiced and
paid in Great British Pounds sterling (GBP £) and is exclusive of value
added tax (VAT) and this will be added at the point of payment.</p>
<p>6.4 All charges and VAT invoices for the Service will be emailed to you.</p>
<p>6.5 When you first sign-up to the Service we will ask for your
payment card information and, subject to clause 6.7, payments will be
pre-authorised.</p>
<p>6.6 You will be able to view all current logs, Usage Rates and amend and/or update Customer Information in the Application.</p>
<p>6.7 Invoices will, other that in respect of the period covering the
free trial period, be for periods of one month and will be issued
automatically by charging the payment card used to register for the
Service on the date of such invoice. Changes made to your subscription
during your monthly billing period will be calculated pro-rata for the
remainder of the month and an invoice generated the day following the
changes made. We will send you an invoice for this each time and if you
cancel the Service through the Application you will automatically stop
charges with effect from the end of the then current month and after
settlement of that month’s charges based on Usage Rates.&nbsp;<strong>Please
note that your Service will not work if you do not have a valid credit
card with us and you will not be able to accept Calls on your Number
through the Service.</strong></p>
<p>6.8 We will not issue refunds for any reason on the Service.&nbsp;</p>
<p>6.9 If your payment card fails to make a complete payment to us or we
experience any payment card fraud and/or chargebacks then we have the
right to immediately suspend and/or terminate the Customer’s account and
the Service shall immediately cease.</p>
<p>6.10 If the Customer subsequently makes a valid payment to us then
the Service will be re-instated and we shall make all reasonable
endeavours to restore all data collected by us for the Service provided
to the Customer prior to a termination. If the Customer does not make a
payment within 2 weeks then all such Customer data shall be deleted by
us and the account terminated and Number(s) reclaimed by us and returned
to stock for potential re-use by another person.</p>
<ol>
<li><strong>7. Service Provision</strong></li>
</ol>
<p>7.1 Our service level provisions are set out at&nbsp;<a href="https://www.kasookoo.com/pricing" style={{color:'black'}}>https://www.kasookoo.com/pricing</a>&nbsp;and
we have two paid for plans known as the “Pro” Plan and Pay as you
Go (PAYG) Plan. We may also offer a “Free” service which is operated in
conjunction with selected partners and/or promotions.</p>
<p>7.2 The Free service is a free service to which the terms and
conditions set out herein shall apply save where not applicable. For the
avoidance of doubt, the Free service is an inbound call only service
and you will not be able to make outgoing calls using this service.</p>
<p>7.3 The Pro and PAYG service are paid for service
subscriptions, chargeable monthly as per the latest pricing lists set
out at&nbsp;<a href="https://www.kasookoo.com/pricing" style={{color:'black'}}>https://www.kasookoo.com/pricing</a>. On the PAYG plan you will be charged for any and all additional usage based on our Usage Rates.</p>
<p>7.4 Notwithstanding any other provision of these terms and
conditions, if you do not choose to add card payment details before the
end of the trial, your account will be suspended. You may add payment
details up to 30 days post-trial period, at which point the account will
activate immediately and the amount of Premium Features and Usage Rates
will be invoiced in accordance with clause 6.7.</p>
<p>7.5 Should you terminate the free trial within the free trial period
you will not be billed for any usage as all features enabled within the
trial period are included within the trial.</p>
<p>7.6 If you add new Users or extra Numbers into the Pro or PAYG
services then these will be charged for in advance calculated pro-rata
for the remainder of the current billing period. In the following
billing months, any new User or Number charges will be consolidated into
a single bill combining both monthly fees, any Premium Features and any
usage based on the Usage Rates.</p>
<ol>
<li><strong>8. Security</strong></li>
</ol>
<p>8.1 The Customer shall not attempt to circumvent system security or
access the source code of the Software or the Application of the
Service.</p>
<p>8.2 The Customer shall keep confidential any passwords or other
security information relating to the Software or the Application of the
Service.</p>
<p>8.3 The Customer is responsible for the security and proper use of
all user IDs, passwords, used in connection with the Service and must
take all necessary steps to ensure that they are kept confidential,
secure, used properly and not disclosed to unauthorised persons.</p>
<p>8.4 The Customer must immediately inform us if there is any reason to
 believe that a user ID or password issued by us has or is likely to
become known to someone not authorised to use it or is being or is
likely to be used in an unauthorised way.</p>
<p>8.5 We reserve the right to suspend access to the Service if at any
time we consider that there is or is likely to be a breach of security
or if the Customer fails to comply with our instructions or requests in
relation to security matters.</p>
<p>8.6 We reserve the right (at our sole discretion) to require the
Customer to change any or all of the passwords used by the Customer in
connection with the Service.</p>
<ol>
<li><strong>9. Term and Termination</strong></li>
</ol>
<p>9.1 To terminate the Agreement the Customer must either cancel the
account through the Application process  with formal confirmation of cancellation served in
 writing pursuant to clause 16 and we shall terminate the Agreement
immediately during the free trial period or the then current month, as
the case may be, subject, in the latter case, to being given seven days
notice and to receipt of such written confirmation prior to this time.</p>
<p>9.2 If you terminate the Agreement of your own choosing then you will lose your Number(s) permanently.&nbsp;</p>
<p>9.2 On termination of the Service any balance due on termination will be invoiced and collected in accordance with clause 6.</p>
<p>9.3 We may terminate this Agreement, without notice, if you are in
breach of any part of this Agreement or suffer Insolvency Proceedings,
although your obligation to pay then current charges shall survive
termination of this Agreement.</p>
<ol>
<li><strong>10. Limitation of Remedies and Liability: Your attention is particularly drawn to this clause</strong></li>
</ol>
<p>10.1 Nothing in this Agreement shall operate to exclude or limit either party´s liability for:</p>
<ol>
<li>a) death or personal injury caused by its negligence; or</li>
<li>b) fraud; or</li>
<li>c) any other liability which cannot be excluded or limited under applicable law.</li>
</ol>
<p>10.2 We shall not be liable to the Customer or any third party for
any indirect, incidental, sequential or consequential loss or damages
arising under this Agreement (including without limitation) loss of
profits, business, earnings or opportunity arising under or in
connection with this Agreement.</p>
<p>10.3 Other than as stated in clause 10.1, we shall not be liable to
the Customer in contract, tort (including negligence) or otherwise in
relation to this Agreement.</p>
<p>10.4 The provisions of this Agreement and the rights and remedies of
the parties under this Agreement are cumulative and are without
prejudice and in addition to any rights or remedies a party may have at
law or in equity.</p>
<p>10.5 The Customer accepts that we are neither obligated nor able to
edit, review or modify Customer Information or Third Party Information
and that we do not examine the use to which the Customer puts the
Service. However, we reserve the right to remove any Customer
Information or Third Party Information if we reasonably believe such
information breaches any laws or regulations or any third party´s
rights.</p>
<p>10.6 We exclude all liability of any kind in respect of Customer
Information, Third Party Information or any other material which can be
accessed using the Service and are not responsible in any way for any
goods (including software) or services provided advertised, sold or
otherwise made available by means of the Service.</p>
<ol>
<li><strong>11. Intellectual Property Rights</strong></li>
</ol>
<p>11.1 All Intellectual Property Rights in Customer Information
("Customer Intellectual Property") are and shall remain the property of
the Customer and its licensors.</p>
<p>11.2 Except as provided for at clause 11.1, all Intellectual Property
 Rights in the Service and/or the Software, Application, Service sand
any supporting or associated material relating to the Software, the
Application or the Service developed by us in fulfilling our obligations
 under this Agreement or in connection with this Agreement, whether the
Software, the Application or the Service or material is particular to
the Customer or not, and any other material provided to or made
accessible to the Customer by us as a result of this Agreement ("Our
Intellectual Property") are and shall remain our sole property.</p>
<p>11.3 The Customer grants to us a non-transferable, royalty free
licence to use the Customer Intellectual Property for the purposes of
performing our obligations under this Agreement.</p>
<p>11.4 We grant the Customer a non-transferable, non-exclusive, royalty
 free licence to use Our Intellectual Property for the purposes of
performing your obligations to us under this Agreement.</p>
<ol>
<li><strong>12. Third Party Transactions</strong></li>
</ol>
<p>12.1 The Customer shall remain solely responsible for any
transactions of any kind entered into between the Customer and Users or
any other third parties accessing or acting in reliance on the Service,
or any information on the site. We will not be a party to or in any way
responsible for any transaction between the Customer and a Buyer or
other third party.</p>
<p>12.2 The Customer agrees to indemnify and keep us indemnified from
any claim brought by a third party resulting or arising from the
Customer´s use of the Service, any breach of its obligations under
clauses 3 and 4 of this Agreement by the Customer, our hosting of the
Service, or any claim brought by a third party resulting or arising in
any way from any Customer information or any other material provided by
the Customer to us.</p>
<p>12.3 The indemnities at clause 12.2 includes all costs, expenses,
damages, awards, fees (including reasonable legal fees) and judgments
finally awarded against us arising from any relevant claims.
Furthermore, the Customer agrees it will provide us with notice of such
claims, gives us full authority to defend, compromise or settle such
claims and to give all reasonable assistance necessary to defend such
claims, at the Customer´s sole expense. We agree that any actions taken
in respect of such claims will be taken in consultation with the
Customer.</p>
<ol>
<li><strong>13. Data Protection</strong></li>
</ol>
<ol>
<ol>
<li>1. Both parties will comply with all applicable requirements of the
Data Protection Legislation. This clause 13 is in addition to, and does
not relieve, remove or replace, a party's obligations under the Data
Protection Legislation.</li>
<li>2. The parties acknowledge that for the purposes of the Data Protection
 Legislation, the Customer is the Controller and we the Processor of any
 Personal Data. We Process Personal Data in accordance with the
following scope, nature and purpose:</li>
</ol>
</ol>
<table class="cl-policy-table" border="1px">
<tbody>
<tr>
<td>
<p><strong>Nature and purpose of the processing of Personal Data</strong></p>
</td>
<td>
<p>In order that we can provide our Services to you in accordance with this Agreement</p>
</td>
</tr>
<tr>
<td>
<p><strong>Subject matter of the Processing of Personal Data</strong></p>
</td>
<td>
<p>For our provision of Services to you as our customer, as set out in this Agreement.</p>
</td>
</tr>
<tr>
<td>
<p><strong>Duration of the Processing of Personal Data</strong></p>
</td>
<td>
<p>The term of our contract with you in accordance with this Agreement,
until we delete that data in accordance with this Agreement.</p>
</td>
</tr>
<tr>
<td>
<p><strong>Categories of Personal Data being shared between you and us</strong></p>
</td>
<td>
<p>Data relating to individuals provided to us by you or any person you
authorise (or direct) to access or use the Services (or at your
direction).</p>
</td>
</tr>
<tr>
<td>
<p><strong>Types of Data Subject in respect of whom we Process Personal Data</strong></p>
</td>
<td>
<p>The Data Subjects in respect of whom you use our Services and data is
 sent (or provided) to us through those Services, by you, at your
direction or with your authorisation.</p>
</td>
</tr>
</tbody>
</table>
<ol>
<ol>
<li>3. Each party confirms that it holds, and during the term of this
Agreement will maintain, all registrations and notifications required in
 terms of the Data Protection Legislation which are appropriate to its
performance of the obligations under this Agreement.</li>
<li>4.We will:</li><p>4.1 Process Personal Data only on documented
instructions from you, unless required to do so by Data Protection
Legislation or any other applicable law to which we are subject; in such
 a case, we shall inform you of that legal requirement before
Processing, unless that law prohibits us to so inform you; </p>
<p>4.2 ensure that persons we authorise to Process the Personal Data
have committed themselves to confidentiality or are under an appropriate
 statutory obligation of confidentiality; </p>
<p>4.3 take all measures required pursuant to Article 32 of the GDPR in respect of security of Processing; </p>
<p>4.4 subject to clause 13.11 and clause 13.12, not commission any
subcontractor in respect of Processing Personal Data without your prior
written consent (such consent not to be unreasonably withheld or
delayed), and ensure that any such subcontractor we commission complies
with the provisions of this clause 13 as if it was a party to this
Agreement;</p>
<p>4.5 taking into account the nature of the Processing, assist you by
putting in place appropriate technical and organisational measures,
insofar as this is possible, for the fulfilment of your obligation to
respond to requests for exercising the Data Subject's rights laid down
in Data Protection Legislation, to the extent that such requests relate
to this Agreement and our obligations under it; </p>
<p>4.6 assist you in ensuring compliance with your obligations pursuant
to Articles 32 to 36 of the GDPR taking into account the nature of
Processing the Personal Data and the information available to us; </p>
<p>4.7 at your option, delete (to the extent practicable) or return all
the Personal Data to you after termination of this Agreement or
otherwise on your request, and delete existing copies (to the extent
practicable) unless applicable law requires our ongoing storage of the
Personal Data; </p>
<p>4.8 make available to you all information necessary to demonstrate
our compliance with this clause 13.4, and allow for and contribute to
audits, including inspections, conducted by you or another auditor
mandated by you; and</p>
<p>4.9 inform you immediately if, in our opinion, an instruction from
you infringes (or, if acted upon, might cause the infringement of) Data
Protection Laws. Subject to clause 10.1, we shall not have any liability
 in respect of any instruction from you that breaches (or causes a
breach of) Data Protection Legislation to the extent that we could not
reasonably have been aware, or could not reasonably be expected to have
been aware, that such instruction would breach (or cause a breach of)
Data Protection Legislation.</p><li>Each party will notify the other
party as soon as is reasonably practicable if it becomes aware of a
Personal Data Breach relating to either party’s obligations under this
Agreement.</li>
<li>You shall undertake appropriate data protection impact assessments
to ensure that Processing of Personal Data complies with Data Protection
 Legislation. We will provide you with reasonable assistance, where
necessary and upon your request, in carrying out any data protection
impact assessment and undertaking any necessary prior consultation of
the Supervisory Authority.</li>
<li>It is your responsibility to ensure that Personal Data is dealt with in a way that is compliant with Article 5(1) of the GDPR. </li>
<li>You shall ensure that:</li><p>8.1 you are able to justify the
Processing of Personal Data in accordance with Article 6(1) of the GDPR
(including, where applicable, obtaining any and all consents of Data
Subjects required in order to commence the Processing), and that you
have recorded or documented this in accordance with the record keeping
requirements of the GDPR; </p>
<p>8.2 where Personal Data falls within the Special Categories of
Personal Data, Article 9(2) of the GDPR applies to that Personal Data
before Processing takes place; </p>
<p>8.3 where Article 9(2) of the GDPR does not apply to any Personal
Data falling within the Special Categories of Personal Data, no such
data will be sent to us; and</p>
<p>8.4 you have all necessary appropriate consents and notices in place
to enable lawful transfer of the Personal Data to us for the duration
and purposes of this Agreement.</p><li>In the event that we:</li><p>9.1
comply with your instructions in respect of Processing, we shall not
have any liability (subject to clause 10.1) for any damage caused by
Processing that Personal Data, or for any consequences in the event that
 such Processing otherwise infringes Data Protection Legislation, to the
 extent that such damage or consequences result from our compliance with
 such instructions; and/or</p>
<p>9.2 refuse to comply with your instructions in respect of Processing
due to concerns that compliance will cause a breach of Data Protection
Legislation, we shall not have any liability (subject to clause 10.1)
for any failure to follow such instructions.</p><li>Each party agrees to
 indemnify, and keep indemnified and defend at its own expense, the
other party, against all costs, claims, damages or expenses incurred by
the other party or for which the other party may become liable, due to
any failure by the first party or its employees or agents to comply with
 this clause 13.</li>
<li>You authorise our engagement of third parties as subcontractors for
the purposes of Processing; in the event that we contract with such
subcontractors in accordance with the requirements of Data Protection
Legislation, your entry into this Agreement will constitute your prior
written consent to that subcontracting by us in respect of the relevant
Processing. Information about the subcontractors that we engage for
Processing purposes can be found at <a href="http://www.kasookoo.com/#/gdpr" style={{color:'black'}}>www.kasookoo.com/gdpr</a>.</li>
<li>In the event that we engage any new subcontractor for the purposes
of Processing during the term of this Agreement, we will inform you at
least 30 days in advance of the engagement commencing, together with
relevant information relating to that subcontractor and its operations.
You may object to that engagement by contacting us, and, as your sole
and exclusive remedy for such engagement, terminate this Agreement in
accordance with clause 9.1.</li>
<li>Please see our <a href="https://www.kasookoo.com/#/privacy-policy" style={{color:'black'}}>Privacy and Cookies Policy</a> which forms part of this Agreement and set out how we Process certain personal data (as defined in GDPR) as a Controller.</li>
<li>Either party may, at any time on not less than 30 days’ notice,
revise this clause 13 by replacing it with any applicable controller to
processor standard clauses or similar terms forming party of an
applicable certification scheme (which shall apply when replaced by
attachment to this Agreement).</li>
</ol>
</ol>
<ol>
<li><strong>14. Force Majeure</strong></li>
</ol>
<p>If either party is unable to perform any obligation under this
Agreement because of a matter beyond that party's reasonable control
including, without limitation, lightning, flood, exceptionally severe
weather, fire, explosion, war, acts of terrorism, civil disorder,
industrial disputes (whether or not involving employees of either
party), breakdown of plant or machinery, default of suppliers or
sub-contractors or acts of local or central government or other
competent authorities, or events beyond the reasonable control of
suppliers to either party, that party will have no liability to the
other for that failure to perform.</p>
<ol>
<li><strong>15. Confidentiality</strong></li>
</ol>
<p>15.1 Each party shall keep confidential (and ensure that its
employees keep confidential) all information concerning the business,
finances, technology, affairs, clients, marketing plans of the other
party and other information which is identified as such or is
confidential by its nature (including, but not limited to, the terms of
this Agreement) ("Confidential Information").</p>
<p>15.2 Each party shall protect the Confidential Information of the
other party against unauthorised disclosure by using the same degree of
care as it takes to preserve and safeguard its own confidential
information of a similar nature, being at least a reasonable degree of
care.</p>
<p>15.3 Confidential Information may be disclosed by the receiving party
 to its employees, affiliates and professional advisers, or in the case
of us the employees of a any other group company or their suppliers who
need to know the information. Confidential Information may not be
disclosed to any other party without the written consent of the other
party.</p>
<p>15.4 The obligations set out in this clause 15 shall not apply to
Confidential Information which the receiving party can demonstrate, with
 written evidence, came into the public domain otherwise than through a
breach of this clause 15 or which is required by law to be disclosed.</p>
<p>15.5 The obligations of confidentiality in this clause 15 shall not
be affected by the expiry or termination of this Agreement, but will
remain in effect for 2 years after the termination of this Agreement</p>
<ol>
<li><strong>16. Notices</strong></li>
</ol>
<p>A notice given under this Agreement:</p>
<p>16.1. shall be in writing in the English language (or be accompanied by a properly prepared translation into English);</p>
<p>16.2 shall be delivered by email with formal written confirmation
served by hand or prepaid first class post to the registered office of
the other party or to such other address as may from time to time be
notified to the other party in writing.</p>
<p>16.3 Any notice given under this clause 16 shall be deemed to have been received:</p>
<p>(a) if by email, on receipt of the email subject to receipt of formal written confirmation in due course; or</p>
<p>(b) on the date of delivery if delivered by hand prior to 5.00 pm on a
 Business Day, otherwise on the next Business Day following the date of
delivery; or</p>
<p>(c) on the second Business Day from and including the day of posting in the case of pre-paid first class post.</p>
<ol>
<li><strong>17. Assignment</strong></li>
</ol>
<p>17.1 The Customer will not assign, resell, sublease or in any other
way transfer the Service (or any element thereof), or any of its rights
or obligations under the terms of this Agreement without our prior
written consent.</p>
<p>17.2 Contravention of this restriction in any way, whether successful
 or not will entitle us to terminate the Service and/or the Agreement
immediately.</p>
<p>17.3 We may assign or transfer its rights or obligations under this Agreement by written notice to the Customer.</p>
<ol>
<li><strong>18. Entire Agreement</strong></li>
</ol>
<p>18.1 This Agreement and our Privacy Policy sets out the entire
agreement and understanding between the parties, it supersedes any
previous agreement between them in relation to the subject matter of
this Agreement and no agreements, promises, assurances, warranties,
undertakings, representations, statements or inducements, oral or
written, not contained herein shall bind either party.</p>
<p>18.2 You acknowledge that in entering into this Agreement you do not
rely on any statement, representation, assurance or warranty (whether
made innocently or negligently) that is not set out in this Agreement
[and any agreement referred to herein/ our Privacy Policy, Terms of
Application Use and Acceptable Use Policy]</p>
<p>18.3 No person or undertaking that is not a party to this Agreement
shall have any right under the Contracts (Rights of Third Parties) Act
1999 to enforce any of the terms of this Agreement.</p>
<p>18.4 The Customer may not vary this Agreement without the written consent of us.</p>
<p>18.5 If any provision (or part of a provision) of this Agreement is
found by any court or administrative body of competent jurisdiction to
be invalid, unenforceable or illegal, the other provisions shall remain
in force.</p>
<p>18.6 If any invalid, unenforceable or illegal provision would be
valid, enforceable or legal if some part of it were deleted, the
provision shall apply with whatever modification is necessary to give
effect to the commercial intention of the parties.</p>
<p>18.7 Nothing in this Agreement shall be construed as creating a
partnership or joint venture of any kind between the parties or as
constituting enter party as agent of the other for any purpose
whatsoever. No party shall have the authority to bind the other party or
 to contract in the name of, or create a liability against, the other
party in any way or for any purpose.</p>
<p>18.8 This Agreement and any disputes or claims (whether contractual
or non-contractual) arising out of or in connection with its subject
matter are governed by and construed in accordance with the law of
England.</p>
<p>18.9 The parties irrevocably agree that the courts of England have
exclusive jurisdiction to settle any dispute or claim (whether
contractual or non-contractual) that arises out of or in connection with
 this Agreement.</p>
          </div>
			</div>
		</section>

		

{/* <div id="lightboxOverlay" class="lightboxOverlay" style="display: none;"></div><div id="lightbox" class="lightbox" style="display: none;"><div class="lb-outerContainer"><div class="lb-container"><img class="lb-image" src=""/><div class="lb-nav"><a class="lb-prev" href=""></a><a class="lb-next" href=""></a></div><div class="lb-loader"><a class="lb-cancel"></a></div></div></div><div class="lb-dataContainer"><div class="lb-data"><div class="lb-details"><span class="lb-caption"></span><span class="lb-number"></span></div><div class="lb-closeContainer"><a class="lb-close"></a></div></div></div></div><div id="fc_frame" class="custom_fc_frame fc-widget-small" style="bottom: 15px; right: 15px;"><iframe id="fc_widget" name="fc_widget" title="Chat" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div><div id="fc_push_frame"><iframe id="fc_push" src="https://398771531394381.webpush.freshchat.com/index.html?ref=aHR0cHM6Ly9rYXNvb2tvby5jb20=" title="Chat" frameborder="0"></iframe></div> */}
    </div>
  )
}
